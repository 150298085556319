<div class="pageWrapper" *ngIf="canShow" [style.opacity]="opacity">
    <input class="inputUpload" type="file" accept="image/*" (change)="selectImage($event)" #inputUploadImage>
    <div class="containerTarjeta">
        <div class="containerImage" (click)="onImageClick()" (click)="inputUploadImage.click()">
            <img [src]="urlImage" *ngIf="urlImage">
            <p i18n="@@generador_tarjetas.anyadir_imagen" *ngIf="noImageAdded">Añadir imagen</p>
        </div>
        <div class="containerTitle">
            <form [formGroup]="formTitle" class="subForm" (ngSubmit)="onSubmit(formTitle.value)">
                <input type="text" id="title" formControlName="title" placeholder="Título" maxlength="14" (focus)="onTitleFocus()">
            </form> 
        </div>
    </div>
    <div class="buttonsBar">
        <button class="btnAceptar" (click)="onAceptarClick()"></button>
        <button class="btnCancelar" (click)="onCancelarClick()"></button>
    </div>
    <div class="messages" [style.opacity]="(getErrorType() > 0) ? 1:0">
        <p i18n="@@generador_tarjetas.error_no_imagen" *ngIf="getErrorType() == 1">Es necesario añadir una imagen</p>
        <p i18n="@@generador_tarjetas.error_no_title" *ngIf="getErrorType() == 2">Es necesario añadir un título</p>
    </div>
</div>
<app-spinner></app-spinner>
