import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TBtnPestanya } from '../../classes/t-btn-pestanya';
import { BtnPestanyaInfo } from '../../models/btn-pestanya-info';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { AppStateService } from '../../../shared/services/app-state.service';
import '@angular/localize/init';

const kTitleForBits = $localize`:@@materialComplementario.bits_title:bits`;
const kTitleForCuentos = $localize`:@@materialComplementario.cuentos_title:cuentos \n del mundo`;
const kTitleForRutinas = $localize`:@@materialComplementario.rutinas_title:rutinas`;;
const kTitleForCancionero = $localize`:@@materialComplementario.cancionero_title:cancionero`;;
const kTitleForAmpliacion = $localize`:@@materialComplementario.ampliacion_title:ampliación`;
const kTitleForCurricular = $localize`:@@materialComplementario.curricular_title:documentos \n curriculares`;
const kPathToAssets = "./assets/material_complementario/";
const kPestanyaSheetImageNameByAge = ["pestanya_sheet_3a.png", "pestanya_sheet_4a.png", "pestanya_sheet_5a.png"] 

@Component({
  selector: 'app-btn-pestanya',
  templateUrl: './btn-pestanya.component.html',
  styleUrls: ['./btn-pestanya.component.css']
})
export class BtnPestanyaComponent implements OnInit {

  @Input() info!:BtnPestanyaInfo;
  @Output() eventOnClick = new EventEmitter<BtnPestanyaComponent>();

  titleForTemplate:string = "";

  constructor(private soundPlayer:SoundPlayerService, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.setTitle();
    }, 10);
  }

  private setTitle(){
    
    switch(this.info.type){
      case TBtnPestanya.BITS:
        this.titleForTemplate = kTitleForBits;
        break;
      case TBtnPestanya.CUENTOS:
        this.titleForTemplate = kTitleForCuentos;
        break;
      case TBtnPestanya.RUTINAS:
        this.titleForTemplate = kTitleForRutinas;
        break;
      case TBtnPestanya.CANCIONERO:
        this.titleForTemplate = kTitleForCancionero;
        break;
      case TBtnPestanya.AMPLIACION:
        this.titleForTemplate = kTitleForAmpliacion;
        break;
      case TBtnPestanya.CURRICULAR:
        this.titleForTemplate = kTitleForCurricular;
        break;
    }
  }

  public enable()
  {
    this.info.enabled = true;
  }

  public disable(){
    this.info.enabled = false;
  }

  public isEnabled():boolean{
    return this.info.enabled;
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    this.eventOnClick.emit(this);
  }

  //#region template

  getOpacityForTemplate():number{
    return (this.info.available)? 1:0.3;
  }

  getPestanyaImagePathForTemplate():string{
    return kPathToAssets + kPestanyaSheetImageNameByAge[this.appState.getAge() % 3];
  }

  isDisabled(){
    return (this.info.available)? false:true;
  }

  //#endregion template

}
