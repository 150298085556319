import { Component, OnInit } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';

@Component({
  selector: 'app-creditos-view',
  templateUrl: './creditos-view.component.html',
  styleUrls: ['./creditos-view.component.css']
})
export class CreditosViewComponent implements OnInit {

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  mostrarBotones = true;

  onClickClose(){
    this.soundPlayer.playSoundClick();
    this.mostrarBotones = !this.mostrarBotones;

  }
}
