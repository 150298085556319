import { Component, OnInit } from '@angular/core';
import { PersonajeAnimation } from '../../classes/personaje-animation';
import { Utils } from '../../classes/utils';

const kIdleClass="max";
const kAnimationParpadeoClass="maxParpadeo";
const kAnimationSaludaClass="maxSaluda";
const kAnimationBurlaClass = "maxBurla";
const kAnimationSaltaClass= "maxSalta";
const kTimeSaluda = 3210;
const kTimeSalta = 2780;
const kTimeBurla = 22142;
const kTimeParpadeo = 642;

@Component({
  selector: 'app-max-animado',
  templateUrl: './max-animado.component.html',
  styleUrls: ['./max-animado.component.css']
})
export class MaxAnimadoComponent implements OnInit {

  private cssClassName:string = kIdleClass;
  private animParpadeo:PersonajeAnimation = new PersonajeAnimation(kAnimationParpadeoClass, kTimeParpadeo);
  private animSaluda:PersonajeAnimation = new PersonajeAnimation(kAnimationSaludaClass, kTimeSaluda);
  private animBurla:PersonajeAnimation = new PersonajeAnimation(kAnimationBurlaClass, kTimeBurla);
  private animSalta:PersonajeAnimation = new PersonajeAnimation(kAnimationSaltaClass, kTimeSalta);
  private arrayAvailableAnimations:PersonajeAnimation[] = [];

  constructor() { }

  ngOnInit(): void {}

  public playRandomAnimationsByAge(age:number){

    this.arrayAvailableAnimations = [this.animParpadeo];
    
    switch(age){
      case 3:
        this.arrayAvailableAnimations.push(this.animSaluda);
        break;
      case 4:
        this.arrayAvailableAnimations.push(this.animSalta);
        break;
      case 5:
        this.arrayAvailableAnimations.push(this.animBurla);
        break;
    }

    this.playRandomAnimation();
  }

  public playAllRandomAnimations(){
    
    this.arrayAvailableAnimations = [this.animParpadeo, this.animSalta, this.animSaluda, this.animBurla];

    this.playRandomAnimation();
  }

  private playRandomAnimation(){

    setTimeout(() => {
      const randomIndexAnimation = Utils.getRandomInt(0, this.arrayAvailableAnimations.length-1);
      this.playAnimation(this.arrayAvailableAnimations[randomIndexAnimation]);
    }, Utils.getRandomInt(1000, 5000));
  }

  private playAnimation(animation:PersonajeAnimation){
    
    this.cssClassName = animation.cssStyleNameForAnimation;
    setTimeout(() => {
      this.maxReset();
      this.playRandomAnimation();
    }, animation.duration);
  }
  
  maxReset(){
    this.cssClassName = kIdleClass;
  }

  getClassForTemplate():string
  {
    return this.cssClassName;
  }

}
