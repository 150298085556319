import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TEventFromFichaInteractiva } from '../../../shared/classes/t-event-from-ficha-interactiva';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { FrisoService, FrisoUrlInfo } from '../../services/friso.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';

@Component({
  selector: 'app-friso',
  templateUrl: './friso.component.html',
  styleUrls: ['./friso.component.css']
})
export class FrisoComponent extends BaseViewComponent implements OnInit {

  @Output() eventFromFrame = new EventEmitter<TEventFromFichaInteractiva>();
  @ViewChild('iframe') iframe!: ElementRef;
  safeUrl!:SafeResourceUrl;
  
  private isShowingFlotanteForTemplate: boolean = false;
  private opacityFlotanteForTemplate: number = 0;

  constructor(public sanitizer:DomSanitizer, 
    protected navigationManager:NavigationManagerService, 
    protected appState: AppStateService, 
    private frisoService:FrisoService, 
    private storageManager:StorageManagerService) { 

    super(navigationManager, appState);

    if (window.addEventListener) {
      window.addEventListener("message", this.eventFromFrameReceived.bind(this), false);
    } else {
       (<any>window).attachEvent("onmessage", this.eventFromFrameReceived.bind(this));
    }
  }

  ngOnInit(){
    
    super.ngOnInit();
    
    this.getFrisoUrlFromServer();
  }

  getFrisoUrlFromServer()
  {

    this.frisoService.getFrisoUrl(this.appState.getLanguage(), this.appState.getAge(), this.appState.getNumberOfTrimestre(),this.storageManager.getToken()).subscribe(
      (data:FrisoUrlInfo )=> {
        //console.log("Recuperada url friso: " + data.urlRecurso);
        this.showFrameWithUrl(data.urlRecurso);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  eventFromFrameReceived: any = (event: any) =>  {
    
    // console.log("Soy el flotante y he recibido un mensaje: " + event.data);
    this.eventFromFrame.emit(event.data);

    //Si se ha recibido evento de cerrar, cerramos la ficha
    if(event.data == TEventFromFichaInteractiva.CLOSE)
    {
      this.hideFrame();
    }
  }

  private showFrameWithUrl(url:string){

    //console.log("Voy a mostrar el flotante con la ficha: " + this.urlFicha);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    
    //mostramos flotante
    this.opacityFlotanteForTemplate = 0;
    this.isShowingFlotanteForTemplate = true;
    setTimeout(() => {
      this.opacityFlotanteForTemplate = 1;
    }, 50);
  }


  private hideFrame()
  {
    this.opacityFlotanteForTemplate = 0;
    setTimeout(() => {
      this.isShowingFlotanteForTemplate = false;
    }, 300);
  }

  //#region template
  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.FRISO;
  }

  getVisibilityFlotanteForTemplate():string{
    return (this.isShowingFlotanteForTemplate) ? "visible" : "hidden";
  }

  getOpacityFlotanteForTemplate():number{

    return this.opacityFlotanteForTemplate;
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimaryDark.rgbString;
  }

  getColorTitleForTemplate():string{
    return this.appState.getTransversalColor().rgbString;
  }

  haveValidUrlToShow():boolean{
    
    return(this.safeUrl != null);
  }
  //#endregion

}
