<div class="tarjetasContainer">
    <button class="btnNew" (click)="onNewClick()"><p i18n="@@generador_tarjetas.nueva">Crear nueva</p></button>
    <ng-container *ngFor="let card of arrayCardsForTemplate;">
        <app-btn-card 
            [cardInfo] = "card"
            (eventOnCardClicked)="onCardClicked($event)">
        </app-btn-card>
    </ng-container>
</div>
<app-spinner></app-spinner>
