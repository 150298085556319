export class BtnCaraInfo {
    
    public id:string = "";
    public pathToImage:string = "";

    constructor(_id:string = "", _pathToImage:string = ""){
        this.id = _id;
        this.pathToImage = _pathToImage;
    }
}
