import { Color } from "../classes/color";
import { LocalizedText } from "../classes/localized-text";
import { TLanguage } from "../classes/t-language";
import { TTrimestre } from "../classes/t-trimestre";

export class TrimestreInfo {
    id:TTrimestre = TTrimestre["3_1"];
    description:string = "";
    pathToIcon:string = "";
    pathToLabelImage:string = "";
    pathToCuadernoTematicoImage:string = "";
    cuadernoTematicoDescription:LocalizedText[] = [];
    pathToCancionesImage:string = "";
    pathToCuadernoEntrenamientoImage:string = "";
    pathToCuadernoAdaptacionImage:string = "";
    pathToTrazosImage:string = "";
    pathToFrisoImage:string = "";
    pathToLibroImage:string = "";
    libroDescription:LocalizedText[] = [];
    pathToGamesBackgroundImage:string = "";
    pathToGamesPlayButtonImage:string = "";
    pathToNavButtonGeneradorTarjetas:string = "";
    pathToNavButtonGeneradorTextos:string = "";
    pathToNavButtonComplementario:string = "";
    pathToNavButtonFriso:string = "";
    pathToNavButtonDinamizador:string = "";
    colorPrimary:Color = new Color(0,0,0,0);
    colorPrimaryDark:Color = new Color(0,0,0,0);
    colorPrimaryLight:Color = new Color(0,0,0,0);
    colorTitles:Color = new Color(0,0,0,0);
    colorTitlesLight:Color = new Color(0,0,0,0);
    urlTextGenerator:string = "";
    pathToDiplomaSuccess:string = "";
    pathToDiplomaDownload:string = "";
    pathToDiplomaThumb:string = "";

    getTematicoDescriptionForLanguage(language:TLanguage):string{

        for (let i = 0; i < this.cuadernoTematicoDescription.length; i++) {
            if(this.cuadernoTematicoDescription[i].language == language){
                return this.cuadernoTematicoDescription[i].text;
            }
        }

        console.error("[TrimestreInfo] ERROR: No se ha encontrado la descripcion del cuaderno tematico para el lenguage: " + language);
        return "";
    }

    getLibroDescriptionForLanguage(language:TLanguage):string{

        for (let i = 0; i < this.libroDescription.length; i++) {
            if(this.libroDescription[i].language == language){
                return this.libroDescription[i].text;
            }
        }

        console.error("[TrimestreInfo] ERROR: No se ha encontrado la descripcion del libro para el lenguage: " + language);
        return "";
    }
}
