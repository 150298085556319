export enum TLoginError {
    LICENCE_TEACHER_WRONG,
    LICENCE_TRIMESTRE_1_WRONG,
    LICENCE_TRIMESTRE_2_WRONG,
    LICENCE_TRIMESTRE_3_WRONG,
    USERNAME_USED,
    PASSWORD_MISMATCH,
    CREDENTIALS_WRONG,
    USERNAME_NOT_FOUND,
    SECURITY_QUESTION_NOT_FOUND,
    SECURITY_ANSWER_WRONG,
    RESET_PASSWORD_WRONG, 
    PASSWORD_MIN_LENGTH,
    UNKNOWN
}
