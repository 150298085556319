import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function ComboAgeValidator():ValidatorFn{
    
    return (control:AbstractControl) : ValidationErrors | null => {
        
        const value = control.value;
        
        //La primera opcion del combo es para info y esta desactivada asi que no validamos el control si esta seleccionada
        if(value == 0){
            return {mustSelectAge:true};
        }
        else return null;
    }
}
