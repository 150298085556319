<div class="wrapper" *ngIf="canShowForTemplate()" (click)="onClick()"
    [style.opacity]="getOpacitiyForTemplate()"
    [style.background-color]="getBackgroundColorForTemplate()">
    <div class="diploma" 
        [style.backgroundImage]="'url(' + getPathToDiplomaForTemplate() + ')'"
        [style.transform]="'scale(' + getDiplomaScaleForTemplate() + ') rotate(' + getDiplomaRotationForTemplate() + 'deg)' " >
    </div>
    <div class="anillo" 
        [ngClass]="getClassForAnilloAnimation()"
        [style.visibility]="getVisibiltyAnilloForTemplate()">
    </div>
</div>


