import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BaseViewComponent } from 'src/app/modules/shared/views/base-view/base-view.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppStateService } from 'src/app/modules/shared/services/app-state.service';
import { NavigationManagerService } from 'src/app/modules/shared/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/modules/shared/services/storage-manager.service';
import { ActivatedRoute } from '@angular/router';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TematicaAux } from './tematica-aux';
import { Leccion as Lecciones } from './leccion';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard-profesor-detalle-view',
  templateUrl: './dashboard-profesor-detalle-view.component.html',
  styleUrls: ['./dashboard-profesor-detalle-view.component.css']
})
export class DashboardProfesorDetalleViewComponent extends BaseViewComponent implements OnInit {
  lecciones!: any;
  tematicas: Array<TematicaAux>;
  juegos: any;
  lesson: any;
  name: any;
  eventFromFrameReceived: any;

  constructor(public sanitizer:DomSanitizer,
    protected navigationManager:NavigationManagerService,
    protected appState: AppStateService,
    private storageManager:StorageManagerService,
    private httpClient: HttpClient,
    private activatedRoute:ActivatedRoute,
    private location: Location,
    private soundPlayer: SoundPlayerService) {

    super(navigationManager, appState);
    this.tematicas = []
    this.lesson = "";
    this.name = "";
  }


  ngOnInit(): void {
    const data = localStorage.getItem('badabam_stored_data')
    const token = JSON.parse(data || '').token
    this.activatedRoute.queryParamMap.subscribe(params =>
      {
        const userId = params.get('userID');
        const age = params.get('age');


        this.name = params.get('name');
        if(this.lesson === ""){ this.lesson = params.get('lesson'); }
        this.httpClient
        .get(environment.apiUrl + "dashboard/getAlumno/" + userId + "/" + age + "/" + this.lesson + "?token=" + token)
        .toPromise().then((data) => {
          this.lecciones = data;
          console.log(this.lecciones)

          }).then(() => {
            this.tematicas = this.lecciones.tematicasAux;
            this.juegos = this.lecciones.juegosAux;

            console.log(age);
            if(parseInt((age as string)) != 3){
              if(params.get('age')){
                document.getElementById("adaptacionTab")!.hidden = true;
              }
            }

          })

  })

  document.getElementById('diaporamaContainer')!.style.overflow = 'scroll'
}

getFloor(number:number){
return Math.ceil(number);
}

  public setLesson(number: number){
    this.soundPlayer.playSoundClick();

    console.log('lesson????')
    this.lesson = number
    console.log(this.lesson)
    this.ngOnInit()
  }

  public getStyle(num: number){
    if(this.lesson == num){
      return {'color': 'black'}
    }
    return {'color': 'white'}
  }

  public getJuegosStyle(num: number){
    if(num > 12){
      return {'border': 'none'}
    }
    return {}
  }

  public goBack(){
    this.soundPlayer.playSoundClick();
    this.location.back();
  }

  public close(){
    this.soundPlayer.playSoundClick();
    this.location.back();
  }
}
