
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { SoundDefinition } from '../../classes/sound-definition';
import { AppStateService } from '../../services/app-state.service';
import { SoundPlayerService } from '../../services/sound-player.service';
import { RelojAnimadoComponent } from './components/reloj-animado/reloj-animado.component';
import { BtnSetTimeInfo } from './models/btn-set-time-info';
import { TimerComponent } from './components/timer/timer.component';
import { TTimerStatus } from './classes/t-timer-status';

const kPathToAssets = "./assets/dinamizador/";
const kTapadoBackgroundImageByAge = ["btn_dinamizador_minimizado_3A.png", "btn_dinamizador_minimizado_4A.png", "btn_dinamizador_minimizado_5A.png"]
const kArraySettingsTimeIntervals = [10, 20, 30, 45, 60, 90, 120, 150, 180];

@Component({
  selector: 'app-flotante-dinamizador-reloj',
  templateUrl: './flotante-dinamizador-reloj.component.html',
  styleUrls: ['./flotante-dinamizador-reloj.component.css']
})
export class FlotanteDinamizadorRelojComponent implements OnInit {

  @Output() onEventClose = new EventEmitter();
  @ViewChild(RelojAnimadoComponent,  {static: true}) relojController!: RelojAnimadoComponent;
  @ViewChild(TimerComponent,  {static: true}) timerController!: TimerComponent;
  
  private isShowingFlotanteForTemplate: boolean = false;
  private opacityFlotanteForTemplate: number = 0;
  private opacitySettingsForTemplate: number = 0;
  
  private animationDurationForTemplate: number = 10;
  private timeIntervalSelected:number = 10;
  private soundEnabled:boolean = true;

  public arrayButtonsSettingsForTemplate:BtnSetTimeInfo[] = [];
  public isMinimizedForTemplate: boolean = false;
  public canShowSettings:boolean = false;
  public countDownForTemplate:number = 10;
  public timerForTemplate = 10;
  public animateRelojForTemplate:boolean = false;
  public isAnimationPausedForTemplate:boolean = false;

  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {

    if(this.arrayButtonsSettingsForTemplate.length == 0){
      this.generateButtonsForSetTime();
    }
  }

  private generateButtonsForSetTime(){

    //en orden inverso porque estamos mostrando los botones en orden inverso
    for (let i = kArraySettingsTimeIntervals.length-1; i >= 0; i--) {
      const btnInfo:BtnSetTimeInfo = new BtnSetTimeInfo(kArraySettingsTimeIntervals[i]);
      this.arrayButtonsSettingsForTemplate.push(btnInfo);
    }
  }

  //#region public methods
  public showFlotante(){

    //mostramos flotante
    this.show();
  }

  hideFlotante()
  {
    this.opacityFlotanteForTemplate = 0;
    setTimeout(() => {
      this.isShowingFlotanteForTemplate = false;
      this.soundPlayer.stopSoundFromDefinition(SoundDefinition.APLAUSOS);
    }, 300);
  }

  //#endregion

  private show(){

    this.opacityFlotanteForTemplate = 0;
    this.isShowingFlotanteForTemplate = true;
    setTimeout(() => {
      this.opacityFlotanteForTemplate = 1;
    }, 50);
    this.timerController.setDuration(this.timeIntervalSelected);
  }

  private showSettings(){
    this.canShowSettings = true;
    setTimeout(() => {
      this.opacitySettingsForTemplate = 1;
    }, 10);
    
  }

  private hideSettings(){
    this.opacitySettingsForTemplate = 0;
    setTimeout(() => {
      this.canShowSettings = false;
    }, 200);
  }

  private startTimer(){

    this.relojController.start();
    this.timerController.start();
  }

  private pauseTimer(){

    this.relojController.pause();
    this.timerController.pause();
  }

  private resumeTimer(){

    this.relojController.resume();
    this.timerController.resume();
  }

  private stopTimer(){

    this.relojController.stop();
    this.timerController.stop();
  }

  //#region toques
  onCloseClick(){

    this.soundPlayer.playSoundClick();

    this.hideFlotante();

    this.timerController.stop();
    this.relojController.stop();

    setTimeout(() => {
      this.onEventClose.emit();
    }, 300);
  }

  onMinimizeClick(){

    this.soundPlayer.playSoundClick();

    //tiempo que tarda en estar minimizado
    setTimeout(() => {
      this.isMinimizedForTemplate = true;
      this.timerController.setAsMinimized();
    }, 50);
  }

  onClick(){
    
    if(this.isMinimizedForTemplate){
      
      this.soundPlayer.playSoundClick();
      this.isMinimizedForTemplate = false;
      this.timerController.setAsMaximized();
    }
  }

  onClickBtnSettings(){

    this.soundPlayer.playSoundClick();

    if(this.canShowSettings){
      this.hideSettings();
    }
    else{
      this.showSettings();
    }
  }

  onClickBtnSetTime(btnInfo: BtnSetTimeInfo){
    
    this.timeIntervalSelected = btnInfo.time;
    // this.animationDurationForTemplate = btnInfo.time;
    this.hideSettings();

    this.timerController.setDuration(this.timeIntervalSelected);
    this.relojController.setDuration(this.timeIntervalSelected);
  }

  onClickBtnPlay(){

    this.soundPlayer.playSoundClick();

    this.hideSettings();

    switch(this.timerController.getStatus()){
      case TTimerStatus.RUNNING:
       this.pauseTimer();
        break;
      case TTimerStatus.PAUSED:
        this.resumeTimer();
        break;
      case TTimerStatus.STOPPED:
        this.startTimer();
        break;
    }
  }

  onClickBtnReset(){

    this.soundPlayer.playSoundClick();

    this.hideSettings();

    this.stopTimer();
  }

  onBtnSoundClick(){

    this.soundPlayer.playSoundClick();

    this.soundEnabled = !this.soundEnabled;

    this.timerController.toggleSound(this.soundEnabled);
    this.relojController.toogleSound(this.soundEnabled);
  }

  //#endregion toques

  //#region template
  getVisibilityFlotanteForTemplate():string{
    return (this.isShowingFlotanteForTemplate) ? "visible" : "hidden";
  }

  getOpacityFlotanteForTemplate():number{

    return this.opacityFlotanteForTemplate;
  }

  getTitleBarBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getBackgroundColorForTemplate():string{

    if(this.isMinimizedForTemplate){
      switch(this.appState.getAge()){
        case 3:
          return "#045a53";
        break;
        case 4:
          return "#5b2d65";
        break;
        case 5:
          return "#942b2b";
        break;
      }
      return "";
    }
    else{
      return this.appState.getTrimestreInfo().colorPrimaryLight.rgbString;
    }
    
  }
  getBackgroundImageTapadoForTemplate():string{

    return kPathToAssets + kTapadoBackgroundImageByAge[this.appState.getAge() % 3];
  }

  getSettingsOpacityForTemplate():number{
    return this.opacitySettingsForTemplate;
  }

  getTimeSelectedForTemplate(){
    return this.timeIntervalSelected;
  }

  isTimerRunning():boolean{
    return this.timerController.getStatus() == TTimerStatus.RUNNING;
  }

  isSoundEnabledForTemplate():boolean{
    return this.soundEnabled;
  }
  //#endregion

}
