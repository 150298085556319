import { TmplAstRecursiveVisitor } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from '../modules/shared/services/login.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private router:Router, private loginService:LoginService){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      //console.log('El guard ha sido llamado!');
      return this.checkUserLogin();
  }

  checkUserLogin():true|UrlTree{
    
    if(this.loginService.isUserLogged())
    {
      return true;
    }
    else{
      //no tenemos usuario logueado, redirigimos al login
      return this.router.parseUrl('/login');
    }
  }
  
}
