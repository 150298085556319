<div class="pageWrapper">
    <!-- Pre carga de imagenes para las animaciones de persojanes-->
    <div id="preload_lina_parpadeo"></div>
    <div id="preload_lina_saluda"></div>
    <div id="preload_max_parpadeo"></div>
    <div id="preload_max_saluda"></div>
    <div id="preload_teo_parpadeo"></div>
    <div id="preload_teo_salta"></div>
    <!-- fin precarga-->
    <div class="topContainer">
        <div class="containerTitle">
            <div class="title"></div>
        </div>
    </div>
    <div class="middleContainer">
        <div class="containerAnillo">
            <div class="containerImageAnillo">
                <div class="anillo"></div>
            </div>
        </div>
        <div class="containerLogin">
            <div class="cajaLogin">
                <app-flotante-login 
                    (eventLoginCompleted)="onLoginCompleted()"
                    (eventPasswordRecovery)="onPasswordRecoveryClicked()">
                </app-flotante-login>
            </div>
            <div class="containerButtonRegister">
                <button class="btnRegister" (click)="onBtnRegisterClicked()" i18n="@@login.registro">Registro</button>
            </div>
            
        </div>
    </div>
    <div class="bottomContainer">
        <div class="personajesContainer">
            <div class="linaContainer">
                <app-lisa-animada></app-lisa-animada>
            </div>
            <div class="maxContainer">
                <app-max-animado></app-max-animado>
            </div>
            <div class="teoContainer">
                <app-teo-animado></app-teo-animado>
            </div>
            <div class="btnDemoContainer">
                <button (click)="onBtnDemoClicked()"></button>
            </div>
        </div>
    </div>
    <footer>
        <div class="logo"></div>
        <!-- <div class="version">{{getVersionForTemplate()}}</div> -->
    </footer>
</div>
<app-flotante-register (eventRegisterCompleted)="onRegisterCompleted()"></app-flotante-register>
<app-flotante-password-recovery></app-flotante-password-recovery>
