import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TEventFromFichaInteractiva } from '../../../shared/classes/t-event-from-ficha-interactiva';
import { FlotanteFichaInteractivaComponent } from '../../../shared/components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';
import { AppStateService } from '../../../shared/services/app-state.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { CuentoInfo } from '../../models/cuento-info';
import { ComplementarioConfigService } from '../../services/complementario-config.service';
import { BtnCuentoThumbComponent } from '../btn-cuento-thumb/btn-cuento-thumb.component';

@Component({
  selector: 'app-frame-for-cuentos',
  templateUrl: './frame-for-cuentos.component.html',
  styleUrls: ['./frame-for-cuentos.component.css']
})
export class FrameForCuentosComponent implements OnInit {

  @ViewChild(FlotanteFichaInteractivaComponent,  {static: true}) flotanteCuentoController!: FlotanteFichaInteractivaComponent;
  @ViewChildren(BtnCuentoThumbComponent) cuentoThumbsControllers!: QueryList<BtnCuentoThumbComponent>;
  @Output() onCuentoSelected = new EventEmitter<string>();

  arrayThumbsInfo:CuentoInfo[] = [];
  
  private isShowing: boolean = false;
  private opacity: number = 0;

  constructor(private serverConfig:ComplementarioConfigService, 
    private appState:AppStateService, 
    private storeManager:StorageManagerService) { }
  
  ngOnInit(): void {

    this.getCuentosInfoFromServer();
  }

  private getCuentosInfoFromServer()
  {

    this.serverConfig.getCuentos(this.appState.getAge(), this.appState.getLanguage(), this.storeManager.getToken()).subscribe(
      data => {
        this.arrayThumbsInfo = data;
        
        setTimeout(() => {
          this.showThumbs();
        }, 100);
        
        //console.log("Tengo miniaturas: " + this.arrayThumbsInfo.length);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  showThumbs()
  {
    let delay = 25;
    let counter = 0;
    this.cuentoThumbsControllers.forEach(thumb => {
      setTimeout(() => {
        thumb.showThumb();
      }, delay*counter);
      counter++;
    });
  }
  
  //#region public methods
  public showFrame(){

    this.opacity = 0;
    this.isShowing = true;
    setTimeout(() => {
      this.opacity = 1;
    }, 50);
  }

  public hideFrame()
  {
    this.opacity = 0;
    setTimeout(() => {
      this.isShowing = false;
    }, 300);
  }

  //#endregion public methods

  //#region toques
  onClick(){
    this.flotanteCuentoController.showFlotante();
  }

  eventOnClickCuentoThumb(info:CuentoInfo){

    setTimeout(() => {
      //this.flotanteCuentoController.showFlotanteWithUrl(info.urlRecurso);
      this.onCuentoSelected.emit(info.urlRecurso);
    }, 100);
  }
  //#endregion

  //#region  template
  // getUrlForCuento():string{
  //   return "./assets/cuentos_mundo/index.html";
  // }

  getVisibilityForTemplate():string{
    //return (this.isShowing) ? "block" : "none";
    return (this.isShowing) ? "visible" : "hidden";
  }

  getOpacityForTemplate():number{

    return this.opacity;
  }
  //#endregion

}
