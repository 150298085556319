import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../modules/shared/shared.module';
import { GeneradorTarjetasViewComponent } from './views/generador-tarjetas-view/generador-tarjetas-view.component';
import { EditorTarjetasComponent } from './components/editor-tarjetas/editor-tarjetas.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BtnCardComponent } from './components/btn-card/btn-card.component';
import { ToolBarComponent } from './components/tool-bar/tool-bar.component';
import { CardThumbsComponent } from './components/card-thumbs/card-thumbs.component';


@NgModule({
  declarations: [GeneradorTarjetasViewComponent, EditorTarjetasComponent, BtnCardComponent, ToolBarComponent, CardThumbsComponent],
  imports: [
    CommonModule,
    SharedModule, 
    FormsModule, 
    ReactiveFormsModule
  ],
  exports: [GeneradorTarjetasViewComponent]
})
export class GeneradorTarjetasModule { }
