import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { BtnThumbComponent } from '../../components/btn-thumb/btn-thumb.component';
import { ThumbInfo } from '../../models/thumb-info';
import { FichasService } from '../../services/fichas.service';
import { TCuadernoFicha } from '../../../shared/classes/t-cuaderno-ficha';
import '@angular/localize/init';

@Component({
  selector: 'app-thumbs-view',
  templateUrl: './thumbs-view.component.html',
  styleUrls: ['./thumbs-view.component.css']
})
export class ThumbsViewComponent extends BaseViewComponent implements OnInit {

  @ViewChildren(BtnThumbComponent) thumbsControllers!: QueryList<BtnThumbComponent>;
  thumbsInfo:ThumbInfo[] = [];

  private blocked:boolean = false;
  private category:TCuadernoFicha = TCuadernoFicha.ADAPTACION;
  private titleAdaptacion = $localize`:@@menuTrimestre.periodo_adaptacion:Periodo de adaptación`;
  private titleEntrenamiento = $localize`:@@menuTrimestre.cuaderno_entrenamiento:Cuaderno de entrenamiento`;
  private titleTematico = $localize`:@@menuTrimestre.cuaderno_tematico:Cuaderno temático`;

  constructor(private serverConfig:FichasService, 
    protected navigationManager:NavigationManagerService, 
    protected appState: AppStateService, 
    private storageManager:StorageManagerService, 
    private activatedRoute:ActivatedRoute) { 
    super(navigationManager, appState);
  }

  ngOnInit(): void {

    super.ngOnInit();
     
    //url a la que ir cuando pulsamos en la atras
    this.appState.setLastNavigatedUrl("menu");

    //Recogemos parametro para ver en que tipo de fichas estamos
    this.activatedRoute.params.subscribe(data => 
      {
        this.category =  data.category;
        console.log("Tengo el parametro: " + this.category);

        //recogemos info de las miniaturas
        this.getThumbsInfoFromServer();
      });
  }

  getThumbsInfoFromServer()
  {

    const language = this.appState.getLanguage().toString();
    const age = this.appState.getAge();
    const trimestre = this.appState.getNumberOfTrimestre();
    const token = this.storageManager.getToken();

    this.serverConfig.getThumbnails(this.category, language, age, trimestre, token).subscribe(
      (data:ThumbInfo[]) => {
        //console.log("Data: " + data[0].favorita);
        this.thumbsInfo = data;

        setTimeout(() => {
          this.showThumbs();
        }, 100);
        
        //console.log("Tengo miniaturas: " + this.thumbsInfo.length);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  showThumbs()
  {
    let delay = 25;
    let counter = 0;

    this.thumbsControllers.forEach(thumb => {
      setTimeout(() => {
        thumb.showThumb();
      }, delay*counter);
      counter++;
    });
  }

  eventOnClickThumb(info:ThumbInfo){

    if(this.blocked) return;

    this.blocked = true;

    let url =  "/visor_fichas/" + this.category + "/" + info.unicId;
    //console.log("Url a visor de fichas: " + url);

    this.navigateTo(url);
  }

  //#region template
  getFichasCategoryForTemplate(){
    return this.category;
  }

  getTitleForTemplate():string{

    switch(+this.category){
      case TCuadernoFicha.ADAPTACION:
        return this.titleAdaptacion;
      case TCuadernoFicha.ENTRENAMIENTO:
       return this.titleEntrenamiento;
      case TCuadernoFicha.TEMATICO:
        return this.titleTematico;
      default:
        return "";  
    }
  }

  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.TEMATICO;
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimaryDark.rgbString;
  }

  getColorTitleForTemplate():string{
    return this.appState.getTransversalColor().rgbString;
  }

  //#endregion template
}

