import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { StorageManagerService } from './storage-manager.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationManagerService {

  private navigationHistory:string[] = [];

  constructor(private router:Router, private storageManager:StorageManagerService) { 

    //Escuchamos pulsaciones del boton de atras/adelante del browser
    router.events.forEach((event) => {
      if(event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {

          //si hemos usado el boton de atras o adelante, eliminamos la ultima url que teniamos en el historial porque ya no es valida
          let navigationHistory = this.storageManager.getNavigationHistory();
          navigationHistory.splice(this.navigationHistory.length-1, 1);
          this.storageManager.saveNavigationHistory(navigationHistory);

          //console.log("Historial: " + this.storageManager.getNavigationHistory());
        }
      }
    });
  }


  public setBackUrl(previousUrl:string){
    //console.log("Hemos fijado url de retorno: " + previousUrl);
    let navigationHistory = this.storageManager.getNavigationHistory();
    if(navigationHistory.length > 0 && previousUrl.length > 0)
    {
      //this.navigationHistory[this.navigationHistory.length-1] = previousUrl;
      navigationHistory[navigationHistory.length-1] = previousUrl;
      this.storageManager.saveNavigationHistory(navigationHistory);
      //console.log("Historial: " + this.navigationHistory);
    }
  }

  public getCurrentUrl():string{
    return this.router.url;
  }

  public navigateTo(url:string, addUrlToHistory:boolean = true){

    
    //si estamos en esa url no hacemos nada
    if(!this.canNavigateTo(url)) return;

    this.router.navigate([url]);

    let navigationHistory = this.storageManager.getNavigationHistory();

    if(addUrlToHistory){
      
      //no dejamos añadir la misma url que la anterior
      if(navigationHistory.length > 0 && navigationHistory[navigationHistory.length-1] == url) return;

      navigationHistory.push(url);
      this.storageManager.saveNavigationHistory(navigationHistory);
    }
    
    //console.log("Historial: " + navigationHistory);
  }

  public canNavigateTo(url:string){
   
    return !(this.router.url == url || this.router.url == "/"+url);
  }

  public navigateBack(){
    
    //sino tenemos historial y le damos al boton de atras llevamos a menu del trimestre
    let navigationHistory = this.storageManager.getNavigationHistory();
    if(navigationHistory.length <= 1 ){

      console.log("ERROR: no tengo historial");
      navigationHistory = [];  
    
      if(this.router.url != "/menu" )
      {
        navigationHistory.push("menu");
        this.router.navigate(["/menu"]);
      }
      else{
        navigationHistory.push("selector");
        this.router.navigate(["/selector"]);
      }

      this.storageManager.saveNavigationHistory(navigationHistory);
      return;
    }
    
    //retrocedemos a la url anterior
    //console.log("Navegando a url anterior...");
    navigationHistory.splice(this.navigationHistory.length-1, 1);
    this.storageManager.saveNavigationHistory(navigationHistory);
    //console.log("Historial: " + navigationHistory);
    this.router.navigate([navigationHistory[navigationHistory.length-1]]);
  }

  public clearHistory(){
    
    this.storageManager.deleteNavigationHistory();
  }
}
