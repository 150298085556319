import { Component, OnInit, } from '@angular/core';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-btn-footer',
  templateUrl: './btn-footer.component.html',
  styleUrls: ['./btn-footer.component.css']
})
export class BtnFooterComponent implements OnInit {

  openFooter = false;

  constructor(private loginService:LoginService) { }

  ngOnInit() { }

  isUserLogged() {
    return this.loginService.isUserLogged();
  }

}
