import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../../../services/app-state.service';
import { SoundPlayerService } from '../../../../services/sound-player.service';
import { BtnSetTimeInfo } from '../../models/btn-set-time-info';

@Component({
  selector: 'app-btn-set-time',
  templateUrl: './btn-set-time.component.html',
  styleUrls: ['./btn-set-time.component.css']
})
export class BtnSetTimeComponent implements OnInit {

  @Input() btnInfo: BtnSetTimeInfo = new BtnSetTimeInfo(0);
  @Output() onClickEvent: EventEmitter<BtnSetTimeInfo> = new EventEmitter<BtnSetTimeInfo>();
  
  constructor(private soundManager:SoundPlayerService, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  public onClick(){

    this.soundManager.playSoundClick();

    this.onClickEvent.emit(this.btnInfo);
  }

  getBackgroundColorForTemplate():string{
    return this.appState.getTransversalColor().rgbString;
  }

}
