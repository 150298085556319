import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FrisoComponent } from './modules/friso/views/friso/friso.component';
import { LibroComponent } from './modules/libro/views/libro/libro.component';
import { DistribuidorJuegosComponent } from './modules/juegos/views/distribuidor-juegos/distribuidor-juegos.component';
import { KaraokeComponent } from './modules/karaoke/views/karaoke/karaoke.component'
import { MaterialComplementarioViewComponent} from './modules/material-complementario/views/material-complementario-view/material-complementario-view.component';
import { LoginViewComponent } from './modules/login/views/login-view/login-view.component';
import { ThumbsViewComponent} from './modules/fichas/views/thumbs-view/thumbs-view.component';
import { VisorFichasViewComponent } from './modules/fichas/views/visor-fichas-view/visor-fichas-view.component';
import { MenuTrimestreViewComponent } from './modules/menu-trimestre/views/menu-trimestre-view/menu-trimestre-view.component';
import { SelectorTrimestresViewComponent } from './modules/selector-trimestres/views/selector-trimestres-view/selector-trimestres-view.component';
import { TrazosViewComponent } from './modules/trazos/views/trazos-view/trazos-view.component';
import { DashboardProfesorViewComponent } from './modules/dashboard-profesor/views/dashboard-profesor-view/dashboard-profesor-view.component';
import { DashboardProfesorDetalleViewComponent } from './modules/dashboard-profesor/views/dashboard-profesor-detalle-view/dashboard-profesor-detalle-view.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  {path: '', redirectTo:'/login', pathMatch: 'full'},
  {path: 'login', component: LoginViewComponent},
  {path: 'selector', canActivate: [AuthGuard], component: SelectorTrimestresViewComponent},
  {path: 'menu', canActivate: [AuthGuard], component: MenuTrimestreViewComponent},
  {path: 'menu_fichas/:category', canActivate: [AuthGuard], component: ThumbsViewComponent},
  {path: 'visor_fichas/:category/:id', canActivate: [AuthGuard], component: VisorFichasViewComponent},
  {path: 'friso', canActivate: [AuthGuard], component: FrisoComponent},
  {path: 'libro', canActivate: [AuthGuard], component: LibroComponent},
  {path: 'karaoke', canActivate: [AuthGuard], component: KaraokeComponent},
  {path: 'juegos', canActivate: [AuthGuard], component: DistribuidorJuegosComponent},
  {path: 'complementario', canActivate: [AuthGuard], component: MaterialComplementarioViewComponent},
  {path: 'trazos', canActivate: [AuthGuard], component: TrazosViewComponent},
  {path: 'profesor', canActivate: [AuthGuard], component: DashboardProfesorViewComponent},
  {path: 'profesor/detalle', canActivate: [AuthGuard], component: DashboardProfesorDetalleViewComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
