import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { DescargableInfo } from '../../../material-complementario/models/descargable-info'

@Component({
  selector: 'app-btn-descargables-thumb',
  templateUrl: './btn-descargables-thumb.component.html',
  styleUrls: ['./btn-descargables-thumb.component.css']
})
export class BtnDescargablesThumbComponent implements OnInit {

  @Input() info!:DescargableInfo;
  @Output() eventOnClick = new EventEmitter<DescargableInfo>();

  private scaleForTemplate = 0;

  constructor(private soundPlayer:SoundPlayerService, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  onBtnClicked(){

    this.soundPlayer.playSoundClick();

    this.eventOnClick.emit(this.info);
  }

  //#region public methods
  public showThumb(){
    this.scaleForTemplate = 1;
  }
  //#endregion

  //#region template
  getBackgroundImage():string{
    
    return "url('" + this.info.urlThumb + "')";
  }

  getBorderColor():string{
    //return this.appState.getPrimaryColorForCurrentAge().rgbString;
    return "rgb(128,55,155)";
  }

  getTransformForTemplate():string{
    return "scale(" + this.scaleForTemplate + ")";
  }

  getOpacityForTemplate():number{
    return 1
  }
  //#endregion template


}
