<div class="pageWrapper">
<div style="background-color: white; height: 100%;">
    <header>
        <button class="btnClose" (click)="goBack()"></button>
        <div class="title"></div>
        <div class="tabs">
            <div class="tab" (click)="setLesson(0)"  *ngIf="this.age == 3">
                <span [ngStyle]="this.getStyle(0)" class="tab-title">Periodo de <br> adaptación</span>
                <img *ngIf="this.lesson != 0" src="assets/dashboard_profesor/pestana1.png" alt="" style="height: 78px;">
                <img *ngIf="this.lesson == 0" src="assets/dashboard_profesor/pestana2.png" alt="" style="height: 78px;">
            </div>
            <div class="tab" (click)="setLesson(1)">
                <span [ngStyle]="this.getStyle(1)" class="tab-title">Primer <br> trimestre</span>
                <img *ngIf="this.lesson != 1" src="assets/dashboard_profesor/pestana1.png" alt="" style="height: 78px;">
                <img *ngIf="this.lesson == 1" src="assets/dashboard_profesor/pestana2.png" alt="" style="height: 78px;">
            </div>
            <div class="tab" (click)="setLesson(2)">
                <span [ngStyle]="this.getStyle(2)" class="tab-title">Segundo <br> trimestre</span>
                <img *ngIf="this.lesson != 2" src="assets/dashboard_profesor/pestana1.png" alt="" style="height: 78px;">
                <img *ngIf="this.lesson == 2" src="assets/dashboard_profesor/pestana2.png" alt="" style="height: 78px;">
            </div>
            <div class="tab" (click)="setLesson(3)">
                <span [ngStyle]="this.getStyle(3)" class="tab-title"> Tercer <br> trimestre</span>
                <img *ngIf="this.lesson != 3" src="assets/dashboard_profesor/pestana1.png" alt="" style="height: 78px;">
                <img *ngIf="this.lesson == 3" src="assets/dashboard_profesor/pestana2.png" alt="" style="height: 78px;">
            </div>
        </div>
    </header>
    <table>
        <thead>
        <tr>
            <th colspan="6" style="border: transparent;"> 
                <!-- <img src="" alt=""> -->
                <p class="titulo-tabla">ALUMNADO</p>
            </th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <th width="2%" class="head-lower">Curso</th>
            <th width="24%" class="head-lower">USUARIO/A</th>
            <th width="24%" class="head-lower">NOMBRE EN CLASE</th>
            <th width="16%">INTERACTIVAS</th>
            <th width="16%">NO INTERACTIVAS</th>
            <th width="16%" *ngIf="this.lesson != 0">JUEGOS</th>
        </tr>
        <tr *ngFor="let alumno of alumnos" (click)="getAlumno(alumno)" >
        <td style="text-align: center;" *ngIf="alumno[0].curso == this.age">{{ alumno[0].curso }}</td>
        <td style="white-space: nowrap;overflow: hidden;"*ngIf="alumno[0].curso == this.age">{{ alumno.user.email}}</td>
        <td style="white-space: nowrap;overflow: hidden;"*ngIf="alumno[0].curso == this.age">{{ alumno.user.name}}</td>
        <td class="bar-field"*ngIf="alumno[0].curso == this.age">
            <div class="wrapper">
                <div class="progress-bar">
                    <span class="progress-bar-fill" [ngStyle]="{'width': alumno[0].interactivas+'%' }"></span>
                </div>
            </div>
            <span>{{ alumno[0].interactivas+'%'}}</span>
        </td>
        <td class="bar-field"*ngIf="alumno[0].curso == this.age">
            <div class="wrapper">
                <div class="progress-bar">
                    <span class="progress-bar-fill" [ngStyle]="{'width': alumno[0].tematicas+'%' }"></span>
                </div>
            </div>
            <span>{{ alumno[0].tematicas+'%'}}</span>
        </td>
        <td class="bar-field" *ngIf="this.lesson != 0 && alumno[0].curso == this.age">
            <div class="wrapper">
                <div class="progress-bar">
                    <span class="progress-bar-fill" [ngStyle]="{'width': alumno[0].juegos+'%' }"></span>
                </div>
            </div>
            <span>{{ alumno[0].juegos+'%'}}</span>
        </td>
    </tr>
    </tbody>
    </table>
</div>
</div>