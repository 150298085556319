import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { SharedModule } from '../shared/shared.module';
import { FlotanteLoginComponent } from './components/flotante-login/flotante-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FlotanteRegisterComponent } from './components/flotante-register/flotante-register.component';
import { RegisterFormStep1Component } from './components/register-form-step1/register-form-step1.component';
import { RegisterFormStep2Component } from './components/register-form-step2/register-form-step2.component';
import { RegisterFormStep3Component } from './components/register-form-step3/register-form-step3.component';
import { FlotantePasswordRecoveryComponent } from './components/flotante-password-recovery/flotante-password-recovery.component';

@NgModule({
  declarations: [LoginViewComponent, FlotanteLoginComponent, FlotanteRegisterComponent, RegisterFormStep1Component, RegisterFormStep2Component, RegisterFormStep3Component, FlotantePasswordRecoveryComponent],
  imports: [
    CommonModule, 
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  exports: [LoginViewComponent]
})
export class LoginModule { }
