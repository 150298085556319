import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrazosViewComponent } from './views/trazos-view/trazos-view.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [TrazosViewComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [TrazosViewComponent]
})
export class TrazosModule { }
