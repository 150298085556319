import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { LoginService } from '../../../shared/services/login.service';
import '@angular/localize/init';
import {ConfirmPasswordValidator} from '../../classes/confirm-password-validator';
import { TLoginError } from '../../../shared/classes/t-login-error';
import { RegisterInfo } from '../../../shared/models/register-info';
import { AuthorizationService } from '../../../shared/services/authorization.service';
import { SecurityQuestionService } from '../../services/security-question.service';
import { LoginErrorMessageComponent } from '../../../shared/components/login-error-message/login-error-message.component';


@Component({
  selector: 'app-register-form-step2',
  templateUrl: './register-form-step2.component.html',
  styleUrls: ['./register-form-step2.component.css']
})
export class RegisterFormStep2Component implements OnInit {

  @Input() registerInfoFromStep1:RegisterInfo | null = null;
  @Output() eventCompleted = new EventEmitter<RegisterInfo>(); 
  @Output() eventCancelled = new EventEmitter(); 
  @ViewChild(SpinnerComponent,  {static: true}) spinnerController!: SpinnerComponent;
  @ViewChild(LoginErrorMessageComponent,  {static: false}) errorsController!: LoginErrorMessageComponent;

  formRegister: UntypedFormGroup;
  questionDropDownOptions: any[] = [];
  
  private opacityForMessages = 0;
  private userNameAvailable:boolean = false;

  constructor(private formBuilder: UntypedFormBuilder, 
    private soundPlayer:SoundPlayerService, 
    private loginService:LoginService, 
    private authService:AuthorizationService, 
    private securityQuestions:SecurityQuestionService) { 
    
      this.formRegister = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordRepeat: ['', [Validators.required]],
      classroomName:['', Validators.required],
      comboQuestion: ['', Validators.required],
      answer:['', Validators.required]
    }, {validator: ConfirmPasswordValidator('password', 'passwordRepeat')});
  }

  ngOnInit(): void {

    this.setQuestionsCombo();
  }

  setQuestionsCombo(){

    const arraySecurityQuestions = this.securityQuestions.getSecurityQuestions();

    for (let i = 0; i < arraySecurityQuestions.length; i++) {
      this.questionDropDownOptions.push({"id":arraySecurityQuestions[i].id, "name":arraySecurityQuestions[i].question});
    }
  }

  checkUserName(username:string){
    
    this.loginService.checkUsername(username).subscribe(
      resp => {
        
        //Si la API devuelve false es que el nombre no existe y se puede usar
        if(!resp.success){
          this.userNameAvailable = true;
          this.errorsController.hideError(TLoginError.USERNAME_USED);
        }
        else{
          this.userNameAvailable = false;
          this.errorsController.showError(TLoginError.USERNAME_USED);
        }
      },
      error => {
        console.log("Error desde el componente: " + error);
      },
      () => {
        console.log("Se ha completado el check del username");
        this.spinnerController.hide();
      }
    );  
  }

  checkPasswordMismatch(){

    if(this.formRegister.get('password')!.value == null || this.formRegister.get('passwordRepeat')!.value == null){
      return;
    }

    if(this.formRegister.get('password')!.value.length == 0 || this.formRegister.get('passwordRepeat')!.value.length == 0)
    {
      return;
    }

    const inputPassword = this.formRegister!.get('password');
    const inputPasswordConfirmed = this.formRegister!.get('passwordRepeat');

    const passwordErrors = inputPassword!.errors;
    const passwordConfirmedErrors = inputPasswordConfirmed!.errors;

    if(passwordErrors){
      //console.log("Tenemos errores en el password")
      if(Object.keys(passwordErrors)[0] == "minlength"){
        this.errorsController.resetErrors();
        this.errorsController.showError(TLoginError.PASSWORD_MIN_LENGTH);
        return;
      }
    }
    else{
      this.errorsController.resetErrors();
    }

    if(passwordConfirmedErrors){
      //console.log("Tenemos errores en el password confirmed")
      if(Object.keys(passwordConfirmedErrors)[0] == "mustMatch"){
        this.errorsController.resetErrors();
        this.errorsController.showError(TLoginError.PASSWORD_MISMATCH);
      }
    }
    else{
      this.errorsController.resetErrors();
    }
  }

  register(registerInfo:RegisterInfo){
    
    this.spinnerController.show();

    this.loginService.register(registerInfo).subscribe(
      resp => {
        
        //console.log("Registro completado: " + resp.user.email);
        this.loginService.setLoggedUser(resp);
        this.authService.setToken(resp.token);
        this.eventCompleted.emit(registerInfo);
      },
      error => {
        console.log("Error desde el componente: " + error);
      },
      () => {
        console.log("Se ha completado registro");
        this.spinnerController.hide();
      }
    );  
  }

  onSubmit(values:any){

    this.soundPlayer.playSoundClick();

    let registerInfo:RegisterInfo = new RegisterInfo();

    //campos obtenidos del paso anterior
    registerInfo.licenceTeacher = this.registerInfoFromStep1!.licenceTeacher;
    registerInfo.age = this.registerInfoFromStep1!.age;
    registerInfo.licencesTrimestres = this.registerInfoFromStep1!.licencesTrimestres;

    //campos obtenidos de este formulario
    registerInfo.username = this.formRegister.get('username')!.value;
    registerInfo.password = this.formRegister.get('password')!.value;
    registerInfo.classroomName = this.formRegister.get('classroomName')!.value;
    registerInfo.questionId = this.formRegister.get('comboQuestion')!.value;
    registerInfo.question = this.questionDropDownOptions[registerInfo.questionId].name;
    registerInfo.answerToQuestion = this.formRegister.get('answer')!.value;

    this.register(registerInfo);
  }

  onCancel(){

    this.soundPlayer.playSoundClick();

    this.eventCancelled.emit();
  }

  onBlurUsername(){
    
    const username:string = this.formRegister.get('username')!.value;
    if(username.length > 0){
      this.checkUserName(username);
    }
  }

  onInputPasswords(){

    this.checkPasswordMismatch();
  }

  changeRole(e:any) {
    this.comboQuestion!.setValue(e.target.value, {
      onlySelf: true
    });

    // console.log("Hemos cambiado el rol: " + this.formProfeSelection!.get('comboProfeAlumno').value);
    // console.log("Combo valido: " + this.formProfeSelection!.get('comboProfeAlumno').valid);
    // console.log("Formulario valido: " + this.formProfeSelection!.valid);

    //this.formProfeSelection!.get('comboProfeAlumno').markAsTouched;
  }


  isUsernameAvailable():boolean{
    return this.userNameAvailable;
  }

  getOpacityForMessages():number{
    return this.opacityForMessages;
  }

  get comboQuestion(){
    return this.formRegister.get('comboQuestion');
  }
}
