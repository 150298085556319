import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TTopBarStyles } from '../../classes/t-top-bar-styles';
import { TNavBarButtons } from '../../classes/t-nav-bar-buttons';
import { TTrimestre } from '../../classes/t-trimestre';
import { AppStateService } from '../../services/app-state.service';
import { ColorTrimestresManagerService } from '../../services/color-trimestres-manager.service';
import { BtnUserComponent } from '../userTools/btn-user/btn-user.component';
import { NavBarComponent } from '../nav-bar/nav-bar.component';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  @Input() style:TTopBarStyles = TTopBarStyles.NONE;
  @Input() navigateBackUrl:string = "";
  @Output() clickAtras = new EventEmitter();
  @Output() clickUser = new EventEmitter();
  @Output() clickNavBar = new EventEmitter<TNavBarButtons>();
  @Output() clickHome = new EventEmitter();
  @ViewChild(BtnUserComponent, { static: false }) btnUserController?: BtnUserComponent;
  @ViewChild(NavBarComponent, { static: false }) navBarController?: NavBarComponent;

  constructor(private appState:AppStateService, private colorManager:ColorTrimestresManagerService) { }

  ngOnInit(): void {
  }

  public hideUserSubMenu(){
    this.btnUserController.hideSubMenu();
  }

  getTrimestreForTemplate():TTrimestre{
    
    return this.appState.getTrimestre();
  }

  getNavBarButtonsToShow(){
    switch (this.style) {
      case TTopBarStyles.NONE:
        return [];
      case TTopBarStyles.MENU:
        return [TNavBarButtons.GENERADOR_TARJETAS, TNavBarButtons.GENERADOR_TEXTOS, TNavBarButtons.MATERIAL_COMPLEMENTARIO, TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.TEMATICO:
        return [TNavBarButtons.FRISO, TNavBarButtons.MATERIAL_COMPLEMENTARIO, TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.FRISO:
        return [TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.LIBRO:
        return [TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.KARAOKE:
        return [TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.JUEGOS:
        return [TNavBarButtons.GAMES_TITLE];
      case TTopBarStyles.MATERIAL_COMPLEMENTARIO:
        return [TNavBarButtons.DINAMIZADOR];
      default:
        return [];
    }
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getPreviousUrlForTemplate():string
  {
    return this.navigateBackUrl;
  }

  onBtnAtrasClicked(){

    this.clickAtras.emit();
  }

  onBtnUserClicked(){
    console.log("onBtnUserClicked");
    this.navBarController!.showDinamizadorDesplegable = false;
    this.clickUser.emit();
  }

  onBtnNavBarClicked(navBarButton:TNavBarButtons){
    this.btnUserController?.hideSubMenu();
    this.clickNavBar.emit(navBarButton);
  }

  onBtnHomeClicked(){
    this.clickHome.emit();
  }

}
