<div class="pageWrapper" [style.background-color]="getBackgroundColorForTemplate()">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'menu'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="titleBar" [style.color]="getColorTitleForTemplate()">
        <p>{{getTitleForTemplate()}}</p>
    </div>
    <div class="thumbsContainer">
        <ng-container *ngFor="let thumb of thumbsInfo;">
            <app-btn-thumb 
            [category]="getFichasCategoryForTemplate()"
            [info] = "thumb"
            (clickEvent) = "eventOnClickThumb($event)">
            </app-btn-thumb >
        </ng-container>
    </div>
</div>
