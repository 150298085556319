import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../shared/services/login.service';
import { TTrimestre } from '../../../shared/classes/t-trimestre';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { BtnTrimestreInfo } from '../../models/btn-trimestre-info';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { CreditosViewComponent } from '../../components/creditos-view/creditos-view.component';
import { Utils } from 'src/app/modules/shared/classes/utils';


@Component({
  selector: 'app-selector-trimestres-view',
  templateUrl: './selector-trimestres-view.component.html',
  styleUrls: ['./selector-trimestres-view.component.css']
})
export class SelectorTrimestresViewComponent extends BaseViewComponent implements OnInit {

  @ViewChild(SpinnerComponent,  {static: true}) spinnerController!: SpinnerComponent;
  @ViewChild(CreditosViewComponent,  {static: true}) creditosViewComponent!: CreditosViewComponent;

  buttonsTrimestresForTemplate:BtnTrimestreInfo[] = [];

  constructor(private loginService:LoginService, 
    protected appState:AppStateService, 
    protected navigationManager:NavigationManagerService, 
    private storageManeger:StorageManagerService, private soundPlayer:SoundPlayerService) {
    
      super(navigationManager, appState);
    this.autoScaleView = false;
  }
  
  ngOnInit(): void {

    this.storageManeger.deleteNavigationHistory();
    super.ngOnInit();
    this.setButtonsTrimestres();

    //Escuchamos cambios en los trimestres disponibles
    this.loginService.eventOnTrimestresAvailableChanged.subscribe(()=> {
      this.spinnerController.show();
      this.buttonsTrimestresForTemplate = [];

      setTimeout(() => {
        this.setButtonsTrimestres();
        this.spinnerController.hide();
      }, 300);
      
    });
  }

  ngAfterViewInit(): void {
    
    super.ngAfterViewInit();
  }

  setButtonsTrimestres()
  {

    //3 años
    let btnTrimestre = new BtnTrimestreInfo(TTrimestre['3_1'], this.loginService.getTrimestresAvailables().includes(TTrimestre['3_1']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['3_2'], this.loginService.getTrimestresAvailables().includes(TTrimestre['3_2']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['3_3'], this.loginService.getTrimestresAvailables().includes(TTrimestre['3_3']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    //4años
    btnTrimestre = new BtnTrimestreInfo(TTrimestre['4_1'], this.loginService.getTrimestresAvailables().includes(TTrimestre['4_1']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['4_2'], this.loginService.getTrimestresAvailables().includes(TTrimestre['4_2']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['4_3'], this.loginService.getTrimestresAvailables().includes(TTrimestre['4_3']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);


    //5 años
    btnTrimestre = new BtnTrimestreInfo(TTrimestre['5_1'], this.loginService.getTrimestresAvailables().includes(TTrimestre['5_1']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['5_2'], this.loginService.getTrimestresAvailables().includes(TTrimestre['5_2']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['5_3'], this.loginService.getTrimestresAvailables().includes(TTrimestre['5_3']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);
  }

  getName():string{
    return this.loginService.getName();
  }

  onBtnTrimestreClicked(info:BtnTrimestreInfo){
    
    this.appState.setTrimestre(info.trimestre);

    this.navigateTo("menu");

    console.log("Trimestre elegido: " + info.trimestre);

    // this.fadeOut();

    // setTimeout(() =>{
        
    //   this.router.navigate(['menu']);
    // }, 300);

  }

  getTitle(){
    if(this.loginService.isTeacher())
    return "GUÍA DE USO";
  else
    return "EN FAMILIA";
}

  onBtnGuiaClicked(){
    this.soundPlayer.playSoundClick();

    if(this.loginService.isTeacher())
      Utils.downloadLocalFile("./assets/Recursos_descargables/GUIA_DE_USO.pdf", "GUÍA_DE_USO.pdf");
    else
      Utils.downloadLocalFile("./assets/Recursos_descargables/EN_FAMILIA.pdf", "GUÍA_PARA_LA_FAMILIA.pdf");

  }
  onBtnInfoClicked(){
    this.creditosViewComponent.mostrarBotones = !this.creditosViewComponent.mostrarBotones;
    this.soundPlayer.playSoundClick();
  }
}
