<div class="cardContainer" 
    [ngStyle]="{'transform':'scale(' + scale + ')'}"
    (click)="onClick()">
    <div class="containerImage" [style.background-image]="'url(' + cardInfo.urlImage + ')'">
        <!-- <img [src]="cardInfo.urlImage"> -->
    </div>
    <div class="containerTitle">
        <p>{{cardInfo.title}}</p>
    </div>
    <div class="cardSelectedFrame" *ngIf="selected"></div>
</div>
