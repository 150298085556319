<div class="nav" [style.background-color]="getBackgroundColorForTemplate()">
    <div class="angulo_izquierdo"></div>
    <div class="juegos_title_container" *ngIf="canShowGamesTitle()">
        <p [style.color]="getTitleColorForTemplate()" i18n="@@varios.juegos">Juegos</p>
    </div>
    <div class="botonera">
        <img *ngIf="showLogoBddbm()" src="../../../../../assets/barra_navegacion/badabam_logo_menu_superior.png" class="menu_button_image" >

        <button *ngIf="canShowTarjetasButton()" class="menu_button menu_tarjetas" 
            [style.background-image]="'url(' + getGeneradorTarjetasButtonImageForTemplate() + ')'"
            (click)="onBtnTarjetasClicked()">
            <p>{{titleGeneradorTarjetasForTemplate}}</p>
        </button>
        <button *ngIf="canShowTextosButton()" class="menu_textos menu_button" 
            [style.background-image]="'url(' + getGeneradorTextosButtonImageForTemplate() + ')'"
            (click)="onBtnTextosClicked()">
            <p>{{titleGeneradorTextosForTemplate}}</p>
        </button>
        <button *ngIf="canShowFrisoButton()" class="menu_button" 
            [style.background-image]="'url(' + getFrisoButtonImageForTemplate()"
            (click)="onBtnFrisoClicked()">
            <p>{{titleFrisoForTemplate}}</p>
        </button>
        <button *ngIf="canShowComplementarioButton()" class="menu_button" 
            [style.background-image]="'url(' + getMaterialComplementarioButtonImageForTemplate() + ')'"
            (click)="onBtnComplementarioClicked()">
            <p>{{titleMaterialComplementarioForTemplate}}</p>
        </button>
        <!-- <button disabled *ngIf="canShowVideoButton()" class="menu_video menu_button" (click)="onBtnVideoClicked()">
            <p>{{titleVideoForTemplate}}</p>
        </button> -->
        <div *ngIf="canShowDinamizadorButton()" class="dinamizador_container">
            <button class="menu_button" 
                [style.background-image]="'url(' + getDinamizadorButtonImageForTemplate() + ')'"
                (click)="onBtnDinamizadorClicked()">
                <p>{{titleDinamizadorAulaForTemplate}}</p>
            </button>
            <div [ngClass]="getClassForDisableDinamizadorDesplegable()"
                class="dinamizador_desplegable"
                [style.opacity]="getOpacityForDinamizadorDesplegable()"
                [style.background-color]="getDinamizadorDesplegableBackgroundColorForTemplate()">
                <button (click)="onBtnDinamizadorRelojClicked()" 
                    class="dinamizador_button dinamizador_button_reloj"
                    [style.background-color]="getDinamizadorButtonBackgroundColorForTemplate()">
                </button>
                <button (click)="onBtnDinamizadorPalmasClicked()" 
                    class="dinamizador_button dinamizador_button_palmas"
                    [style.background-color]="getDinamizadorButtonBackgroundColorForTemplate()">
                </button>
                <button (click)="onBtnDinamizadorSemaforoClicked()" 
                    class="dinamizador_button dinamizador_button_semaforo"
                    [style.background-color]="getDinamizadorButtonBackgroundColorForTemplate()">
                </button>
                <button (click)="onBtnDinamizadorCaraClicked()" 
                    class="dinamizador_button dinamizador_button_cara"
                    [style.background-color]="getDinamizadorButtonBackgroundColorForTemplate()">
                </button>
            </div>
        </div>
    </div><!--botonera-->
    <div class="angulo_derecho"></div>
</div>
<ng-container *ngIf="canShowDinamizadorAplausos()">
    <app-flotante-dinamizador-aplausos (onEventClose)="onDinamizadorAplausosClosed()"></app-flotante-dinamizador-aplausos>
</ng-container>
<ng-container *ngIf="canShowDinamizadorSemaforo()">
    <app-flotante-dinamizador-semaforo (onEventClose)="onDinamizadorSemaforoClosed()"></app-flotante-dinamizador-semaforo>
</ng-container>
<ng-container *ngIf="canShowDinamizadorCaras()">
    <app-flotante-dinamizador-caras (onEventClose)="onDinamizadorCarasClosed()"></app-flotante-dinamizador-caras>
</ng-container>
<ng-container *ngIf="canShowDinamizadorReloj()">
    <app-flotante-dinamizador-reloj (onEventClose)="onDinamizadorRelojClosed()"></app-flotante-dinamizador-reloj>
</ng-container>


