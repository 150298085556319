import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../../services/sound-player.service';
import { BtnCaraInfo } from '../../models/btn-cara-info';

@Component({
  selector: 'app-btn-cara',
  templateUrl: './btn-cara.component.html',
  styleUrls: ['./btn-cara.component.css']
})
export class BtnCaraComponent implements OnInit {

  @Input() btnCaraInfo:BtnCaraInfo = new BtnCaraInfo();
  @Output() onBtnCaraClicked = new EventEmitter<BtnCaraInfo>();

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  //#region  toques
  onClick(){

   // this.soundPlayer.playSoundClick();

    this.onBtnCaraClicked.emit(this.btnCaraInfo);
  }

  //#endregion toques

  //#region template
  getBackgroundImage():string{
    return this.btnCaraInfo.pathToImage;
  }

  //#endregion template

}
