import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const kUrlTrazosApi = environment.apiUrl + "trazos/getTrazos/";

@Injectable({
  providedIn: 'root'
})
export class TrazosService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };


  constructor(private http:HttpClient) { }

  getTrazoUrl(language:string, age:number, trimestre:number, token:string):Observable<any>
  {
    const url = kUrlTrazosApi + language + "/" + age + "/" + trimestre;
    const tokenParameter = new HttpParams().set('token', token);

    return this.http.get<any>(url, {params:tokenParameter})
    .pipe(catchError(this.handleError<any>('getTrazosUrl')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    //console.log("ERROR!!!");
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
