import { Injectable } from '@angular/core';
import { StorageManagerService } from './storage-manager.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  constructor(private storageManager:StorageManagerService) { }

  setToken(token:string){
    this.storageManager.saveToken(token)
  }

  getToken():string{
    return this.storageManager.getToken();
  }

  deleteToken(){
    this.storageManager.deleteToken();
  }
}
