import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuTrimestreViewComponent } from './views/menu-trimestre-view/menu-trimestre-view.component';
import { BtnMenuTrimestreComponent } from './components/btn-menu-trimestre/btn-menu-trimestre.component';
import { SharedModule } from './../shared/shared.module';
import { GeneradorTarjetasModule } from '../generador-tarjetas/generador-tarjetas.module';
import { BtnMenuTrimestreAlumnoComponent } from './components/btn-menu-trimestre-alumno/btn-menu-trimestre-alumno.component';

@NgModule({
  declarations: [MenuTrimestreViewComponent, BtnMenuTrimestreComponent, BtnMenuTrimestreAlumnoComponent],
  imports: [
    CommonModule,
    SharedModule,
    GeneradorTarjetasModule
  ],
  exports:[MenuTrimestreViewComponent]
})
export class MenuTrimestreModule { }
