import { Injectable } from '@angular/core';
import { GameSaveInfo } from '../classes/game-save-info';
import { TrimestreInfo } from '../models/trimestre-info';

const kKeyGameSaves = "savedGames";
const kKeyStoredData = "badabam_stored_data";

export interface iStoredData{
  trimestre:number,
  token:string
  navigation_history:string[];
}

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {

  constructor() { }

  private getStoredData():iStoredData{

    if(localStorage.getItem(kKeyStoredData) != null){
      let data = localStorage.getItem(kKeyStoredData);
      let dataParsed:iStoredData;
      dataParsed = data ? JSON.parse(data) : [];
      return dataParsed;
    }
    else{
      console.log("No hay nada guardado en el almacenamiento local");
      return {trimestre:0, token:"", navigation_history:[]};
    }
  }

  private saveStoredData(storedData:iStoredData){
    
    localStorage.setItem(kKeyStoredData, JSON.stringify(storedData));
  }

  //#region trimestre
  public saveTrimestre(trimestre: number){

    if(localStorage.getItem(kKeyStoredData) != null){
      const dataStored = this.getStoredData();
      dataStored.trimestre = trimestre;
      this.saveStoredData(dataStored);
    }
    else{

      const dataStored:iStoredData = {trimestre: trimestre, token:"", navigation_history:[]};
      this.saveStoredData(dataStored);
    }

    const dataStored:iStoredData = this.getStoredData();
    //console.log("Se ha guardado el trimestre en local: " + dataStored.trimestre);
  }

  public getTrimestre():number{
    
    if(localStorage.getItem(kKeyStoredData) != null){
      const dataStored = this.getStoredData();
      return dataStored.trimestre;
    }
    else{
      console.error("[StorageManager] ERROR: No tenemos datos guardados en el almacenaje local.");
      return 0;
    }
  }
  //#endregion trimestre
  
  //#region token
  saveToken(token:string){

    if(localStorage.getItem(kKeyStoredData) != null){
      const dataStored = this.getStoredData();
      dataStored.token = token;
      this.saveStoredData(dataStored);
    }
    else{

      const dataStored:iStoredData = {trimestre: 0, token:token, navigation_history:[]};
      this.saveStoredData(dataStored);
    }

    const dataStored:iStoredData = this.getStoredData();
    //console.log("Se ha guardado el token en local: " + dataStored.token);
  }

  getToken():string{

    if(localStorage.getItem(kKeyStoredData) != null){
      const dataStored = this.getStoredData();
      return dataStored.token;
    }
    else{
      console.error("[StorageManager] ERROR: No tenemos datos guardados en el almacenaje local.");
      return "";
    }
  }

  deleteToken(){
    
    if(localStorage.getItem(kKeyStoredData) != null){
      const dataStored = this.getStoredData();
      dataStored.token = "";
      this.saveStoredData(dataStored);
    }
  }
  //#endregion token

  //#region historial
  saveNavigationHistory(history:string[]){

    //console.log("Guardando historial en local: " + history);
    if(localStorage.getItem(kKeyStoredData) != null){
      const dataStored = this.getStoredData();
      dataStored.navigation_history = history;
      this.saveStoredData(dataStored);
    }
    else{

      const dataStored:iStoredData = {trimestre: 0, token:"", navigation_history:history};
      this.saveStoredData(dataStored);
    }

    const dataStored:iStoredData = this.getStoredData();
    //console.log("Se ha guardado el historial en local: " + dataStored.navigation_history);
  }

  getNavigationHistory():string[]{

    if(localStorage.getItem(kKeyStoredData) != null){
      const dataStored = this.getStoredData();
      //console.log("Historial recuperado de local: " + dataStored.navigation_history);
      return dataStored.navigation_history;
    }
    else{
      console.error("[StorageManager] ERROR: No tenemos datos guardados en el almacenaje local.");
      return [];
    }
  }

  deleteNavigationHistory(){

    if(localStorage.getItem(kKeyStoredData) != null){
      const dataStored = this.getStoredData();
      dataStored.navigation_history = [];
      this.saveStoredData(dataStored);
    }
  }
  //#endregion


  //#region juegos
  public saveGame(saveInfo:GameSaveInfo)
  {
    
    //obtenemos datos salvados
    let dataSaved:GameSaveInfo[] = this.getGameSaves();
    
    //miramos si estamos salvando un juego que ya existe
    let index = this.getGameSaveIndex(saveInfo.idGame);
    
    if(index == -1)
    {
      dataSaved.push(saveInfo);
    } 
    else{
      dataSaved[index]=saveInfo;
    }
    
    localStorage.setItem(kKeyGameSaves, JSON.stringify(dataSaved));
  }

  public getGameSaveInfo(idGame:number):GameSaveInfo{

    let dataSaved:GameSaveInfo[] = this.getGameSaves();

    for (let i = 0; i < dataSaved.length; i++) {
      if(dataSaved[i].idGame == idGame){
        //console.log("Encontrado salvado anterior!!!");
        return dataSaved[i];
      }
    }
    return null as any;      
  }

  public deleteGameSaves(){
    
    localStorage.removeItem(kKeyGameSaves);
  }

  private getGameSaveIndex(idGame:number):number{

    let index = -1;
    let dataSaved:GameSaveInfo[] = this.getGameSaves();

    for (let i = 0; i < dataSaved.length; i++) {
      if(dataSaved[i].idGame == idGame){
        index = i;
        break;
      }
    }

    return index;
         
  }

  private getGameSaves():GameSaveInfo[]{

    if(localStorage.getItem(kKeyGameSaves) != null){
      let data = localStorage.getItem(kKeyGameSaves);
      //console.log("Tenemos datos: " + data);
      let dataParsed:GameSaveInfo[] = [];
      dataParsed = data ? JSON.parse(data) : [];
      return dataParsed;
    }
    else{
      //console.log("No tenemos datos!");
      return [];
    }
  }
  //#endregion juegos

  
}
