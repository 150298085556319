import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { Color } from '../../classes/color';
import { TPainterBrushSize } from '../../classes/t-painter-brush-size';
import { DraggableTextComponent } from '../draggable-text/draggable-text.component';

const kBrushSmall = 5;
const kBrushBig = 15;

@Component({
  selector: 'app-painter',
  templateUrl: './painter.component.html',
  styleUrls: ['./painter.component.css']
})
export class PainterComponent implements OnInit {

  @Input() width:number = 0;
  @Input() height:number = 0;
  @Input() top: number = 0;
  @Input() left: number = 0;
  @ViewChild('canvas', { static: true }) canvas!: ElementRef;
  @ViewChild(DraggableTextComponent, { static: true }) draggableText!: DraggableTextComponent;

  private ctx!: CanvasRenderingContext2D;
  private boundings!:DOMRect;
  private isDrawing = false;
  private mouseX = 0;
  private mouseY = 0;
  private color:Color = new Color(0,0,0);
  private brushSize = kBrushSmall;
  private eraseMode = false;
  private lastX: number = 0;
  private lastY: number = 0;
  private scale:number = 1;

  constructor() { }

  @HostListener('window:resize', ['$event']) onResize() {
    
    //Con cada reescalado se actualizan las propiedades del boundingRect necesarias para calcular la posicion del raton en el canvas
    this.boundings = this.canvas!.nativeElement.getBoundingClientRect();
  }

  ngOnInit()
  {

    this.canvas!.nativeElement.width = this.width;
    this.canvas!.nativeElement.height = this.height;
    this.ctx = this.canvas!.nativeElement.getContext('2d')
    this.boundings = this.canvas!.nativeElement.getBoundingClientRect();

    this.setContext();
  }

  isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0));
  }

  setContext(){
    
    this.ctx!.strokeStyle = this.color.rgbString; 
    this.ctx!.lineWidth = this.brushSize; 
  }

  startDrawing(x:number, y:number){

    this.setMouseCoordinates(x, y);

    this.isDrawing = true;

    if(!this.eraseMode)
    { 
      this.ctx!.beginPath();
      this.ctx!.moveTo(this.mouseX, this.mouseY);
    }
    
  }

  draw(){

    if(this.eraseMode)
    {
      this.ctx!.beginPath();
      this.ctx!.globalCompositeOperation="destination-out";
      this.ctx!.arc(this.mouseX,this.mouseY,this.brushSize+5,0,Math.PI*2,false);
      this.ctx!.fill();
    }
    else{
      //console.log("Voy a pintar una linea de: " + this.mouseX + " a " + this.mouseY);
      this.ctx!.globalCompositeOperation="source-over";
      this.ctx!.lineTo(this.mouseX,this.mouseY);
      this.ctx!.stroke(); 
    }

    this.lastX=this.mouseX;
    this.lastY=this.mouseY;
  }

  stopDrawing()
  {
    this.isDrawing = false;
  }

  //#region eventos raton
  onMouseDown(event:MouseEvent)
  {
    
    this.startDrawing(event.clientX, event.clientY);
  }

  onMouseUp(event:MouseEvent)
  {
    this.setMouseCoordinates(event.clientX, event.clientY);
    this.stopDrawing();
  }

  onMouseMove(event:MouseEvent){
    
    this.setMouseCoordinates(event.clientX, event.clientY);

    if(this.isDrawing)
    {
      this.draw();
    }

  //   if(this.isDrawing){
  //     this.ctx!.lineTo(this.mouseX, this.mouseY);
  //     this.ctx!.stroke();
  //   }
  }

  onMouseLeave(event:MouseEvent)
  {
    this.stopDrawing();
  }
  //#endregion eventos raton
  
  //#region eventos touchpad
  onTouchStart(event:TouchEvent){
    
    this.startDrawing(event.touches[0].clientX, event.touches[0].clientY);
  }

  onTouchMove(event:TouchEvent){
    
    this.setMouseCoordinates(event.touches[0].clientX, event.touches[0].clientY);

    if(this.isDrawing)
    {

      this.draw();
    }
  }

  onTouchEnd(event:TouchEvent){

    //this.setMouseCoordinates(event.touches[0].clientX, event.touches[0].clientY);
    this.stopDrawing();
  }

  //#endregion eventos touchpad

  

  setMouseCoordinates(x:number, y:number){
    
    this.mouseX = (x - this.boundings!.left) / (this.boundings!.right - this.boundings!.left) * this.canvas!.nativeElement.width;
    this.mouseY = (y - this.boundings!.top) / (this.boundings!.bottom - this.boundings!.top) * this.canvas!.nativeElement.height;
  
    // this.mouseX = event.clientX - this.boundings!.left;
    // this.mouseY = event.clientY - this.boundings!.top;
  }

  //#region metodos publicos
  setColor(color:Color)
  {
    this.color = color;
    this.eraseMode = false;
    this.setContext();
  }

  setBrush(size:TPainterBrushSize)
  {
    switch(size)
    {
      case TPainterBrushSize.SMALL:
        this.brushSize = kBrushSmall;
        break;
      case TPainterBrushSize.BIG:
        this.brushSize = kBrushBig;
        break;
    }
    this.setContext();
  }

  erase(){
    
    this.eraseMode = true;
  }

  clear(){

    this.ctx!.clearRect(0, 0, this.canvas!.nativeElement.width, this.canvas!.nativeElement.height);
  }

  showDraggableText(){
    this.draggableText.show();
  }

  hideDraggableText(){
    this.draggableText.hide();
  }
  //#endregion metodos publicos

}
