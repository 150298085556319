<div class="wrapper">
    <!-- Pre carga de imagenes para las animaciones de persojanes-->
    <div id="preload_lina_parpadeo"></div>
    <div id="preload_max_parpadeo"></div>
    <div id="preload_teo_parpadeo"></div>
    
    <div *ngIf="getAgeForTemplate()==3" id="preload_lina_saluda"></div>
    <div *ngIf="getAgeForTemplate()==3" id="preload_max_saluda"></div>
    <div *ngIf="getAgeForTemplate()==3" id="preload_teo_salta"></div>
    
    <div *ngIf="getAgeForTemplate()==4" id="preload_lina_rie"></div>
    <div *ngIf="getAgeForTemplate()==4" id="preload_max_salta"></div>
    <div *ngIf="getAgeForTemplate()==4" id="preload_teo_rie"></div>

    <div *ngIf="getAgeForTemplate()==5" id="preload_lina_salta"></div>
    <div *ngIf="getAgeForTemplate()==5" id="preload_max_burla"></div>
    <div *ngIf="getAgeForTemplate()==5" id="preload_teo_saluda"></div>
    <!-- fin precarga-->
    <header [style.background-color]="getBackgroundColorForTemplate()">
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'selector'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="main" [style.background-color]="getBackgroundColorForTemplate()">
        <!-- [style.padding-left]="(isProfesor())? '0px':'46px'" -->
        <div class="leftContainer" [style.width]="(isProfesor())? '100%':'27%'"  [style.height-left]="(isProfesor())? '800px':'825px'">
            <!-- Botones de actividades SI eres profe-->
            <ng-container *ngFor="let button of arrayButtonsActivityInfoForTemplate;">
                <app-btn-menu-trimestre *ngIf="isProfesor()"
                [info] = "button"
                (clickEvent) = "onBtnTrimestreActivityClicked($event)">
                </app-btn-menu-trimestre>
                <app-btn-menu-trimestre-alumno *ngIf="!isProfesor()"
                [info] = "button"
                (clickEvent) = "onBtnTrimestreActivityClicked($event)">
                </app-btn-menu-trimestre-alumno>
            </ng-container>
        </div>
        <div class="rightContainer" [style.background-image] = "'url(' + getGamesBackgroundImage() + ')'">
            <!-- Boton de play, fondo y animaciones-->
            <button (click)="onBtnJuegosClicked()" [style.background-image]="'url(' + getBtnPlayImage() + ')'">
                <p [style.color]="colorJuegosTxt()" i18n="@@menuTrimestre.juegos">Juegos</p>
            </button>
            
            <div class="personajesContainer" [style.margin-left]="(isProfesor())? '0px':'129px'">
                <div class="linaContainer">
                    <app-lisa-animada></app-lisa-animada>
                </div>
                <div class="maxContainer">
                    <app-max-animado></app-max-animado>
                </div><div class="teoContainer">
                    <app-teo-animado></app-teo-animado>
                </div>
            </div>
        </div>
    </div>
</div>
<app-flotante-ficha-interactiva></app-flotante-ficha-interactiva>
<app-generador-tarjetas-view></app-generador-tarjetas-view>
