import { TTrimestre } from "../../shared/classes/t-trimestre";

export class BtnTrimestreInfo {

    public trimestre:TTrimestre;
    public available:boolean;

    constructor(trimestre:TTrimestre, available:boolean)
    {
        this.trimestre = trimestre;
        this.available = available;
    }
}
