import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BtnJuegoInfo } from '../../models/btn-juego-info';
import { AppStateService } from '../../../shared/services/app-state.service';
import { LingoteComponent } from '../lingote/lingote.component';
import { SoundDefinition } from '../../../shared/classes/sound-definition';
import { GameInfo } from '../../models/game-info';

const kOffsetForAnilloEnabled = -159;
const kPathToThumbs = "./assets/juegos/thumbs/"
const kPathToRingImages = "./assets/juegos/";

export enum tAnilloStates{DISABLED, ENABLED, ANIMATED};

@Component({
  selector: 'app-btn-juego',
  templateUrl: './btn-juego.component.html',
  styleUrls: ['./btn-juego.component.css']
})
export class BtnJuegoComponent implements OnInit {

  @Input() btnInfo!:BtnJuegoInfo;
  @Output() eventOnClick = new EventEmitter<GameInfo>();
  @Output() onAnilloReceived = new EventEmitter();
  @ViewChildren(LingoteComponent) lingotesController!: QueryList<LingoteComponent>;

  opacityForTemplate = 1;
  showAnilloAnimadoForTemplate = false;

  private anilloEnabled = false;
  private scaleForTemplate: number = 0;
  private anilloScaleForTemplate:number = 1;
  private anilloTransitionForTemplate:string = "none";
  private anilloAnimationForTemplate:string = "none";
  private anilloStatus:tAnilloStates = tAnilloStates.DISABLED;
  private urlAnilloBackgroundImage = "";
  private anilloBackgroundImagePosition = "";
  private enabledLingotes = 0;
  private intervalShowRing:any;
  private intervalRingShowed:any;
  private intervalSoundRing:any;
  private currentLingoteController:LingoteComponent | null = null;
  
  constructor(private soundPlayer:SoundPlayerService, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){

    setTimeout(() => {
      if(this.lingotesController == null) console.log("No tengo controladores de lingotes!!!");
      this.setLingotes();
      this.opacityForTemplate = (this.btnInfo.enabled) ? 1:0.3;
    }, 50);
    
  }

  setLingotes(){

    if(this.btnInfo == null) return;

    this.enabledLingotes = this.btnInfo.gameInfo.session;

    const enableWithEffect = false;
    
    switch(this.enabledLingotes)
    { 
      case 0:
        this.setAnilloStatus(tAnilloStates.DISABLED);
        break;
      case 1:
        this.setAnilloStatus(tAnilloStates.DISABLED);
        this.lingotesController.toArray()[0].enableLingote(enableWithEffect);
        break;
      case 2:
        this.setAnilloStatus(tAnilloStates.DISABLED);
        this.lingotesController.toArray()[0].enableLingote();
        this.lingotesController.toArray()[1].enableLingote(enableWithEffect);
        break;
      case 3:
        this.lingotesController.toArray()[0].enableLingote();
        this.lingotesController.toArray()[1].enableLingote();
        this.lingotesController.toArray()[2].enableLingote(enableWithEffect);
        
        //anillo
        if(enableWithEffect){
          this.setAnilloStatus(tAnilloStates.ANIMATED);
          this.soundPlayer.playSoundFromDefinition(SoundDefinition.TACHAN, false, 1, 4);
        }
        else{
          this.setAnilloStatus(tAnilloStates.ENABLED);
        } 
        break;
      default:
        break;
    }
  }

  private setAnilloStatus(status:tAnilloStates){
    
    this.anilloStatus = status;

    switch(this.anilloStatus){
      case tAnilloStates.DISABLED:
        this.urlAnilloBackgroundImage = this.btnInfo.urlImgRing;
        this.anilloBackgroundImagePosition = "left center";
        this.showAnilloAnimadoForTemplate = false;
        break;
      case tAnilloStates.ENABLED:
        this.urlAnilloBackgroundImage = this.btnInfo.urlImgRing;
        this.anilloBackgroundImagePosition = kOffsetForAnilloEnabled.toString() + "px center";
        this.showAnilloAnimadoForTemplate = false;
        break;
      case tAnilloStates.ANIMATED:
        this.urlAnilloBackgroundImage = this.btnInfo.urlImgRingAnimated;        
        this.anilloBackgroundImagePosition = "left center";
        this.showAnilloAnimadoForTemplate = true;
        break;
    }
  }

  public show(){
    
    this.scaleForTemplate = 1;
  }

  public resetLogros(){
    
    this.btnInfo.gameInfo.session = 0;
    this.enabledLingotes = 0;

    this.lingotesController.forEach(lingote => {
      lingote.disableLingote();
    });
    
    this.setAnilloStatus(tAnilloStates.DISABLED);
  }

  public enableNextLingote(){

    if(this.enabledLingotes >= 3) return;

    this.enabledLingotes ++;

    const indexLingoteToEnable = this.enabledLingotes - 1;
    this.currentLingoteController = this.lingotesController[indexLingoteToEnable];
    this.lingotesController.toArray()[indexLingoteToEnable].enableLingote(true);

    if (this.enabledLingotes == 3){

      //animacion del anillo
      this.intervalShowRing = setTimeout(() => {
        this.setAnilloStatus(tAnilloStates.ANIMATED);
      }, 100);

      //sonido anillo
      this.intervalSoundRing = setTimeout(() => {
        this.soundPlayer.playSoundFromDefinition(SoundDefinition.TACHAN);
      }, 4100);

      //informamos de que hemos obtenido un anillo cuando concluye la animacion
      this.intervalRingShowed = setTimeout(() => {
        this.onAnilloReceived.emit();
      }, 4500);
    }
  }

  public updateSessions(numSession:number){

    this.btnInfo.gameInfo.session = numSession;
  }

  public getSession():number{
    return this.btnInfo.gameInfo.session;
  }

  public cancelSuccessOperation(){

    //console.log("Parando animacion de anillo pendientes");
    this.soundPlayer.stopSoundFromDefinition(SoundDefinition.TACHAN);
    clearInterval(this.intervalRingShowed);
    clearInterval(this.intervalShowRing);
    clearInterval(this.intervalSoundRing);
    if(this.currentLingoteController != null){
      this.currentLingoteController.cancelShowLingote();
    }
  }

  //#region toques
  onClick(){

    this.soundPlayer.playSoundClick();

    this.scaleForTemplate = 1;
    
    this.eventOnClick.emit(this.btnInfo.gameInfo);
  }

  onDown(){
    
    this.scaleForTemplate = 1.01;
  }

  onLeave(){
    this.scaleForTemplate = 1;
  }

  onOver(){
   // this.scaleForTemplate = 1.01;
  }
  //#endregion toques

  //#region template
  getBackgroundColorForTemplate():string{

    //return this.appState.getPrimaryColorForCurrentAge().rgbString;
    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getAnilloBackgroundPositionForTemplate():string{
    return this.anilloBackgroundImagePosition;
  }

  getButtonBackgroundImageForTemplate():string{
    
    return "url('" + kPathToThumbs + this.btnInfo.gameInfo.urlImg + "')"; 
  }

  getAnilloBackgroundImageForTemplate():string{
    
    return "url('" + kPathToRingImages + this.urlAnilloBackgroundImage + "')"; 
  }

  getTransformForTemplate():string{
    return "scale(" + this.scaleForTemplate + ")";
  }

  getAnilloScaleForTemplate():number{
    return this.anilloScaleForTemplate;
  }

  getAnilloTransitionForTemplate():string{
    return this.anilloTransitionForTemplate;
  }

  getAnilloAnimationForTemplate(){
    return this.anilloAnimationForTemplate;
  }
  //#endregion

}
