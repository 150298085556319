import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BtnMenuTrimestreInfo } from '../../models/btn-menu-trimestre-info';
import { TTrimestreActivity } from '../../classes/t-trimestre-activity';
import { SoundPlayerService } from '../../../../modules/shared/services/sound-player.service';
import { AppStateService } from '../../../../modules/shared/services/app-state.service';
import '@angular/localize/init';
import { TrimestreInfo } from '../../../shared/models/trimestre-info';
import { TrimestreStaticDataService } from '../../../shared/services/trimestre-static-data.service';

const kHeightForShortSize = 175;
const kHeightForLargeSize = 235;

@Component({
  selector: 'app-btn-menu-trimestre',
  templateUrl: './btn-menu-trimestre.component.html',
  styleUrls: ['./btn-menu-trimestre.component.css']
})
export class BtnMenuTrimestreComponent implements OnInit {

  @Input() info: BtnMenuTrimestreInfo = new BtnMenuTrimestreInfo(TTrimestreActivity.ADAPTACION);
  @Output() clickEvent = new EventEmitter<BtnMenuTrimestreInfo>();
  disabled:Boolean = false;
  opacity:number = 1;

  private blocked:boolean = false;
  private trimestreInfo:TrimestreInfo = new TrimestreInfo();

  constructor(private soundPlayer:SoundPlayerService, 
    private appState:AppStateService) { }

  ngOnInit(): void {

    this.trimestreInfo = this.appState.getTrimestreInfo();
  }

  getTitle():string{
    switch(this.info.activity){
      case TTrimestreActivity.ADAPTACION:
        return $localize`:@@menuTrimestre.periodo_adaptacion:Periodo de adaptación`;
      case TTrimestreActivity.AVENTURAS:
        return $localize`:@@menuTrimestre.libro_aventuras:Libro de aventuras`;
      case TTrimestreActivity.CANCIONES:
        return $localize`:@@menuTrimestre.canciones:Canciones`;
      case TTrimestreActivity.ENTRENAMIENTO:
        return $localize`:@@menuTrimestre.cuaderno_entrenamiento:Cuaderno de entrenamiento`;
      case TTrimestreActivity.FRISO:
        return $localize`:@@menuTrimestre.friso:Friso`;
      case TTrimestreActivity.TEMATICO:
        return $localize`:@@menuTrimestre.cuaderno_tematico:Cuaderno temático`;
      case TTrimestreActivity.TRAZOS:
        return $localize`:@@menuTrimestre.trazos:Trazos`;
    }
  }

  getTitleBackgroundColor():string{
    
    return this.trimestreInfo.colorTitlesLight.rgbString;
  }

  getDescriptionColor():string{

    return this.appState.getTransversalColor().rgbString;
  }

  getDescription():string{
    switch(this.info.activity){
      case TTrimestreActivity.AVENTURAS:
        //return this.appState.getTrimestreInfo().getLibroDescriptionForLanguage(this.appState.getLanguage());
        return this.trimestreInfo.getLibroDescriptionForLanguage(this.appState.getLanguage());
      case TTrimestreActivity.TEMATICO:
        //return this.appState.getTrimestreInfo().getTematicoDescriptionForLanguage(this.appState.getLanguage());
        return this.trimestreInfo.getTematicoDescriptionForLanguage(this.appState.getLanguage());
      default:
        return "";
    }
  }

  getBackgroundImage():string
  {
    switch(this.info.activity)
    {
      case TTrimestreActivity.ADAPTACION:
        return this.trimestreInfo.pathToCuadernoAdaptacionImage;
      case TTrimestreActivity.AVENTURAS:
        return this.trimestreInfo.pathToLibroImage;
      case TTrimestreActivity.CANCIONES:
        return this.trimestreInfo.pathToCancionesImage;
      case TTrimestreActivity.ENTRENAMIENTO:
        return this.trimestreInfo.pathToCuadernoEntrenamientoImage;
      case TTrimestreActivity.FRISO:
        return this.trimestreInfo.pathToFrisoImage;
      case TTrimestreActivity.TEMATICO:
        return this.trimestreInfo.pathToCuadernoTematicoImage;
      case TTrimestreActivity.TRAZOS:
        return this.trimestreInfo.pathToTrazosImage;
    }
  }

  getHeightForButton():number{

    const age = this.appState.getAge();
    const trimestre = this.appState.getNumberOfTrimestre();

    if(age == 3 && trimestre == 1){
      return kHeightForShortSize;
    }
    else{
      return kHeightForLargeSize;
    }
  }

  onClicked()
  {

    //console.log("Boton trimestre: " + this.info.trimestre);
    if(this.blocked) return;

    this.blocked = true;

    this.soundPlayer.playSoundClick();

    this.clickEvent.emit(this.info);
  }

}
