export class RegisterInfo {

    licenceTeacher:string = "";
    age:number = 3;
    licencesTrimestres:string[] = [];
    username:string = "";
    password:string = "";
    classroomName:string = "";
    question:string = "";
    questionId:number = 0;
    answerToQuestion:string = "";
}
