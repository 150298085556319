<div class="wrapper">
    
    <p class="title" i18n="@@register.tu_nombre_usuario">Tu nombre de usuario es</p>
    <p class="value">{{usernameForTemplate}}</p>
    <p class="line">_______</p>
    
    <p class="title" i18n="@@register.tu_password">Tu contraseña es</p>
    <p class="value">{{passwordForTemplate}}</p>
    <p class="line">_______</p>

    <p class="title" i18n="@@register.pregunta">Tu pregunta de seguridad para recuperar la contraseña es</p>
    <p class="value">{{questionForTemplate}}</p>
    <p class="line">_______</p>

    <p class="title" i18n="@@register.tu_respuesta">Tu respuesta de seguridad es</p>
    <p class="value">{{answerForTemplate}}</p>

    <span class="advice-links">
        Al hacer click en “Comenzar” aceptas los <a href="https://terms.santillana.es/" target="_blank">Términos de Uso</a> de esta plataforma y reconoces haber sido informado de la <a href="https://securitypolicy.santillana.es/" target="_blank"></a>Política de Seguridad.
    </span>

    <button class="btnComenzar" (click)="onClick()" i18n="@@varios.comenzar">Comenzar</button>

    <!-- <div class="bottomContainer"></div> -->
</div>

