<div class="pageWrapper" *ngIf="canShowFlotante()" [style.opacity]="getFlotanteOpacity()">
    <div class="box">
        <div class="logo"></div>
        <p class="subTitle" i18n="@@login.anayadir_licencias">GESTIÓN DE LICENCIAS</p>
        <form [formGroup]="formAddLicences" (ngSubmit)="onSubmit(formAddLicences.value)">

            <input [readonly]="isFormDisabled()" type="text" id="licenciaProfesor" formControlName="licenciaProfesor" placeholder="Introduce la licencia del/de la profesor/a">

            <select [attr.disabled]="isFormDisabled() ? '' : null" formControlName="comboAge"
                class="form-control" 
                (change)="changeRole($event)">
                <option *ngFor="let role of ageDropDownOptions; let i = index" [disabled]="i==0" [value]="role.id">
                    {{role.name}}
            </select>

            <div class="trimestreLicenceContainer">
                <label for="licenciaTrimestre1" i18n="@@login.primer_trimestre">Primer trimestre</label>
                <input type="text" id="licenciaTrimestre1"  class="inputLicencia" [readonly]="isFormDisabled()" formControlName="licenciaTrimestre1" placeholder="Introduce la licencia">
            </div>
            <div class="trimestreLicenceContainer">
                <label for="licenciaTrimestre2" i18n="@@login.segundo_trimestre">Segundo trimestre</label>
                <input type="text" id="licenciaTrimestre2"  class="inputLicencia" [readonly]="isFormDisabled()" formControlName="licenciaTrimestre2" placeholder="Introduce la licencia">
            </div>
            <div class="trimestreLicenceContainer">
                <label for="licenciaTrimestre3" i18n="@@login.tercer_trimestre">Tercer trimestre</label>
                <input type="text" id="licenciaTrimestre3"  class="inputLicencia" [readonly]="isFormDisabled()" formControlName="licenciaTrimestre3" placeholder="Introduce la licencia">
            </div>
        
            <button class="btnComprobar" type="submit" [disabled]="formAddLicences.invalid || isFormDisabled()" i18n="@@varios.comprobar">Comprobar</button>
        </form>
        <button class="btnContinuar" (click)="onContinue()" i18n="@@varios.continuar" [disabled]="isContinuarButtonEnabled()">Continuar</button>
        <p style="margin-left: 24%;">-</p>
        <button class="btnCancelar" (click)="onCancel()" i18n="@@varios.cancelar">Cancelar</button>
        <div class="containerErrorMessages">
            <app-login-error-message></app-login-error-message>
        </div>
    </div>
</div>
<app-spinner></app-spinner>
