
import { Injectable } from '@angular/core';
import { TGamesId } from '../classes/t-games-id';

const kTotalRounds = 3;
const kTotalSessions = 3;

@Injectable({
  providedIn: 'root'
})
export class GamesManagerService {

  private currentRound = 1;     //Ronda actual. Cada sesion tiene 3 rondas.
  private currentSession = 1;   //Sesion actual. Cada juego tiene 3 sesiones.

  public arrayLingotesByGameId:number[] = [0,0,0,0];
  public lastGameCompleted:TGamesId = TGamesId.NONE;

  constructor() { }


  public getMaxRounds():number
  {
    return kTotalRounds;
  }

  public getMaxSessions():number{
    return kTotalSessions;
  }

  public setCurrentRound(round:number)
  {
    this.currentRound = round;
  }

  public getCurrentRound():number{
    return this.currentRound;
  }

  public setCurrentSession(session:number)
  {
    this.currentSession = session;
  }

  public getCurrentSession():number{
    return this.currentSession;
  }



}
