import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';

const kFadeDelay = 200;

@Component({
  selector: 'app-flotante-delete-logros',
  templateUrl: './flotante-delete-logros.component.html',
  styleUrls: ['./flotante-delete-logros.component.css']
})
export class FlotanteDeleteLogrosComponent implements OnInit {

  @Output() eventButtonClicked = new EventEmitter<boolean>();
  
  private visible = false;
  private opacity = 0;

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  public show()
  {
    this.visible = true;
    this.opacity = 1;
  }

  public hide(){
    this.opacity = 0;
    
    setTimeout(() => {
      this.visible = false;
    }, kFadeDelay);
  }

  //#region toques
  onClick(deleteLogros:boolean){

    this.soundPlayer.playSoundClick();

    this.eventButtonClicked.emit(deleteLogros);

    setTimeout(() => {
      this.hide();
    }, 200);
  }

  //#endregion toques

  //#region template
  getVisibilityForTemplate():string{

    return (this.visible) ? "visible" : "hidden";
  }

  getOpacityForTemplate():number{
    return this.opacity;
  }
  //#endregion template

}
