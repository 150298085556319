import { Component, OnInit, ViewChild, ViewChildren, QueryList} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Color } from '../../../shared/classes/color';
import { TCuadernoFicha } from '../../../../modules/shared/classes/t-cuaderno-ficha';
import { TEventFromFichaInteractiva } from '../../../shared/classes/t-event-from-ficha-interactiva';
import { TNavBarButtons } from '../../../shared/classes/t-nav-bar-buttons';
import { TPainterBrushSize } from '../../../shared/classes/t-painter-brush-size';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { FlotanteFichaInteractivaComponent } from '../../../shared/components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';
import { PainterBarComponent } from '../../../shared/components/painter-bar/painter-bar.component';
import { PainterComponent } from '../../../shared/components/painter/painter.component';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { FichasMenuBarComponent } from '../../components/fichas-menu-bar/fichas-menu-bar.component';
import { FichaInfo } from '../../models/ficha-info';
import { ThumbInfo } from '../../models/thumb-info';
import { FichasService } from '../../services/fichas.service';
import { BtnFichaMenuBarInfo } from '../../models/btn-ficha-menu-bar-info';
import { TCuadernosMenuItem } from '../../classes/t-cuadernos-menu-item';
import { BtnFichaMenuDesplegableInfo } from '../../models/btn-ficha-menu-desplegable-info';
import { TCuadernosMenuRecursoItem } from '../../classes/t-cuadernos-menu-recurso-item';
import { Utils } from '../../../shared/classes/utils';
import { FichaTematicaInfo } from '../../models/ficha-tematica-info';
import { LoginService } from '../../../shared/services/login.service';



const kPathToAssetsAdaptacion = "./assets/cuadernos_adaptacion/fichas/";
const kPathToAssetsTematicos = "./assets/cuadernos_tematicos/fichas/";
const kPathToAssetsEntrenamiento = "./assets/cuadernos_entrenamiento/fichas/";
const kPathToPdfsTematicos = "./assets/cuadernos_tematicos/pdf/";
const kArraySubfoldersNamesByTrimestre = ["3A_1T", "3A_2T", "3A_3T", "4A_1T", "4A_2T", "4A_3T", "5A_1T", "5A_2T", "5A_3T"];
const kImgNameFavoriteDisabled = "favorito_desactivado.png";
const kFadeDelay = 200;

@Component({
  selector: 'app-visor-fichas-view',
  templateUrl: './visor-fichas-view.component.html',
  styleUrls: ['./visor-fichas-view.component.css']
})
export class VisorFichasViewComponent extends BaseViewComponent implements OnInit {

  @ViewChild(FlotanteFichaInteractivaComponent,  {static: true}) flotanteFichaController!: FlotanteFichaInteractivaComponent;
  @ViewChild(PainterComponent, { static: true }) painter?: PainterComponent;
  @ViewChild(FichasMenuBarComponent,  {static: true}) menuBarController!: FichasMenuBarComponent;
  @ViewChild(PainterBarComponent,  {static: true}) painterMenuBarController!: PainterBarComponent;


  
  pageOpacity = 0;

  fichas:FichaInfo[] = [];
  currentFichaInfo:FichaInfo = new FichaInfo();
  headers:any;
  favoriteForTemplate = false;
  opacityRecursosDesplegableForTemplate = 1;
  isShowingFlotanteForTemplate = false;
  opacityFlotanteForTemplate = 0;

  widthForCanvas = 1160;
  heightForCanvas = 833;
  topForCanvas = 0;
  leftForCanvas = 0;
  
  private fichaId:any;
  private fichaCategory:TCuadernoFicha = TCuadernoFicha.NONE;
  private imgNameFichaShowingInTemplate:string = "";
  private showingReverse = false;
  private ImgNameFavoriteShowingInTemplate:string = kImgNameFavoriteDisabled;
  private opacityFichaForTemplate = 0;
  private blockedButtons = false;
  private thumbsInfo:ThumbInfo[] = []; //lo uso para saber los ids de todas las fichas y poder navegar entre fichas
  private titleAdaptacion = $localize`:@@menuTrimestre.periodo_adaptacion:Periodo de adaptación`;
  private titleEntrenamiento = $localize`:@@menuTrimestre.cuaderno_entrenamiento:Cuaderno de entrenamiento`;
  private titleTematico = $localize`:@@menuTrimestre.cuaderno_tematico:Cuaderno temático`;

  constructor( private loginService:LoginService,
    private serverConfig:FichasService, 
    protected appState:AppStateService,
    private soundPlayer:SoundPlayerService,
    protected navigationManager:NavigationManagerService, 
    private activatedRoute:ActivatedRoute, 
    private storageManager:StorageManagerService, 
    private router:Router) {

      super(navigationManager, appState);

      //recarga la routa cuando cambia el parametro de la url con el id de ficha
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {

    super.ngOnInit();

    //Cada vez que cambia el id de ficha en el parametro de la url necesitamos cargar la ficha correcta asociada a ese id
    this.activatedRoute.params.subscribe(data => 
    {
      this.fichaId = data.id;

      if(this.fichaCategory != data.category){
        
        //actualizamos categoria de la ficha
        this.fichaCategory = data.category;

        //recuperamos info de las miniaturas para saber todos los ids de fichas disponibles y poder navegar entre ellas
        this.getAllFichasInfoFromServer();
      }
      // console.log("Tengo el parametro fichaId: " + this.fichaId);
      // console.log("Tengo el parametro category: " + this.fichaCategory);
      
      //Recuperamos info de la ficha
      this.getFichaInfoFromServer();
    });

    //url a la que ir cuando pulsamos en la atras
    //this.appState.setLastNavigatedUrl("menu_adaptacion");
  }

  /* Se llama cuando se han recibido los datos de la ficha del server */
  initFicha(info:FichaInfo)
  {

    this.currentFichaInfo = info;
    this.imgNameFichaShowingInTemplate = this.currentFichaInfo.urlImage;

    //favorito
    this.favoriteForTemplate = (this.currentFichaInfo.favorita) ? true:false;
    
    this.showingReverse = false;

    //menu para la ficha
    this.menuBarController.setMenuForFicha(info);
    
    //mostramos la ficha
    this.showFichaWithDelay(kFadeDelay);

    //borramos trazos
    this.painter!.clear();

    //ocultamos texto arrastrable
    this.painter!.hideDraggableText();
    this.painterMenuBarController.disableTextToolButton();

    //mostramos pagina
    this.fadeIn();
  }  

  showFichaWithDelay(delay:number)
  {
    setTimeout(() => {
      this.opacityFichaForTemplate = 1;
    }, delay);
  }

  hideFicha()
  {
    this.opacityFichaForTemplate = 0;
  }

  getFichaInfoFromServer()
  {

    this.serverConfig.getFicha(this.fichaCategory, this.fichaId, this.storageManager.getToken()).subscribe(
      (data:FichaInfo )=> {
        //console.log("Recuperada ficha adaptacion: " + data.unicId);
        this.initFicha(data);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  getAllFichasInfoFromServer()
  {
    this.serverConfig.getThumbnails(this.fichaCategory, this.appState.getLanguage(), 
      this.appState.getAge(),
      this.appState.getNumberOfTrimestre(), 
      this.storageManager.getToken()).subscribe(
      (data:ThumbInfo[] )=> {
        //console.log("Recuperadas fichas: " + data[0].unicId);
        this.thumbsInfo = data;
      }, error => {
        console.log("ERROR desde el componente recuperando info de miniaturas");
      }
    );
  }

  updateFavorite(){

    this.serverConfig.updateFavorite(this.fichaCategory, this.currentFichaInfo.unicId, (this.currentFichaInfo.favorita==1)?true:false, this.storageManager.getToken()).subscribe(
      resp => {
        console.log("Exito: " + resp.exito);
      },
      error => {
        console.log("Error desde el componente: " + error);
      },
      () => {
        console.log("Se ha actualizado el favorito");
      }
    );
  }

  updateInteractivaAsCompleted(){

    this.serverConfig.updateFichaInteractivaCompletada(this.currentFichaInfo.unicId, true, this.storageManager.getToken()).subscribe(
      resp => {
        console.log("Exito: " + resp.exito);
      },
      error => {
        console.log("Error desde el componente: " + error);
      },
      () => {
        console.log("Se ha actualizado la ficha interactiva");
      }
    );
  }

  showReverse(){

    this.showingReverse = !this.showingReverse;
    
    this.imgNameFichaShowingInTemplate = (this.showingReverse) ? this.currentFichaInfo.urlImageReverse : this.currentFichaInfo.urlImage;
  }

  navigateToFicha(fichaId:string){

    let url = "/visor_fichas/" + this.fichaCategory + "/" + fichaId;
    this.navigationManager.setBackUrl(url);

    this.navigateTo(url, "", false);
  }

  navigateToFichaEntrenamiento(fichaId:string){
    
    let url = "/visor_fichas/" + TCuadernoFicha.ENTRENAMIENTO + "/" + fichaId;

    this.navigateTo(url, this.router.url);
  }

  getNextFichaId():string{

    let nextFichaId = "";

    const currentFichaIndex = this.currentFichaInfo.index-1;

    if(this.thumbsInfo == null || currentFichaIndex > this.thumbsInfo.length-1){
      return nextFichaId;
    }

    if(currentFichaIndex < this.thumbsInfo.length-1){
      nextFichaId = this.thumbsInfo[currentFichaIndex+1].unicId;
    }

    return nextFichaId;
  }

  getPreviousFichaId():string{

    let prevFichaId = "";

    const currentFichaIndex = this.currentFichaInfo.index-1;

    if(this.thumbsInfo == null || currentFichaIndex > this.thumbsInfo.length-1){
      return prevFichaId;
    }

    if(currentFichaIndex > 0){
      prevFichaId = this.thumbsInfo[currentFichaIndex-1].unicId;
    }

    return prevFichaId;
  }
  
  //#region eventos
  onClickFavorite(){

    this.soundPlayer.playSoundClick();

    this.currentFichaInfo.favorita = (this.currentFichaInfo.favorita == 1) ? 0:1;
    this.favoriteForTemplate = (this.currentFichaInfo.favorita == 1) ? true:false;
    
    //llamar al servicio para updatear si la ficha es favorita
    this.updateFavorite();
  }

  onClickFlip(){

    this.soundPlayer.playSoundClick();

    this.showReverse();
  }

  onClickSiguiente(){

    this.soundPlayer.playSoundClick();

    if(this.blockedButtons) return;

    const nextFichaId = this.getNextFichaId();
    if(nextFichaId != ""){
      this.navigateToFicha(nextFichaId);
    }
  }

  onClickAnterior(){

    this.soundPlayer.playSoundClick();

    if(this.blockedButtons) return;

    const prevFichaId = this.getPreviousFichaId();

    if(prevFichaId != ""){
      this.navigateToFicha(prevFichaId);
    }
  }

  isLastPage(){
    const nextFichaId = this.getNextFichaId();
    
    if(nextFichaId != ""){
      return false;
    }else{
      return true;
    }

  }

  isFirstPage(){
    const prevFichaId = this.getPreviousFichaId();

    if(prevFichaId != ""){
      return false;
    }else{
      return true;
    }

  }

  /* Sobreescribimos metodo del padre para poner la url de la ficha a la que volver */
  eventOnClickNavBar(navBarButton:TNavBarButtons){
    this.menuBarController.hideAllDesplegables();
    let route:string = "";
    switch(navBarButton)
    {
      case TNavBarButtons.FRISO:
        this.navigateTo("friso", this.router.url);
        break;
      case TNavBarButtons.MATERIAL_COMPLEMENTARIO:
        this.navigateTo("complementario", this.router.url);
        break;
      case TNavBarButtons.GENERADOR_TEXTOS:
        this.showGeneradorTextos();
        break;
    }
  }


  onClickMenuBarItem(info:BtnFichaMenuBarInfo)
  {
    
    switch(+info.type)
    {
      case TCuadernosMenuItem.INTERACTIVA:
        this.flotanteFichaController.showFlotanteWithUrl(info.urlAsociada);
        break;
      case TCuadernosMenuItem.ENTRENAMIENTO:
        break;
      case TCuadernosMenuItem.RECURSOS:
        break;
      case TCuadernosMenuItem.CURRICULAR:
        console.log("Click en Curricular");
        const fichaTematicaInfo:FichaTematicaInfo = this.currentFichaInfo as FichaTematicaInfo;
        if(info != null && fichaTematicaInfo.urlCurricular.length > 0){
          Utils.downloadLocalFile(kPathToPdfsTematicos + fichaTematicaInfo.urlCurricular, fichaTematicaInfo.urlCurricular);
        }
        break;
    }
  }


  closeVideo(){
    this.soundPlayer.playSoundClick();
    document.getElementById("infoModal")!.style.display = "none";
    (document.getElementById("videoPlayer") as HTMLVideoElement).pause();
  }

  onClickMenuBarItemDesplegable(btnInfo:BtnFichaMenuDesplegableInfo){

    //console.log("Hola soy el visor de fichas y me he enterado de que hemos pulsado en un boton de desplegable: " + btnInfo.url);
    switch(btnInfo.type){
      case TCuadernosMenuRecursoItem.AUDIO:
        // console.log("Es un audio!");
        sessionStorage.setItem('cancionIndice', btnInfo.url.split('#')[1]);
        this.navigateTo('/karaoke', this.router.url, true);

        break;
      case TCuadernosMenuRecursoItem.DESCARGABLE:
        // console.log("Es un descargable!");
        // window.open( './assets/Recursos_descargables/'+btnInfo.url, "_blank");
        Utils.downloadLocalFile('./assets/Recursos_descargables/'+btnInfo.url, btnInfo.name);

        break;
      case TCuadernosMenuRecursoItem.ENTRENAMIENTO:
        console.log("Es una ficha de entrenamiento: " + btnInfo.id);
        this.navigateToFichaEntrenamiento(btnInfo.id);
        break;
      case TCuadernosMenuRecursoItem.INTERACTIVO:
        console.log("Es un interactivo!");
        window.open(btnInfo.url, "_blank");

        break;
      case TCuadernosMenuRecursoItem.TRAZO:
        sessionStorage.setItem('trazoIndice', btnInfo.url.split('#')[1]);

        this.navigateTo('/trazos', this.router.url, true);
        break;
      case TCuadernosMenuRecursoItem.VIDEO:
        document.getElementById("infoModal")!.style.display = "block";
    
        (document.getElementById("videoPlayer") as HTMLVideoElement).src = btnInfo.url;
        (document.getElementById("videoPlayer") as HTMLVideoElement).play();
        console.log("Es un video!");
        break;
    }
  }

  onEventClickColorItem(color:Color)
  {
    this.painter!.setColor(color);
  }

  onEventClickBrushSize(size:TPainterBrushSize){
    

    this.painter!.setBrush(size);
  }

  onEventClickEraser()
  {
    this.painter!.erase();
  }

  onEventClickTextTool(enabled:boolean){

    if(enabled){
      this.painter!.showDraggableText();
    }
    else{
      this.painter!.hideDraggableText();
    }
  }

  onEventClickTrash(){

    this.painter!.clear();
  }

  onEventFichaInteractiva(eventFicha:TEventFromFichaInteractiva){
    
    console.log("Hemos recibido un evento de la ficha interactiva: " + eventFicha);

    if(eventFicha == TEventFromFichaInteractiva.CLOSE || eventFicha == TEventFromFichaInteractiva.SUCCESS){
      //Enviamos info de que hemos completado la ficha
      this.updateInteractivaAsCompleted();
    }
  }
  //#endregion

  //#region accesos desde el template
  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.TEMATICO;
  }

  getImgNameForTemplate():string
  {
    
    let pathToAssets = "";
    switch(+this.fichaCategory){
      case TCuadernoFicha.ADAPTACION:
        pathToAssets = kPathToAssetsAdaptacion;
        break;
      case TCuadernoFicha.TEMATICO:
        pathToAssets = kPathToAssetsTematicos;
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        pathToAssets = kPathToAssetsEntrenamiento;
        break;
      default:
        console.error("ERROR: No se ha recibido un tipo de ficha valido");
    }

    let subFolder = kArraySubfoldersNamesByTrimestre[this.appState.getTrimestre()];
    pathToAssets += subFolder + "/";
    //console.log("Path to assets: " + pathToAssets + this.imgNameFichaShowingInTemplate);
    
    return "url('" + pathToAssets + this.imgNameFichaShowingInTemplate + "')";
  }

  getFavoriteImgNameForTemplate():string
  {
    return "url('" + kPathToAssetsTematicos + this.ImgNameFavoriteShowingInTemplate + "')";
  }

  getImgOpacityForTemplate():string{
    return this.opacityFichaForTemplate.toString();
  }

  getFichaType():TCuadernoFicha{
    return this.fichaCategory;
  }

  getUrlFichaInteractiva():string{
    
    //console.log("URL Ficha: " + this.currentFichaInfo.urlFicha);
    //return this.currentFichaInfo.urlFicha;
    return "";
  }

  getFichaTitleForTemplate():string{
    
    switch(+this.fichaCategory){
      case TCuadernoFicha.ADAPTACION:
        return this.titleAdaptacion;
      case TCuadernoFicha.ENTRENAMIENTO:
       return this.titleEntrenamiento;
      case TCuadernoFicha.TEMATICO:
        return this.titleTematico;
      default:
        return "";  
    }
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimaryDark.rgbString;
  }

  getColorTitleForTemplate():string{
    return this.appState.getTransversalColor().rgbString;
  }

  canShowFlipButton():boolean{
    return (this.fichaCategory == TCuadernoFicha.ENTRENAMIENTO || this.fichaCategory == TCuadernoFicha.TEMATICO);
  }

  showManejadores(){
    if(this.fichaCategory == TCuadernoFicha.ENTRENAMIENTO && !this.loginService.isTeacher())
      return false;


    return true;
  }

  isAlumno(){
    return this.loginService.isTeacher();
  }
  //#endregion

}
