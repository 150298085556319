import { Component, OnInit } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BaseViewComponent } from 'src/app/modules/shared/views/base-view/base-view.component';
import { DomSanitizer } from '@angular/platform-browser';
import { AppStateService } from 'src/app/modules/shared/services/app-state.service';
import { NavigationManagerService } from 'src/app/modules/shared/services/navigation-manager.service';
import { StorageManagerService } from 'src/app/modules/shared/services/storage-manager.service';
import { Router } from '@angular/router';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-dashboard-profesor-view',
  templateUrl: './dashboard-profesor-view.component.html',
  styleUrls: ['./dashboard-profesor-view.component.css']
})
export class DashboardProfesorViewComponent extends BaseViewComponent implements OnInit {
  alumnos: any;
  lesson: number;
  eventFromFrameReceived: any;
  age: number = 3;

  constructor(public sanitizer:DomSanitizer,
    protected navigationManager:NavigationManagerService,
    protected appState: AppStateService,
    private storageManager:StorageManagerService,
    private httpClient: HttpClient,
    private router: Router,
    private location: Location,
    private soundPlayer: SoundPlayerService) {

    super(navigationManager, appState);
    this.alumnos = []
    this.lesson = 1;

  }


  ngOnInit(): void {

    super.ngOnInit();

    // this.lesson = this.appState.getNumberOfTrimestre();
    // console.log("año " + this.appState.getAge());
    this.age = this.appState.getAge();
    const data = localStorage.getItem('badabam_stored_data')
    const token = JSON.parse(data || '').token


    this.httpClient
    .get(environment.apiUrl + "dashboard/getAllAlumnos/" + this.lesson + "?token=" + token)
    .toPromise().then((data) => {
       this.alumnos = data;
       this.alumnos.sort((a:any, b:any) => {
        return this.compareObjects(a, b, 'name')
      })
      //  console.log(this.alumnos);

      //  this.alumnos.forEach( (item:any, index: number) => {
      //   if(item[0].curso == this.appState.getAge()) this.alumnos.splice(index,1);
      // });

      });
  }

  compareObjects(object1:any, object2:any, key:string) {
    const obj1 =  parseInt(object1[0].curso);
    const obj2 = parseInt(object2[0].curso);

    if (obj1 < obj2) {
      return -1
    }
    if (obj1 > obj2) {
      return 1
    }

    return 0
  }

  public setLesson(num: number){
    if(num == 0 && this.age != 3)
      return;

    this.soundPlayer.playSoundClick();

    this.lesson = num;
    this.ngOnInit();
  }

  public getStyle(num: number){
    if(this.lesson == num){
      return {'color': 'black'}
    }
    return {'color': 'white'}
  }

  public getAlumno(alumno: any){
    // console.log('alumno', alumno)
    this.router.navigate([`/profesor/detalle`], {queryParams: { userID : alumno.user.unicId, age: alumno[0].curso, lesson: this.lesson, name:alumno.user.name  }})

  }

  public goBack(){
    this.soundPlayer.playSoundClick();
    this.location.back();
  }

  public getCeilNumbrer(number:number){
    return Math.ceil(number);
  }

}
