<button [disabled]="disabled" class="btnMenu" (click)="onClicked()"
    [style.opacity]="opacity"
    [style.height]="getHeightForButton() + 'px'">
    <div class="containerTitle" [style.background-color]="getTitleBackgroundColor()">
        <p>{{getTitle()}}</p>
    </div>
    <div class="btnMenuMain" 
        [style.background-image]="'url(' + getBackgroundImage() + ')'"
        [style.height]="getHeightForButton() + 'px'">
        <p [style.color]="getDescriptionColor()">{{getDescription()}}</p>
    </div>
</button>   
