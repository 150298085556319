import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { TrimestreBannerComponent } from './components/trimestre-banner/trimestre-banner.component';
import { BtnAtrasComponent } from './components/btn-atras/btn-atras.component';
import { BaseViewComponent } from './views/base-view/base-view.component';
import { PainterComponent } from './components/painter/painter.component';
import { PainterBarComponent } from './components/painter-bar/painter-bar.component';
import { FlotanteFichaInteractivaComponent } from './components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';
import { FlotanteVideoComponent } from './components/flotante-video/flotante-video.component';
import { FlotanteCartelaComponent } from './components/flotante-cartela/flotante-cartela.component';
import { FlotanteWithButtonsComponent } from './components/flotante-with-buttons/flotante-with-buttons.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { BtnPainterBarColorComponent } from './components/btn-painter-bar-color/btn-painter-bar-color.component';
import { DraggableTextComponent } from './components/draggable-text/draggable-text.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field'; 
import { LisaAnimadaComponent } from './components/lisa-animada/lisa-animada.component';
import { MaxAnimadoComponent } from './components/max-animado/max-animado.component';
import { TeoAnimadoComponent } from './components/teo-animado/teo-animado.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { FlotanteDinamizadorAplausosComponent } from './components/flotante-dinamizador-aplausos/flotante-dinamizador-aplausos.component';
import { FlotanteDinamizadorSemaforoComponent } from './components/flotante-dinamizador-semaforo/flotante-dinamizador-semaforo.component';
import { FlotanteDinamizadorCarasComponent } from './components/flotante-dinamizador-caras/flotante-dinamizador-caras.component';
import { BtnCaraComponent } from './components/flotante-dinamizador-caras/components/btn-cara/btn-cara.component';
import { FlotanteDinamizadorRelojComponent } from './components/flotante-dinamizador-reloj/flotante-dinamizador-reloj.component';
import { BtnSetTimeComponent } from './components/flotante-dinamizador-reloj/components/btn-set-time/btn-set-time.component';
import { RelojAnimadoComponent } from './components/flotante-dinamizador-reloj/components/reloj-animado/reloj-animado.component';
import { TimerComponent } from './components/flotante-dinamizador-reloj/components/timer/timer.component';
import { BtnUserComponent } from './components/userTools/btn-user/btn-user.component';
import { FlotanteLicenceComponent } from './components/userTools/flotante-licence/flotante-licence.component';
import { AddLicencesFormComponent } from './components/userTools/add-licences-form/add-licences-form.component';
import { LoginErrorMessageComponent } from './components/login-error-message/login-error-message.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BtnFooterComponent } from './components/btn-footer/btn-footer.component';
// import { FichasMenuBar } from '../fichas/components/btn-ficha-menu-bar/btn-ficha-menu-bar.component';

@NgModule({
  declarations: [NavBarComponent, 
    TopBarComponent, 
    TrimestreBannerComponent, 
    BtnAtrasComponent,  
    BaseViewComponent, 
    PainterComponent, 
    PainterBarComponent, 
    FlotanteFichaInteractivaComponent, 
    FlotanteVideoComponent, 
    FlotanteCartelaComponent, 
    FlotanteWithButtonsComponent, 
    CustomButtonComponent, 
    BtnPainterBarColorComponent, 
    DraggableTextComponent,
    LisaAnimadaComponent,
    MaxAnimadoComponent,
    TeoAnimadoComponent,
    SpinnerComponent,
    FlotanteDinamizadorAplausosComponent,
    FlotanteDinamizadorSemaforoComponent,
    FlotanteDinamizadorCarasComponent,
    BtnCaraComponent,
    FlotanteDinamizadorRelojComponent,
    BtnSetTimeComponent,
    RelojAnimadoComponent,
    TimerComponent,
    BtnUserComponent,
    BtnFooterComponent,
    FlotanteLicenceComponent,
    AddLicencesFormComponent,
    LoginErrorMessageComponent],
  imports: [
    CommonModule,
    RouterModule,
    DragDropModule,
    TextFieldModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    TopBarComponent,
    BaseViewComponent, 
    PainterComponent,
    PainterBarComponent,
    FlotanteFichaInteractivaComponent,
    FlotanteVideoComponent,
    FlotanteCartelaComponent,
    FlotanteWithButtonsComponent,
    DraggableTextComponent,
    LisaAnimadaComponent,
    MaxAnimadoComponent,
    TeoAnimadoComponent,
    SpinnerComponent,
    BtnUserComponent,
    BtnFooterComponent,
    LoginErrorMessageComponent,
  ]
})
export class SharedModule { }
