import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TLanguage } from '../../shared/classes/t-language';
import { environment } from 'src/environments/environment';

export interface KaraokeUrlInfo{
  urlRecurso:string;
}

@Injectable({
  providedIn: 'root',
})
export class KaraokeConfigService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private karaokeApiUrl = environment.apiUrl + "karaoke/getKaraoke/";

  constructor(private http:HttpClient) { }

  getUrlForKaraoke(age:number, trimestre:number,language:TLanguage, token:string):Observable<KaraokeUrlInfo>
  {

    let url = this.karaokeApiUrl + language + "/" + age + "/" + trimestre;
    const tokenParameter = new HttpParams().set('token', token);
    //console.log("Url karaoke: " + url);

    return this.http.get<KaraokeUrlInfo>(url, {params:tokenParameter})
      .pipe(
        // tap(_ => console.log('Datos miniaturas recibidos')),
        catchError(this.handleError<KaraokeUrlInfo>('getKaraokeUrl', {urlRecurso:""}))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {

      console.error(error); // log to console instead
      return of(result as T);
    };
  }
}
