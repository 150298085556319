import { TDescargable } from "../classes/t-descargable";

export class DescargableInfo {
    type:TDescargable = TDescargable.AMPLIACION;
    title:string = "";
    description:string = "";
    urlThumb:string = "";
    urlForDownload:string = "";

    constructor(_type:TDescargable, _title:string, _description:string, _urlThumb:string, _urlForDownload:string){
        this.type = _type;
        this.title = _title;
        this.description = _description;
        this.urlThumb = _urlThumb;
        this.urlForDownload = _urlForDownload;
    }
}
