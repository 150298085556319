import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TTrimestre } from '../../../shared/classes/t-trimestre';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TrimestreStaticDataService } from '../../../shared/services/trimestre-static-data.service';
import { BtnTrimestreInfo } from '../../models/btn-trimestre-info';

@Component({
  selector: 'app-btn-trimestre',
  templateUrl: './btn-trimestre.component.html',
  styleUrls: ['./btn-trimestre.component.css']
})
export class BtnTrimestreComponent implements OnInit {

  @Input() info: BtnTrimestreInfo = new BtnTrimestreInfo(TTrimestre['3_1'], false);
  @Output() clickEvent = new EventEmitter<BtnTrimestreInfo>();

  constructor(private soundPlayer:SoundPlayerService, 
    private trimestreDataService:TrimestreStaticDataService) { }

  ngOnInit(): void {
  }

  getBtnIconImage():string{

    return this.trimestreDataService.getIconImageNameForTrimestre(this.info.trimestre);
  }

  getBtnTitleImage():string{
    
    return this.trimestreDataService.getLabelTrimestreImageNameForTrimestre(this.info.trimestre);
  }

  
  isDisabled():boolean{
    return !this.info.available;
  }

  onClick()
  {

    this.soundPlayer.playSoundClick();
    this.clickEvent.emit(this.info);
  }

}
