import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { TTrimestre } from '../classes/t-trimestre';
import { RegisterInfo } from '../models/register-info';
import { environment } from 'src/environments/environment';

//Interface para datos que se envian a la API de login
export interface ILoginInfo{
  email:string,
  password:string,
  password_raw:string;
}

//Interface para datos de comprobacion de licencias previo al registro
export interface ILicencesForTrimestres{
  idProfesor:string,
  age:number,
  license_trimestre_1:string;
  license_trimestre_2:string,
  license_trimestre_3:string
}

//Interface para los datos del registro
export interface IRegisterInfo{
  idProfesor:string,
  age:string,
  license_trimestre_1:string,
  license_trimestre_2:string,
  license_trimestre_3:string,
  email:string,
  password:string,
  name:string,
  respuesta:string,
  preguntaId:string
}

//Interface para datos de respuesta de la API de comprobacion de licencias previo al registro
export interface IResponseFromLicencesForTrimestres{
  profesor:{idProfesor:boolean},
  licencias:{
    license_trimestre_1:{licencia:string, age:string, trimestre:number, correct:boolean},
    license_trimestre_2:{licencia:string, age:string, trimestre:number, correct:boolean},
    license_trimestre_3:{licencia:string, age:string, trimestre:number, correct:boolean}},
  success:{success:boolean}
}

//Interface para datos de respuesta de la API de registro y de login
export interface IUserInfo{
  user:{
    name:string,
    email:string,
    unicId:string,
    isProfe:number,
    respuesta:string,
    preguntaId:string,
    updated_at:string,
    created_at:string,
    id:string
  },
  licences:string[],
  token:string
}

//Interface para datos de envio cuando se añaden licencias a un alumno
export interface IAddLicencesInfo{
  idProfesor:string,
  age:number,
  license_trimestre_1:string,
  license_trimestre_2:string,
  license_trimestre_3:string,
  token:string
}

export enum tLicencesProfesorCodes{AVAILABLE, USED, NOT_VALID}
export enum tLicencesAlumnosCodes{AVAILABLE, NOT_VALID, PROFE_NOT_VALID}

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  eventOnTrimestresAvailableChanged = new EventEmitter();

  private urlRegisterApi = environment.apiUrl + "register";
  private urlLoginApi = environment.apiUrl + "login"
  private urlCheckLicensesForTrimestre = environment.apiUrl + "checkLicensesForRegister";
  private urlCheckUsername = environment.apiUrl + "checkUserNameUsed";
  private urlGetSecurityQuestionIdForUsername = environment.apiUrl + "getAnswerForUser";
  private urlCheckSecurityQuestion = environment.apiUrl + "checkAnswerForUser";
  private urlResetPasswordForUser = environment.apiUrl + "resetPasswordForUser";
  private urlAddLicences = environment.apiUrl + "addLicensesForStudent";

  private userLogged:IUserInfo | null = null;
  private trimestresAvailables:TTrimestre[] = [];

  constructor(private http:HttpClient) { }

  checkLicencesForRegister(_licenceTeacher:string, _age:number, _licenceTrimestre1:string, _licenceTrimestre2:string, _licenceTrimestre3:string):Observable<IResponseFromLicencesForTrimestres>{

    let info:ILicencesForTrimestres = {idProfesor:_licenceTeacher, age:_age, license_trimestre_1:_licenceTrimestre1, license_trimestre_2:_licenceTrimestre2, license_trimestre_3:_licenceTrimestre3};

    return this.http.post<IResponseFromLicencesForTrimestres>(this.urlCheckLicensesForTrimestre, info, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  checkUsername(username:string):Observable<any>{

    return this.http.get<any>(this.urlCheckUsername + "/" + username).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  register(registerInfo:RegisterInfo): Observable<IUserInfo> {

    const registerInfoForApi:IRegisterInfo = {
      idProfesor:registerInfo.licenceTeacher,
      age:registerInfo.age.toString(),
      license_trimestre_1:registerInfo.licencesTrimestres[0],
      license_trimestre_2:registerInfo.licencesTrimestres[1],
      license_trimestre_3:registerInfo.licencesTrimestres[2],
      email:registerInfo.username,
      password:registerInfo.password.toUpperCase(),
      name:registerInfo.classroomName,
      respuesta:registerInfo.answerToQuestion.toUpperCase(),
      preguntaId:registerInfo.questionId.toString()
    };

    // console.log("*** Datos enviados al registro ***");
    // console.log("IdProfesor: " + registerInfoForApi.idProfesor);
    // console.log("age: " + registerInfoForApi.age);
    // console.log("Licencia Trimestre 1: " + registerInfoForApi.license_trimestre_1);
    // console.log("Licencia Trimestre 2: " + registerInfoForApi.license_trimestre_2);
    // console.log("Licencia Trimestre 3: " + registerInfoForApi.license_trimestre_3);
    // console.log("username: " + registerInfoForApi.email);
    // console.log("password: " + registerInfoForApi.password);
    // console.log("nombre clase: " + registerInfoForApi.name);
    // console.log("respuesta: " + registerInfoForApi.respuesta);
    // console.log("preguntaId: " + registerInfoForApi.preguntaId);

    return this.http.post<IUserInfo>(this.urlRegisterApi, registerInfoForApi, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  addLicences(_licenceTeacher:string, _age:number, _licenceTrimestre1:string, _licenceTrimestre2:string, _licenceTrimestre3:string, _token:string):Observable<any>{

    const postInfo:IAddLicencesInfo = {
      idProfesor:_licenceTeacher,
      age:_age,
      license_trimestre_1:_licenceTrimestre1,
      license_trimestre_2:_licenceTrimestre2,
      license_trimestre_3:_licenceTrimestre3,
      token:_token
    };
    return this.http.post<any>(this.urlAddLicences, postInfo, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  login(_username:string, _password:string):Observable<IUserInfo>{

    const loginInfo:ILoginInfo = {email:_username, password:_password.toUpperCase(), password_raw:_password};
    return this.http.post<IUserInfo>(this.urlLoginApi, loginInfo, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  getSecurityQuestionIdForUser(username:string):Observable<any>{

    return this.http.get<any>(this.urlGetSecurityQuestionIdForUsername + "/" + username).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  checkSecurityQuestion(_username:string, _answer:string):Observable<any>{

    const postInfo = {username:_username, answer:_answer.toUpperCase()};
    return this.http.post<any>(this.urlCheckSecurityQuestion, postInfo, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  resetPasswordForUser(_username:string, _answer:string, _password:string):Observable<any>{

    const postInfo = {username:_username, answer:_answer.toUpperCase(), password:_password.toUpperCase()};
    return this.http.post<any>(this.urlResetPasswordForUser, postInfo, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );

  }

  private handleError(error: HttpErrorResponse){

    if(error.error instanceof ErrorEvent){
      console.error("Ha ocurrido un error: ", error.error.message);
    }
    else{ //Codigo de error desde el backend
      console.error("Error desde el backend!!!");
      console.error("Codigo: ", error.status);
      console.error("Descripcion: ", error.error);
    }

    return throwError("Ha ocurrido un error en el acceso al servidor.");
  }

  logOut(){

    this.userLogged = null;
    this.trimestresAvailables = [];
  }

  setLoggedUser(user:IUserInfo){

    this.userLogged = user;
    this.trimestresAvailables = [];
    this.setTrimestresAvailables(user.licences);
  }

  isUserLogged():boolean{

    return (this.userLogged != null);
  }

  setTrimestresAvailables(arrayTrimestres:string[]){


    /* PRUEBAS BORRAR */
    // console.warn("ATENCION!!! LOS TRIMESTRES DISPONIBLES ESTAN FAKEADOS POR CODIGO!!!");
    // this.trimestresAvailables.push(TTrimestre['3_1']);
    // this.trimestresAvailables.push(TTrimestre['3_2']);
    // this.trimestresAvailables.push(TTrimestre['3_3']);
    // this.trimestresAvailables.push(TTrimestre['4_1']);
    // this.trimestresAvailables.push(TTrimestre['4_2']);
    // this.trimestresAvailables.push(TTrimestre['4_3']);
    // this.trimestresAvailables.push(TTrimestre['5_1']);
    // this.trimestresAvailables.push(TTrimestre['5_2']);
    // this.trimestresAvailables.push(TTrimestre['5_3']);

    // return;
    /* FIN PRUEBAS */

    arrayTrimestres.forEach(strTrimestre => {
      this.trimestresAvailables.push(this.getTrimestreForApiString(strTrimestre));
    });
  }

  private getTrimestreForApiString(strTrimestre:string):TTrimestre{

    switch(strTrimestre){
      case "3_1":
        return TTrimestre['3_1'];
      case "3_2":
        return TTrimestre['3_2'];
      case "3_3":
        return TTrimestre['3_3'];
      case "4_1":
        return TTrimestre['4_1'];
      case "4_2":
        return TTrimestre['4_2'];
      case "4_3":
        return TTrimestre['4_3'];
      case "5_1":
        return TTrimestre['5_1'];
      case "5_2":
        return TTrimestre['5_2'];
      case "5_3":
        return TTrimestre['5_3'];
      default:
        console.error("ERROR: Hemos recibido un trimestre no valido desde la API de login");
        return TTrimestre['3_1'];
    }
  }

  getName():string{

    if(this.userLogged != null){
      return this.userLogged.user.name;
    }
    else{
      return "";
    }
  }

  getTrimestresAvailables():TTrimestre[]
  {
    return this.trimestresAvailables;
  }

  isTeacher(){

    if(this.userLogged == null) return false;

    return (this.userLogged.user.isProfe == 1) ? true:false;
  }

  addTrimestresAvailables(trimestresToAdd:TTrimestre[]){

    for (let i = 0; i < trimestresToAdd.length; i++) {
      if(this.trimestresAvailables.indexOf(trimestresToAdd[i]) == -1){
        this.trimestresAvailables.push(trimestresToAdd[i]);
      }
    }

    this.eventOnTrimestresAvailableChanged.emit();
  }

  getUserId():string{
    if(this.userLogged  == null) return "";

    return this.userLogged.user.unicId;
  }
}
