export class CardInfo {
    id:string;
    urlImage:string;
    title:string;
    base64Image:string;

    constructor(id:string, urlImage:string, title:string, base64Image:string){
        this.id = id;
        this.urlImage = urlImage;
        this.title = title;
        this.base64Image = base64Image;

    }
}
