import { Component, EventEmitter, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { RegisterInfo } from '../../../shared/models/register-info';
import { IResponseFromLicencesForTrimestres, LoginService } from '../../../shared/services/login.service';
import '@angular/localize/init';
import { LoginErrorMessage } from '../../../shared/classes/login-error-message';
import { TLoginError } from '../../../shared/classes/t-login-error';
import { LicencesForTrimestreValidator } from '../../classes/licences-for-trimestre-validator';
import { LoginErrorMessageComponent } from '../../../shared/components/login-error-message/login-error-message.component';
import { ComboAgeValidator } from '../../classes/combo-age-validator';

const kComboAge3 = $localize`:@@registro.combo_edad_3:3 años`;
const kComboAge4 = $localize`:@@registro.combo_edad_4:4 años`;
const kComboAge5 = $localize`:@@registro.combo_edad_5:5 años`;
const kComboSelectAge = $localize`:@@registro.combo_selecciona_edad:¿3, 4 o 5 años?`;

@Component({
  selector: 'app-register-form-step1',
  templateUrl: './register-form-step1.component.html',
  styleUrls: ['./register-form-step1.component.css']
})
export class RegisterFormStep1Component implements OnInit {

  @Output() eventCompleted = new EventEmitter<RegisterInfo>(); 
  @Output() eventCancelled = new EventEmitter(); 
  @ViewChild(SpinnerComponent,  {static: true}) spinnerController!: SpinnerComponent;
  @ViewChild(LoginErrorMessageComponent,  {static: false}) errorsController!: LoginErrorMessageComponent;

  formCheckTrimestreLicences: UntypedFormGroup;
  ageDropDownOptions: any = [{id:0, name:kComboSelectAge}, {id:3, name:kComboAge3}, {id:4, name:kComboAge4}, {id:5, name:kComboAge5}];

  private formValidated:boolean = false;
  private disabledForm:boolean = false;
  
  constructor(private formBuilder: UntypedFormBuilder, 
    private soundPlayer:SoundPlayerService,
    private loginService:LoginService) { 

    this.formCheckTrimestreLicences = this.formBuilder.group({
      licenciaProfesor: ['', Validators.required],
      licenciaTrimestre1: ['', Validators.nullValidator],
      licenciaTrimestre2: ['', Validators.nullValidator],
      licenciaTrimestre3: ['', Validators.nullValidator],
      comboAge: ['', [Validators.required, ComboAgeValidator()]]
    }, {validator: LicencesForTrimestreValidator('licenciaTrimestre1', 'licenciaTrimestre2', 'licenciaTrimestre3')});
  }


  ngOnInit(): void {
    this.comboAge!.setValue(0);
  }

  checkLicences(){
    
    this.spinnerController.show();

    const licenceTeacher = this.formCheckTrimestreLicences.get('licenciaProfesor')!.value;
    const age = this.formCheckTrimestreLicences!.get('comboAge')!.value;
    const licenceTrimestre1 = this.formCheckTrimestreLicences.get('licenciaTrimestre1')!.value;
    const licenceTrimestre2 = this.formCheckTrimestreLicences.get('licenciaTrimestre2')!.value;
    const licenceTrimestre3 = this.formCheckTrimestreLicences.get('licenciaTrimestre3')!.value;

    this.loginService.checkLicencesForRegister(licenceTeacher, age, licenceTrimestre1, licenceTrimestre2, licenceTrimestre3).subscribe(
      resp => {
        
        if(!this.checkLicencesResponseForErrors(resp)){
          console.log("Algo ha fallado");
          return;
        }

        console.log("Todas las licencias son validas");
        const registerInfo:RegisterInfo = new RegisterInfo();

        registerInfo.licenceTeacher = licenceTeacher;
        registerInfo.age = age;
        registerInfo.licencesTrimestres.push(licenceTrimestre1);
        registerInfo.licencesTrimestres.push(licenceTrimestre2);
        registerInfo.licencesTrimestres.push(licenceTrimestre3);
        
        this.formValidated = true;
        this.eventCompleted.emit(registerInfo);
      },
      error => {
        console.log("Error desde el componente: " + error);
        this.spinnerController.hide();
      },
      () => {
        console.log("Se ha completado el check de licencias previo al registro");
        this.spinnerController.hide();
      }
    );
  }

  private checkLicencesResponseForErrors(resp:IResponseFromLicencesForTrimestres):boolean{

    if(!resp.profesor.idProfesor){
      console.log("licencia de profesor no valida");
      this.errorsController.showError(TLoginError.LICENCE_TEACHER_WRONG);
      return false;
    }

    if(!resp.licencias.license_trimestre_1.correct && resp.licencias.license_trimestre_1.licencia != null){
      console.log("licencia trimestre 1 no valida");
      this.errorsController.showError(TLoginError.LICENCE_TRIMESTRE_1_WRONG);
      return false;
    }

    if(!resp.licencias.license_trimestre_2.correct && resp.licencias.license_trimestre_2.licencia!= null){
      console.log("licencia trimestre 2 no valida"); 
      this.errorsController.showError(TLoginError.LICENCE_TRIMESTRE_2_WRONG);
      return false;
    }

    if(!resp.licencias.license_trimestre_3.correct && resp.licencias.license_trimestre_3.licencia != null){
      console.log("licencia trimestre 3 no valida");
      this.errorsController.showError(TLoginError.LICENCE_TRIMESTRE_3_WRONG);
      return false;
    }

    return true;
  }

  changeRole(e:any) {
    this.comboAge!.setValue(e.target.value, {
      onlySelf: true
    });

    // console.log("Hemos cambiado el rol: " + this.formProfeSelection!.get('comboProfeAlumno').value);
    // console.log("Combo valido: " + this.formProfeSelection!.get('comboProfeAlumno').valid);
    // console.log("Formulario valido: " + this.formProfeSelection!.valid);

    //this.formProfeSelection!.get('comboProfeAlumno').markAsTouched;
  }

  onSubmit(values:any) {
    
    this.soundPlayer.playSoundClick();

    this.errorsController.resetErrors();

    this.checkLicences();
  }

  onCancel(){

    this.soundPlayer.playSoundClick();

    this.eventCancelled.emit();
  }

  onContinue(){
    
  }

  get comboAge(){
    return this.formCheckTrimestreLicences.get('comboAge');
  }

  isContinuarButtonEnabled():boolean{
    return !this.formValidated;
  }

  isFormDisabled():boolean{
    return this.disabledForm;
  }

}

