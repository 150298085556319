import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.css']
})
export class SpinnerComponent implements OnInit {

  noBackground:boolean = false;

  private canShow:boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  show(noBackground:boolean = false){
    
    this.noBackground = noBackground;
    this.canShow = true;
  }

  hide(){
    this.canShow = false;
  }

  //#region template
  canShowForTemplate():boolean{
    return this.canShow;
  }

  //#endregion template

}
