import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SoundDefinition } from '../../classes/sound-definition';
import { AppStateService } from '../../services/app-state.service';
import { SoundPlayerService } from '../../services/sound-player.service';

@Component({
  selector: 'app-flotante-dinamizador-aplausos',
  templateUrl: './flotante-dinamizador-aplausos.component.html',
  styleUrls: ['./flotante-dinamizador-aplausos.component.css']
})
export class FlotanteDinamizadorAplausosComponent implements OnInit {

  @Output() onEventClose = new EventEmitter();
  public imgPreloadUrl = "./assets/dinamizador/aplauso_sheet.png";

  private isShowingFlotanteForTemplate: boolean = false;
  private opacityFlotanteForTemplate: number = 0;

  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  ngOnDestroy():void{
    
    this.soundPlayer.stopSoundFromDefinition(SoundDefinition.APLAUSOS);
  }

  //#region public methods
  public showFlotante(){

    //mostramos flotante
    this.show();

    //lo ocultamos automaticamente despues de tiempo
    setTimeout(() => {
      this.hideFlotante();
    }, 1500);
  }


  private show(){

    this.opacityFlotanteForTemplate = 0;
    this.isShowingFlotanteForTemplate = true;
    setTimeout(() => {
      
      this.opacityFlotanteForTemplate = 1;
      
      //audio
      this.soundPlayer.playSoundFromDefinition(SoundDefinition.APLAUSOS, false);
    }, 50);
  }

  hideFlotante()
  {
    this.opacityFlotanteForTemplate = 0;
    setTimeout(() => {
      this.isShowingFlotanteForTemplate = false;
      this.soundPlayer.stopSoundFromDefinition(SoundDefinition.APLAUSOS);
    }, 300);
  }

  //#endregion

  //#region toques
  onCloseClick(){

    this.soundPlayer.playSoundClick();

    this.hideFlotante();

    setTimeout(() => {
      this.onEventClose.emit();
    }, 300);
  }

  onMinimizaClick(){

    this.soundPlayer.playSoundClick();
  }

  //#endregion toques

  //#region template
  getVisibilityFlotanteForTemplate():string{
    return (this.isShowingFlotanteForTemplate) ? "visible" : "hidden";
  }

  getOpacityFlotanteForTemplate():number{

    return this.opacityFlotanteForTemplate;
  }

  getTitleBarBackgroundColorForTemplate():string{

    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getBackgroundColorForTemplate():string{

    return this.appState.getTrimestreInfo().colorPrimaryLight.rgbString;
  }

  onImageLoaded(){
    console.log("Se ha terminado de cargar la imagen...");
  }
  //#endregion

}
