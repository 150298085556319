<div *ngIf="isMinimizedForTemplate" class="tapadoDinamizador"
    [style.background-image]="'url(' +  getBackgroundImageTapadoForTemplate() + ')'"></div>
<div class="flotante" 
        [ngClass]="{'flotanteMinimized': isMinimizedForTemplate}"
        [ngStyle]="{'cursor':(isMinimizedForTemplate) ? 'pointer' : 'default'}"
        (click)="onClick()"
        [style.background-color]="getBackgroundColorForTemplate()"
        [style.visibility]="getVisibilityFlotanteForTemplate()"
        [style.opacity]="getOpacityFlotanteForTemplate()">
        <div *ngIf="!isMinimizedForTemplate" class="titleBar" [style.background-color]="getTitleBarBackgroundColorForTemplate()">
            <button class="btnClose" (click)="onCloseClick()"></button>
            <button class="btnMinimiza" (click)="onMinimizeClick()"></button>
        </div>
        <div class="caraContainer" 
            [style.background-image]="'url(' + getFaceBackgroundImageForTemplate() + ')'"
            [style.transform]="getTransformFaceForTemplate()"
            [style.transition]="getTransitionFaceForTemplate()"
            [style.animation]="getAnimationFaceForTemplate()">
        </div>
        <div *ngIf="!isMinimizedForTemplate" class="carasBotonera">
            <ng-container *ngFor="let btnFaceInfo of arrayButtonsForTemplate;">
                <app-btn-cara
                    [btnCaraInfo]="btnFaceInfo"
                    (onBtnCaraClicked)="onEventBtnFaceClicked($event)">
                </app-btn-cara>
            </ng-container>
        </div>
</div>
<ng-container *ngFor="let pathToImage of arrayImagesForPreload;">
    <img class="imgPreloadFace" [src]="pathToImage" (load)="onImageLoaded()">
</ng-container>

