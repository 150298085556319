import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { TCuadernoFicha } from '../../shared/classes/t-cuaderno-ficha';
import { StorageManagerService } from '../../shared/services/storage-manager.service';
import { FichaEntrenamientoInfo } from '../models/ficha-entrenamiento-info';
import { FichaInfo } from '../models/ficha-info';
import { FichaTematicaInfo } from '../models/ficha-tematica-info';
import { ThumbInfo } from '../models/thumb-info';
import { environment } from 'src/environments/environment';

class favoritoUpdateInfo{
  fichaId:string = "";
  favorita:number = 0;
  token:string = "";
}

class interactivaCompletedInfo{
  fichaId:string = "";
  hecha:number = 1;
  token:string = "";
}

@Injectable({
  providedIn: 'root'
})
export class FichasService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private urlThumbsAdaptacion = environment.apiUrl + "fichasAdaptacion/getAllMinis/";
  private urlThumbsTematico = environment.apiUrl + "fichasTematicas/getAllMinis/";
  private urlThumbsEntrenamiento = environment.apiUrl + "fichasEntrenamiento/getAllMinis/";

  private urlFichasAdaptacion = environment.apiUrl + "fichasAdaptacion/getFicha/";
  private urlFichasTematico = environment.apiUrl + "fichasTematicas/getFicha/";
  private urlFichasEntrenamiento = environment.apiUrl + "fichasEntrenamiento/getFicha/";

  private urlFavoriteAdaptacionUrl = environment.apiUrl + "fichasAdaptacion/updateFavorito";
  private urlFavoriteTematicoUrl = environment.apiUrl + "fichasTematicas/updateFavorito";
  private urlFavoriteEntrenamientoUrl = environment.apiUrl + "fichasEntrenamiento/updateFavorito";

  private urlFichaInteractivaCompleted = environment.apiUrl + "fichasInteractivas/updateInteractiva"

  constructor(private http:HttpClient, private storageManager:StorageManagerService) { }

  getThumbnails(category:TCuadernoFicha, language:string, age:number, trimestre:number, token:string):Observable<ThumbInfo[]>
  {
    let url = this.getThumbsApiUrl(category) + language + "/" + age + "/" + trimestre;
    const tokenParameter = new HttpParams().set('token', token);
    //console.log("API: " + url);

    return this.http.get<ThumbInfo[]>(url, {params:tokenParameter})
      .pipe(
        // tap(_ => console.log('Datos miniaturas recibidos')),
        catchError(this.handleError<ThumbInfo[]>('getThumbnails', []))
      );
  }

  getFicha(category:TCuadernoFicha, id:string, token:string):Observable<FichaInfo>
  {
    const url = this.getFichasApiUrl(category) + id;
    const tokenParameter = new HttpParams().set('token', token);

    return this.http.get<FichaInfo>(url, {params:tokenParameter})
    .pipe(catchError(this.handleError<FichaInfo>('getFicha')));
  }

  getFichaAdaptacion(id:string, token:string):Observable<FichaInfo>
  {

    const url = this.urlFichasAdaptacion + id;
    const tokenParameter = new HttpParams().set('token', token);

    return this.http.get<FichaInfo>(url, {params:tokenParameter})
      .pipe(
        // tap(_ => console.log('Ficha adaptacion recibida')),
        catchError(this.handleError<FichaInfo>('getFicha'))
      );
  }

  getFichaTematica(id:string, token:string):Observable<FichaTematicaInfo>
  {

    const url = this.urlFichasTematico + id;
    const tokenParameter = new HttpParams().set('token', token);
    //console.log("Token: " + token);

    return this.http.get<FichaTematicaInfo>(url, {params:tokenParameter})
      .pipe(
        // tap(_ => console.log('Ficha tematica recibida')),
        catchError(this.handleError<FichaTematicaInfo>('getFicha'))
      );
  }

  getFichaEntrenamiento(id:string, token:string):Observable<FichaEntrenamientoInfo>
  {

    const url = this.urlFichasEntrenamiento + id;
    const tokenParameter = new HttpParams().set('token', token);

    return this.http.get<FichaEntrenamientoInfo>(url, {params:tokenParameter})
      .pipe(
        // tap(_ => console.log('Ficha entrenamiento recibida')),
        catchError(this.handleError<FichaEntrenamientoInfo>('getFicha'))
      );
  }

  updateFavorite(category:TCuadernoFicha, idFicha:string, favorite:boolean, token:string): Observable<any> {

    //console.log("Token: " + token);

    let info:favoritoUpdateInfo = new favoritoUpdateInfo();
    info.fichaId = idFicha;
    info.favorita = (favorite)?1:0;
    info.token = token;

    let urlApi = this.getFavoritesApiUrl(category);

    return this.http.post<any>(urlApi, info, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError())
    );
  }

  updateFichaInteractivaCompletada(_fichaId:string, completada:boolean, _token:string){

    let info:interactivaCompletedInfo = new interactivaCompletedInfo();
    info.fichaId = _fichaId;
    info.hecha = (completada)?1:0;
    info.token = _token;

    return this.http.post<any>(this.urlFichaInteractivaCompleted, info, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError())
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    //console.log("ERROR!!!");
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private getThumbsApiUrl(category:TCuadernoFicha):string{

    let url:string = "";

    switch(+category){
      case TCuadernoFicha.ADAPTACION:
        url = this.urlThumbsAdaptacion;
        break;
      case TCuadernoFicha.TEMATICO:
        url = this.urlThumbsTematico;
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        url = this.urlThumbsEntrenamiento;
        break;
    }

    //console.log("Url: " + url);
    return url;
  }

  private getFichasApiUrl(category:TCuadernoFicha):string{

    let url:string = "";

    switch(+category){
      case TCuadernoFicha.ADAPTACION:
        url = this.urlFichasAdaptacion;
        break;
      case TCuadernoFicha.TEMATICO:
        url = this.urlFichasTematico;
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        url = this.urlFichasEntrenamiento;
        break;
    }

    //console.log("Url: " + url);
    return url;
  }

  private getFavoritesApiUrl(category:TCuadernoFicha):string{

    let url:string = "";

    switch(+category){
      case TCuadernoFicha.ADAPTACION:
        url = this.urlFavoriteAdaptacionUrl;
        break;
      case TCuadernoFicha.TEMATICO:
        url = this.urlFavoriteTematicoUrl;
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        url = this.urlFavoriteEntrenamientoUrl;
        break;
    }

    return url;
  }
}
