import { Injectable } from '@angular/core';
import { Color } from '../classes/color';
import { TLanguage } from '../classes/t-language';
import { TTrimestre } from '../classes/t-trimestre';
import { TrimestreInfo } from '../models/trimestre-info';
import { TrimestreStaticDataService } from '../services/trimestre-static-data.service';
import { StorageManagerService } from './storage-manager.service';

const kColorTransversal = new Color(128,55,155);

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private currentLanguage:TLanguage = TLanguage.ES;
  private lastUrlNavigated:string = "login";
  private screenScale:number = 1;
  private version="1.6.5 19/10";
  private trimestreInfo:TrimestreInfo | null = null;

  constructor(private trimestreStaticData:TrimestreStaticDataService, 
    private storageManager:StorageManagerService) {}

  public getLanguage():TLanguage{
    return this.currentLanguage;
  }

  public setLanguage(language:TLanguage){
    this.currentLanguage = language;
  }

  public setTrimestre(trimestre:TTrimestre){
    
    this.storageManager.saveTrimestre(trimestre);
    this.trimestreInfo = this.trimestreStaticData.getTrimestreInfo(this.getTrimestre());
  }

  public getTrimestre():TTrimestre{

    return this.storageManager.getTrimestre() as TTrimestre;
  }

  public getTrimestreInfo():TrimestreInfo{
    
    if(this.trimestreInfo == null){
      this.trimestreInfo = this.trimestreStaticData.getTrimestreInfo(this.getTrimestre());
    }
    
    return this.trimestreInfo;
  }

  public getAge():number{

    const trimestre = this.storageManager.getTrimestre() as TTrimestre;
    switch(trimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['3_2']:
      case TTrimestre['3_3']:
        return 3;
      case TTrimestre['4_1']:
      case TTrimestre['4_2']:
      case TTrimestre['4_3']:
        return 4;
      case TTrimestre['5_1']:
      case TTrimestre['5_2']:
      case TTrimestre['5_3']:
        return 5;
    }
  }

  public getNumberOfTrimestre():number{

    const trimestre = this.storageManager.getTrimestre() as TTrimestre;
    switch(trimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['4_1']:
      case TTrimestre['5_1']:
        return 1;
      case TTrimestre['3_2']:
      case TTrimestre['4_2']:
      case TTrimestre['5_2']:
        return 2;
      case TTrimestre['3_3']:
      case TTrimestre['4_3']:
      case TTrimestre['5_3']:
        return 3;
    }
  }

  public getTransversalColor():Color{
    return kColorTransversal;
  }

  public setLastNavigatedUrl(url:string)
  {
    this.lastUrlNavigated = url;
  }

  public getLastNavigatedUrl():string{
    return this.lastUrlNavigated;
  }

  public getVersion():string{
    return "v" + this.version;
  }
}
