import { TGamesId } from "../../shared-games/classes/t-games-id";

export class GameInfo {

    id:string = "";
    url:string = "";
    title:string = "";
    urlImg:string = "";
    session:number = 0;

    constructor(id:string, url:string, title:string, urlImg:string, session:number){

        this.id = id;
        this.url = url;
        this.title = title;
        this.urlImg = urlImg;
        this.session = session;
    }
}
