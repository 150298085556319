
<div class="box">
    <form [formGroup]="formLogin" class="subForm" (ngSubmit)="onSubmit(formLogin.value)">
    
        <input type="text" id="user" formControlName="user" placeholder="Usuario/a">
        <input type="password" id="password" formControlName="password" placeholder="Contraseña">
        
        <span class="advice-links">
            Al hacer click en “ACEPTAR” aceptas los <a href="https://terms.santillana.es/" target="_blank">Términos de Uso</a> de esta plataforma y reconoces haber sido informado de la <a href="https://securitypolicy.santillana.es/" target="_blank">Política de Seguridad</a>.
        </span>    

        <button class="btnLogin" type="submit" [disabled]="formLogin.invalid" i18n="@@varios.aceptar">ACEPTAR</button>
    </form> 
    <button class="btnRecoveryPassword" (click)="onClickPasswordRecovery()" i18n="@@varios.olivado_contrasenya">He olvidado mi contraseña</button>
    <div class="containerMessages">
        <app-login-error-message></app-login-error-message>
    </div>
    <app-spinner></app-spinner>
</div>
