<div class="flotante" 
        [style.visibility]="getVisibilityFlotanteForTemplate()"
        [style.opacity]="getOpacityFlotanteForTemplate()">
        <div class="container">
            <div class="titleBar" [style.background-color]="getTitleBarBackgroundColorForTemplate()">
                <!-- <button class="btnClose" (click)="onCloseClick()"></button> -->
                <!-- <button class="btnMinimiza" (click)="onMinimizaClick()"></button> -->
            </div>
            <div class="aplausosContainer" [style.background-color]="getBackgroundColorForTemplate()">
                <div class="containerImageAplausos"></div>
            </div>
        </div>
</div>
<img class="imgPreload" [src]="imgPreloadUrl" (load)="onImageLoaded()">