import { Injectable } from '@angular/core';
import '@angular/localize/init';
import { LoginErrorMessage } from '../classes/login-error-message';
import { TLoginError } from '../classes/t-login-error';

const kMessageErrorLicenceTeacher = $localize`:@@registro.licencia_profesor_incorrecta:La licencia del profesor/a no es correcta.`;
const kMessageErrorLicenceTrimestre1 = $localize`:@@registro.licencia_trimestre1_incorrecta:La licencia del trimestre 1 no es correcta.`;
const kMessageErrorLicenceTrimestre2 = $localize`:@@registro.licencia_trimestre2_incorrecta:La licencia del trimestre 2 no es correcta.`;
const kMessageErrorLicenceTrimestre3 = $localize`:@@registro.licencia_trimestre3_incorrecta:La licencia del trimestre 3 no es correcta.`;
const kMessageUsernameUsed = $localize`:@@registro.username_existe:El nombre de usuario ya existe.`;
const kMessagePasswordsMismatch = $localize`:@@registro.passwords_no_coinciden:Las contraseñas no coinciden`;
const kMessageInvalidCredentials = $localize`:@@registro.credenciales_incorrectos:Usuario o contraseña incorrectos`;"";
const kMessageUsernameNotFound = $localize`:@@registro.usuario_no_existe:No existe el usuario`;"";
const kMessageSecurityQuestionNotFound = $localize`:@@registro.pregunta_seguridad_no_encontrada:No se ha encontrado pregunta de seguridad`;
const kMessageSecurityAnswerWrong = $localize`:@@registro.respuesta_seguridad_incorrecta:La respuesta no es correcta`;
const kMessageResetPasswordWrong = $localize`:@@registro.reset_password_fallido:Error cambiando la contraseña`;
const kMessagePasswordMinLength = $localize`:@@registro.password_length:La contraseña debe tener al menos 6 caracteres`;
const kMessageUnknownError = $localize`:@@registro.error_desconocido:Se ha producido un error`;

@Injectable({
  providedIn: 'root'
})
export class LoginErrorMessagesService {

  private arrayErrorMessages:LoginErrorMessage[] = [];
 
  
  constructor() { 

    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.LICENCE_TEACHER_WRONG, kMessageErrorLicenceTeacher));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.LICENCE_TRIMESTRE_1_WRONG, kMessageErrorLicenceTrimestre1));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.LICENCE_TRIMESTRE_2_WRONG, kMessageErrorLicenceTrimestre2));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.LICENCE_TRIMESTRE_3_WRONG, kMessageErrorLicenceTrimestre3));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.USERNAME_USED, kMessageUsernameUsed));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.PASSWORD_MISMATCH, kMessagePasswordsMismatch));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.CREDENTIALS_WRONG, kMessageInvalidCredentials));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.USERNAME_NOT_FOUND, kMessageUsernameNotFound));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.SECURITY_ANSWER_WRONG, kMessageSecurityAnswerWrong));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.SECURITY_QUESTION_NOT_FOUND, kMessageSecurityQuestionNotFound));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.RESET_PASSWORD_WRONG, kMessageResetPasswordWrong));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.PASSWORD_MIN_LENGTH, kMessagePasswordMinLength));
    this.arrayErrorMessages.push(new LoginErrorMessage(TLoginError.UNKNOWN, kMessageUnknownError));
  }

  getErrorMessageForType(type:TLoginError):string{
    
    for (let i = 0; i < this.arrayErrorMessages.length; i++) {
      if(this.arrayErrorMessages[i].getType() == type){
        return this.arrayErrorMessages[i].getMessage();
      }
    }

    console.error("[ErrorMessagesService] No hemos encontrado el mensaje para el tipo de error: " + type);
    return "";
  }
}
