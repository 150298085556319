import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { of } from 'rxjs';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { AuthorizationService } from '../../../shared/services/authorization.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { CardInfo } from '../../models/card-info';
import { GeneradorTarjetasService } from '../../services/generador-tarjetas.service';

const kPathToCreateNewCardImage = "./assets/generador_tarjetas/agregar_foto.png";

enum tError{NONE, NO_IMAGE, NO_TITLE};

@Component({
  selector: 'app-editor-tarjetas',
  templateUrl: './editor-tarjetas.component.html',
  styleUrls: ['./editor-tarjetas.component.css']
})
export class EditorTarjetasComponent implements OnInit {

  @Output() eventOnCardUpdated = new EventEmitter();
  @ViewChild(SpinnerComponent,  {static: true}) spinnerController!: SpinnerComponent;
  
  formTitle: UntypedFormGroup;
  urlImage:any;
  canShow:boolean = false;
  opacity:number = 0;
  noImageAdded = true;

  private imageFileToUpload:File | null = null;
  private idCard:string = "";
  private error:tError = tError.NONE;

  constructor(private formBuilder: UntypedFormBuilder, 
    private soundPlayer:SoundPlayerService, 
    private generadorService:GeneradorTarjetasService,
    private authtorizationManager:AuthorizationService) { 
    
      this.formTitle = this.formBuilder.group({
      title: ['', Validators.required],
      token: ['', Validators.required],
      id: ['', Validators.required],
      image: ['', Validators.required],
    });
  }

  ngOnInit(): void {
  }

  show(cardInfo:CardInfo){

    this.error = tError.NONE;

    if(cardInfo.id.length > 0){
      this.showWithInfo(cardInfo);
    }
    else{
      this.showEmptyCard();
    }

    this.canShow = true;
    this.opacity = 1;

    this.formTitle.get('token')!.setValue(this.authtorizationManager.getToken());
    this.formTitle.get('id')!.setValue('Id para testing');
  }

  private showEmptyCard(){

    this.noImageAdded = true;
    this.idCard = "";
    this.imageFileToUpload = null;
    this.urlImage = kPathToCreateNewCardImage;
    this.formTitle.get('title')!.setValue("");
  }

  private showWithInfo(cardInfo:CardInfo){

    this.noImageAdded = false;
    this.idCard = cardInfo.id;
    this.imageFileToUpload = null;
    this.urlImage = cardInfo.urlImage;
    this.formTitle.get('title')!.setValue(cardInfo.title); 
  }

  private hide(){
    this.opacity = 0;
    setTimeout(() => {
      this.canShow = false;
    }, 200);
  }

  private createCard(){

    if(this.imageFileToUpload == null){
      this.error = tError.NO_IMAGE;
      return;
    }

    if(this.formTitle.get('title')!.invalid){
      this.error = tError.NO_TITLE;
      return;
    }

    this.spinnerController.show();
    
    const token = this.authtorizationManager.getToken();
    const title = this.formTitle.get('title')!.value;
    
    this.generadorService.createCard(token, this.imageFileToUpload, title, this.idCard).subscribe(
      (data:any )=> {
        //console.log(data);
        this.eventOnCardUpdated.emit();
        this.hide();
        
      }, error => {
        console.log("ERROR desde el componente!");
        this.spinnerController.hide();
      },
      ()=>{
        this.spinnerController.hide();
      }
    );
  }

  private updateCard(idCard:string){

    if(this.formTitle.get('title')!.invalid){
      this.error = tError.NO_TITLE;
      return;
    }

    if(this.imageFileToUpload == null){
      this.updateCardWithNoImage(idCard);
    }
    else{

      const token = this.authtorizationManager.getToken();
      const title = this.formTitle.get('title')!.value;

      this.spinnerController.show();

      this.generadorService.createCard(token, this.imageFileToUpload, title, this.idCard).subscribe(
        (data:any )=> {
          //console.log(data);
          this.eventOnCardUpdated.emit();
          this.hide();
          
        }, error => {
          console.log("ERROR desde el componente!");
          this.spinnerController.hide();
        },
        ()=>{
          this.spinnerController.hide();
        }
      );
    }
  }

  private updateCardWithNoImage(idCard:string){

    const token = this.authtorizationManager.getToken();
    const title = this.formTitle.get('title')!.value;

    this.spinnerController.show();

    this.generadorService.updateCardWithoutImage(token, title, this.idCard).subscribe(
      (data:any )=> {
        this.eventOnCardUpdated.emit();
        this.hide();
        
      }, error => {
        console.log("ERROR desde el componente!");
        this.spinnerController.hide();
      },
      ()=>{
        this.spinnerController.hide();
      }
    );
  }

  selectImage(event: any) { 

    //añadimos el fichero al formulario
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.formTitle.get('image')!.setValue(file);
    }
		
    const imageFile:File = event.target.files[0];
    
    if(!imageFile || event.target.files[0].length == 0) {
			
      //no tenemos imagen
			return;
		}
		
		var mimeType = imageFile.type;
		
		if (mimeType.match(/image\/*/) == null) {
			
      //no es una imagen
			return;
		}
		
		var reader = new FileReader();
		reader.readAsDataURL(imageFile);
		
		reader.onload = (_event) => {
			
      this.imageFileToUpload = imageFile;
			this.urlImage = reader.result; 
      this.noImageAdded = false;
		}
	}

  //#region  toques
  onImageClick(){

    this.soundPlayer.playSoundClick();

    this.error = tError.NONE;
  }

  onAceptarClick(){

    this.soundPlayer.playSoundClick();

    if(this.idCard == ""){
      this.createCard();
    }
    else{
      this.updateCard(this.idCard);
    }
  }

  onCancelarClick(){

    this.soundPlayer.playSoundClick();

    this.hide();
  }
  //#endregion toques

  onTitleFocus(){

    this.error = tError.NONE;
  }


  onSubmit(value:any) {
   
    this.generadorService.createCardAsForm(value).subscribe(
      (data:any )=> {
        //console.log("Se ha creado la tarjeta desde el formulario: "); 
        //console.log(data.status);
        this.eventOnCardUpdated.emit();
        this.hide();
        
      }, error => {
        console.log("ERROR desde el componente!");
        this.spinnerController.hide();
      },
      ()=>{
        this.spinnerController.hide();
      }
    );
  }

  get Title() {
    return this.formTitle.get('title');
  }

  get Token() {
    return this.formTitle.get('token');
  }

  get Id() {
    return this.formTitle.get('id');
  }

  get Image() {
    return this.formTitle.get('image');
  }

  getErrorType():number{
    return this.error;
  }

}
