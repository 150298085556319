<div class="pageWrapper">
    <header>
        <div class="title"></div>
        <div class="userContainer">
            <!-- <app-btn-login></app-btn-login> -->
            <app-btn-user></app-btn-user>
        </div>
        <div class="guia" (click)="onBtnGuiaClicked()"><p>{{getTitle()}}</p></div>
    </header>
    <div class="mainContainer">
        <div class="containerLeft">
            <div class="containerAge">
                <div class="topBar"></div>
                <div class="ageBar">
                    <div class="ageImageContainer"></div>
                </div>
            </div>
            <div class="containerBotonera">
                <ng-container *ngFor="let button of buttonsTrimestresForTemplate; index as i;">
                    <div class="containerTrimestreButton" *ngIf="i < 3">
                        <app-btn-trimestre
                        [info] = "button"
                        (clickEvent) = "onBtnTrimestreClicked($event)">
                        </app-btn-trimestre>
                    </div>
                    
                </ng-container>
            </div>
        </div>
        <div class="containerMiddle">
            <div class="containerAge">
                <div class="topBar"></div>
                <div class="ageBar">
                    <div class="ageImageContainer"></div>
                </div>
            </div>
            <div class="containerBotonera">
                <ng-container *ngFor="let button of buttonsTrimestresForTemplate; index as i;">
                    <div class="containerTrimestreButton" *ngIf="i > 2 && i < 6">
                        <app-btn-trimestre 
                            [info] = "button"
                            (clickEvent) = "onBtnTrimestreClicked($event)">
                    </app-btn-trimestre>
                    </div> 
                </ng-container>
            </div>
        </div>
        <div class="containerRight">
            <div class="containerAge">
                <div class="topBar"></div>
                <div class="ageBar">
                    <div class="ageImageContainer"></div>
                </div>
            </div>
            <div class="containerBotonera">
                <ng-container *ngFor="let button of buttonsTrimestresForTemplate; index as i;">
                    <div class="containerTrimestreButton" *ngIf="i > 5">
                        <app-btn-trimestre 
                            [info] = "button"
                            (clickEvent) = "onBtnTrimestreClicked($event)">
                        </app-btn-trimestre>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <footer >
        <div class="logo"></div>
        <button (click)="onBtnInfoClicked()" style="cursor:pointer"></button>
    </footer>
</div>
<app-creditos-view ></app-creditos-view>
<app-spinner></app-spinner>
