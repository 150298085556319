export class ThumbInfo {
    unicId:string = "";
    urlMini:string = "";
    favorita:boolean = false;
    enabled:boolean = true;

    constructor(id:string="", urlThumb:string="", favorite:boolean=false){

        this.unicId = id;
        this.urlMini = urlThumb;
        this.favorita = favorite;
    }
}
