import { Injectable } from '@angular/core';
import TrimestreData from '../../../../assets/trimestresInfo.json';
import { Color } from '../classes/color';
import { TTrimestre } from '../classes/t-trimestre';
import { TrimestreInfo } from '../models/trimestre-info';

const kPathToAdaptacionButtonImageName = "./assets/menu_trimestre/adaptacion.png";

export interface IColor{
  r:number;
  g:number;
  b:number;
}

export interface IDescargable{
  type:number;
  description:string;
  title:string;
  pathToThumb:string;
  pathToDownloadFile:string;
}


@Injectable({
  providedIn: 'root'
})
export class TrimestreStaticDataService {

  trimestreData:any = TrimestreData;

  constructor() { }

  getTrimestreInfo(trimestre:TTrimestre):TrimestreInfo{

    for (let i = 0; i < this.trimestreData.length; i++) {

      if(trimestre == this.trimestreData[i].id){
        
        return this.generateTrimestreInfo(this.trimestreData[i]);
      }
    }

    console.error("ERROR: No se ha encontrado informacion del trimestre en el JSON");
    return new TrimestreInfo();
  }

  generateTrimestreInfo(data:any):TrimestreInfo{

    let trimestreInfo = new TrimestreInfo();
    trimestreInfo.id = data.id as TTrimestre;
    trimestreInfo.description = data.idTrimestre;
    trimestreInfo.pathToIcon = data.iconImageName;
    trimestreInfo.pathToLabelImage = data.labelTrimestreImageName;
    trimestreInfo.pathToCuadernoTematicoImage = data.tematicoImageName;
    trimestreInfo.cuadernoTematicoDescription = data.tematicoDescription;
    trimestreInfo.pathToCancionesImage = data.cancionesImageName;
    trimestreInfo.pathToCuadernoEntrenamientoImage = data.entrenamientoImageName;
    trimestreInfo.pathToCuadernoAdaptacionImage = data.adaptacionImageName;
    trimestreInfo.pathToTrazosImage = data.trazosImageName;
    trimestreInfo.pathToFrisoImage = data.frisoImageName;
    trimestreInfo.pathToLibroImage = data.libroImageName;
    trimestreInfo.libroDescription = data.libroDescription;
    trimestreInfo.pathToGamesBackgroundImage = data.gamesBackgroundImageName;
    trimestreInfo.pathToGamesPlayButtonImage = data.gamesPlayButtonImageSheet;
    trimestreInfo.pathToNavButtonGeneradorTarjetas = data.navButtonGeneradorTarjetasImageName;
    trimestreInfo.pathToNavButtonGeneradorTextos = data.navButtonbtnGeneradorTextosImageName;
    trimestreInfo.pathToNavButtonComplementario = data.navButtonbtnComplementarioImageName;
    trimestreInfo.pathToNavButtonDinamizador = data.navButtonbtnDinamizadorImageName;
    trimestreInfo.pathToNavButtonFriso = data.navButtonbtnFrisoImageName;
    trimestreInfo.colorPrimary = new Color(data.primaryColor.r, data.primaryColor.g, data.primaryColor.b);
    trimestreInfo.colorPrimaryDark = new Color(data.primaryColorDark.r, data.primaryColorDark.g, data.primaryColorDark.b);
    trimestreInfo.colorPrimaryLight = new Color(data.primaryColorLight.r, data.primaryColorLight.g, data.primaryColorLight.b);
    trimestreInfo.colorTitles = new Color(data.titlesColor.r, data.titlesColor.g, data.titlesColor.b);
    trimestreInfo.colorTitlesLight = new Color(data.titlesColorLight.r, data.titlesColorLight.g, data.titlesColorLight.b);
    trimestreInfo.urlTextGenerator = data.urlTextGenerator;
    trimestreInfo.pathToDiplomaSuccess = data.pathToDiplomaSuccess;
    trimestreInfo.pathToDiplomaDownload = data.pathToDiplomaDownload;
    trimestreInfo.pathToDiplomaThumb = data.pathToDiplomaThumb;

    return trimestreInfo;
  }

  getIconImageNameForTrimestre(trimestre:TTrimestre):string{
    
    for (let i = 0; i < this.trimestreData.length; i++) {

      if(trimestre == this.trimestreData[i].id){
        return this.trimestreData[i].iconImageName;
      }
    }
    return "";
  }

  getLabelTrimestreImageNameForTrimestre(trimestre:TTrimestre):string{

    for (let i = 0; i < this.trimestreData.length; i++) {
      if(trimestre == this.trimestreData[i].id){
        return this.trimestreData[i].labelTrimestreImageName;
      }
    }
    return "";
  }

  getDescargablesForTrimestre(trimestre:TTrimestre):IDescargable[]{

    for (let i = 0; i < this.trimestreData.length; i++) {
      if(trimestre == this.trimestreData[i].id){
        return this.trimestreData[i].descargables;
      }
    }

    console.error("ERROR: No se han encontrado descargables para el trimestre en el JSON");
    return [];
  }

  getUrlTextGeneratorForTrimestre(trimestre:TTrimestre):string{
    for (let i = 0; i < this.trimestreData.length; i++) {
      if(trimestre == this.trimestreData[i].id){
        return this.trimestreData[i].urlTextGenerator;
      }
    }

    console.error("ERROR: No se han encontrado la url para el generador de tarjetas para el trimestre en el JSON");
    return ""
  }
}
