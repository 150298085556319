import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface IGameInfo{
  unicId:string,
  urlMini:string,
  sesion:number | null,
  title:string,
  urlRecurso:string
}

interface IUpdateGameInfo{
  juegoId:string,
  sesion:number,
  token:string
}

@Injectable({
  providedIn: 'root'
})
export class GamesService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private urlGamesInfo = environment.apiUrl + "juegos/getAllJuegos/";
  private urlUpdateGameSession = environment.apiUrl + "juegos/updateProgreso";
  private urldeleteGameSessions = environment.apiUrl + "juegos/deleteProgreso/";

  constructor(private http:HttpClient) { }

  getAllGames(language:string, age:number, trimestre:number, token:string):Observable<IGameInfo[]>
  {

    let url = this.urlGamesInfo + language + "/" + age + "/" + trimestre;
    const tokenParameter = new HttpParams().set('token', token);

    return this.http.get<IGameInfo[]>(url, {params:tokenParameter})
      .pipe(
        retry(3),
        tap(_ => console.log('Datos de juegos recibidos')),
        catchError(this.handleError<IGameInfo[]>('getAllGames', []))
      );
  }

  updateGameSession(gameId:string, session:number, token:string){

    let info:IUpdateGameInfo = {"juegoId":gameId, "sesion":session, "token":token};

    return this.http.post<any>(this.urlUpdateGameSession, info, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError())
    );
  }

  deleteGameSessions(language:string, age:number, trimestre:number, token:string):Observable<IGameInfo[]>{

    let url = this.urldeleteGameSessions + language + "/" + age + "/" + trimestre;
    const tokenParameter = new HttpParams().set('token', token);

    return this.http.get<IGameInfo[]>(url, {params:tokenParameter})
      .pipe(
        retry(3),
        tap(_ => console.log('Sesiones de juegos borradas')),
        catchError(this.handleError<any>('deleteSessions', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.error("[gamesService] ERROR accediendo a la API");
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
