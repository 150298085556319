import { Component, OnInit, ViewChild } from '@angular/core';
import { BtnMenuTrimestreInfo } from '../../models/btn-menu-trimestre-info';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { TTrimestre } from '../../../shared/classes/t-trimestre';
import { TTrimestreActivity } from '../../classes/t-trimestre-activity';
import { AppStateService } from '../../..//shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { FlotanteFichaInteractivaComponent } from '../../../shared/components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';
import { TCuadernoFicha } from '../../../shared/classes/t-cuaderno-ficha';
import { TrimestreStaticDataService } from '../../..//shared/services/trimestre-static-data.service';
import { TrimestreInfo } from '../../../shared/models/trimestre-info';
import { LisaAnimadaComponent } from '../../../shared/components/lisa-animada/lisa-animada.component';
import { TeoAnimadoComponent } from '../../../shared/components/teo-animado/teo-animado.component';
import { MaxAnimadoComponent } from '../../../shared/components/max-animado/max-animado.component';
import { GeneradorTarjetasViewComponent } from '../../../generador-tarjetas/views/generador-tarjetas-view/generador-tarjetas-view.component';
import { LoginService } from '../../../shared/services/login.service';

@Component({
  selector: 'app-menu-trimestre-view',
  templateUrl: './menu-trimestre-view.component.html',
  styleUrls: ['./menu-trimestre-view.component.css']
})
export class MenuTrimestreViewComponent extends BaseViewComponent implements OnInit {

  @ViewChild(FlotanteFichaInteractivaComponent,  {static: true}) flotante!: FlotanteFichaInteractivaComponent;
  @ViewChild(GeneradorTarjetasViewComponent,  {static: true}) generadorTarjetasController!: GeneradorTarjetasViewComponent;
  @ViewChild(LisaAnimadaComponent,  {static: true}) linaController!: LisaAnimadaComponent;
  @ViewChild(TeoAnimadoComponent,  {static: true}) teoController!: TeoAnimadoComponent;
  @ViewChild(MaxAnimadoComponent,  {static: true}) maxController!: MaxAnimadoComponent;
  
  private blockedButtons:boolean = false;
  private trimestreInfo:TrimestreInfo = new TrimestreInfo();
  
  public arrayButtonsActivityInfoForTemplate:BtnMenuTrimestreInfo[] = [];

  constructor(private loginService:LoginService, 
    private soundPlayer:SoundPlayerService, 
    protected appState:AppStateService, 
    protected navigationManager:NavigationManagerService,
    private staticDataService:TrimestreStaticDataService) {
    
    super(navigationManager, appState); 
  }

  ngOnInit(): void {

    super.ngOnInit();
    
    this.setButtonsActivities();

    //url a la que ir cuando pulsamos en la atras
    this.appState.setLastNavigatedUrl("selector");

    this.trimestreInfo = this.appState.getTrimestreInfo();
  }

  ngAfterViewInit(): void {
    
    super.ngAfterViewInit();

    //ponemos en marcha las animaciones
    setTimeout(() => {
      this.linaController.playRandomAnimationsByAge(this.appState.getAge());
      this.maxController.playRandomAnimationsByAge(this.appState.getAge());
      this.teoController.playRandomAnimationsByAge(this.appState.getAge());
    }, 50);
  } 

  setButtonsActivities(){
    
    
    if(this.loginService.isTeacher()){
      
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.AVENTURAS));
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.FRISO));
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.ENTRENAMIENTO));
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.TRAZOS));
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.TEMATICO));
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.CANCIONES));
    }else{
      
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.AVENTURAS));
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.TRAZOS));
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.CANCIONES));
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.TEMATICO));
    }

    if(this.appState.getTrimestre() == TTrimestre['3_1']){
      this.arrayButtonsActivityInfoForTemplate.push(new BtnMenuTrimestreInfo(TTrimestreActivity.ADAPTACION));
    }
    
  }

  getTrimestreForTemplate():TTrimestre
  {
    return this.appState.getTrimestre();
  }

  getTopBarStyleForTemplate():TTopBarStyles
  {
    return TTopBarStyles.MENU;
  }

  getGamesBackgroundImage(): string{
    
    //return this.appState.getTrimestreInfo().pathToGamesBackgroundImage;

    if(this.isProfesor())
      return this.trimestreInfo.pathToGamesBackgroundImage;
    else
      return this.trimestreInfo.pathToGamesBackgroundImage.split('.png')[0]+'_alumno.png';
  }

  getBtnPlayImage():string{

    //return this.appState.getTrimestreInfo().pathToGamesPlayButtonImage;
    return this.trimestreInfo.pathToGamesPlayButtonImage;
  }

  getBackgroundColorForTemplate():string{
    //return this.appState.getTrimestreInfo().colorPrimary.rgbString;
    return this.trimestreInfo.colorPrimary.rgbString;
  }

  getAgeForTemplate():number{
    return this.appState.getAge();
  }

  onBtnJuegosClicked()
  {
    
    if(this.blockedButtons) return;

    this.blockedButtons = true;
    
    this.soundPlayer.playSoundClick();

    this.navigateTo("juegos");
  }

  //Evento que llega desde el componente BtnTrimestreActivity
  onBtnTrimestreActivityClicked(info:BtnMenuTrimestreInfo){

    let urlToNavigate:string = "";

    switch(info.activity){
      case TTrimestreActivity.TEMATICO:
        urlToNavigate = "menu_fichas/" + TCuadernoFicha.TEMATICO;
        break;
      case TTrimestreActivity.ADAPTACION:
        urlToNavigate = "menu_fichas/" + TCuadernoFicha.ADAPTACION;
        break;
      case TTrimestreActivity.ENTRENAMIENTO:
        // urlToNavigate = "menu_entrenamiento";
        urlToNavigate = "menu_fichas/" + TCuadernoFicha.ENTRENAMIENTO;
        break;
      case TTrimestreActivity.FRISO:
        urlToNavigate = "friso";
        break;
      case TTrimestreActivity.TRAZOS:
        urlToNavigate = "trazos";
        break;
      case TTrimestreActivity.AVENTURAS:
        urlToNavigate = "libro";
        break;
      case TTrimestreActivity.CANCIONES:
        urlToNavigate = "karaoke";
        break;
    }

    this.navigateTo(urlToNavigate);
  }

  //#region overrides
  protected showGeneradorTextos(){
    const urlTextGenerator = this.staticDataService.getUrlTextGeneratorForTrimestre(this.appState.getTrimestre());
    this.flotante.showFlotanteWithUrl(urlTextGenerator);
  }

  protected showGeneradorTarjetas(){
    this.generadorTarjetasController.show();
  }
  //#endregion overrides

  isProfesor(){
    return this.loginService.isTeacher();
  }

  colorJuegosTxt(){
    switch(this.appState.getAge()){
      case 3:
        return "#00877c";
      break;
      case 4:
        return "#80379b";
      break;
      case 5:
        return "#de3830";
      break;
    }
    return "#00877c";

  }
}
