import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { BtnJuegoInfo } from '../../models/btn-juego-info';
import { AppStateService } from '../../../shared/services/app-state.service';
import { BtnJuegoComponent } from '../../components/btn-juego/btn-juego.component';
import { FlotanteVideoComponent } from '../../../shared/components/flotante-video/flotante-video.component';
import { FlotanteCartelaComponent } from '../../../shared/components/flotante-cartela/flotante-cartela.component';
import { GamesManagerService } from '../../../shared-games/services/games-manager.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { FlotanteDeleteLogrosComponent } from '../../components/flotante-delete-logros/flotante-delete-logros.component';
import { VisorJuegoComponent } from '../../components/visor-juego/visor-juego.component';
import { GamesService, IGameInfo } from '../../services/games.service';
import { GameInfo } from '../../models/game-info';
import { TEventFromGame } from '../../classes/t-event-from-game';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import '@angular/localize/init';
import { BtnDiplomaComponent } from '../../components/btn-diploma/btn-diploma.component';
import { LoginService} from '../../../shared/services/login.service';
import { FlotanteAnilloCentralComponent } from '../../components/flotante-anillo-central/flotante-anillo-central.component';
import { TopBarComponent } from '../../../shared/components/top-bar/top-bar.component';

const kPathToAssets = "./assets/juegos/";
const kArrayImgNameAnillos = ["01_anillo_sheet.png", "02_anillo_sheet.png", "03_anillo_sheet.png", "04_anillo_sheet.png"];
const kArrayImgNameAnillosAnimados = ["anillo_rojo_sheet.png", "anillo_morado_sheet.png", "anillo_azul_sheet.png", "anillo_verde_sheet.png"];
const kVideoFileName = "intro_3A_1T.mp4";

const kTextWhenDiplomaLocked = $localize`:@@juegos.sin_poderes:Aún no tienes suficientes poderes en tu anillo. Supera los juegos para hacerte con todos y conseguir tu diploma.`;
const kPathToNarrations = "./assets/locuciones/";
const kNarrationDiplomaLocked = "loc_diploma_locked_es.mp3";
const kNarrationDeleteLogros = "loc_borrado_progresos_es.mp3";


@Component({
  selector: 'app-distribuidor-juegos',
  templateUrl: './distribuidor-juegos.component.html',
  styleUrls: ['./distribuidor-juegos.component.css']
})
export class DistribuidorJuegosComponent extends BaseViewComponent implements OnInit {

  @ViewChildren(BtnJuegoComponent) btnGameControllers!: QueryList<BtnJuegoComponent>;
  @ViewChild(BtnDiplomaComponent, { static: true }) btnDiplomaController!: BtnDiplomaComponent;
  @ViewChild(FlotanteVideoComponent, { static: true }) flotanteVideoController!: FlotanteVideoComponent;
  @ViewChild(FlotanteCartelaComponent, { static: true }) flotanteCartelaController!: FlotanteCartelaComponent;
  @ViewChild(FlotanteDeleteLogrosComponent, { static: true }) flotanteConfirmacionController!: FlotanteDeleteLogrosComponent;
  @ViewChild(VisorJuegoComponent, { static: true }) visorGameController!: VisorJuegoComponent;
  @ViewChild(SpinnerComponent, { static: true }) spinnerController!: SpinnerComponent;
  @ViewChild(FlotanteAnilloCentralComponent, { static: true }) succesRingController!: FlotanteAnilloCentralComponent;
  @ViewChild(TopBarComponent, { static: true }) topBarController!: TopBarComponent;
  
  arrayGameButtonsInfoForTemplate: BtnJuegoInfo[] = [];
  private arrayGameInfo:GameInfo[] = [];
  private idCurrentGameSelected:string = "";
  private newLingoteCompleted:boolean = false;
  private allGamesCompleted:boolean = false;

  constructor(private soundPlayer: SoundPlayerService, 
    private loginService:LoginService,
    protected appState:AppStateService, 
    protected navigationManager:NavigationManagerService, 
    private gameManager:GamesManagerService,
    private storageManager: StorageManagerService, 
    private gamesService:GamesService) { 

    super(navigationManager, appState);
  }

  ngOnInit(): void {
    
    this.spinnerController.show();
  }

  ngAfterViewInit(){

    super.ngAfterViewInit();

    setTimeout(() => {

      this.getGamesInfoFromServer();
    }, 50);
  }

  ngOnDestroy(){
    
    this.stopCurrentAnimations();
  }

  private stopCurrentAnimations(){

    //paramos animaciones de anillo central si las hubiera
    this.succesRingController.cancelShowOperation();

    //paramos animaciones de cada boton
    this.btnGameControllers.forEach(gameBtnController => {
      gameBtnController.cancelSuccessOperation();
    });
  }

  private getGamesInfoFromServer()
  {

    const language = this.appState.getLanguage().toString();
    const age = this.appState.getAge();
    const trimestre = this.appState.getNumberOfTrimestre();
    const token = this.storageManager.getToken();

    this.gamesService.getAllGames(language, age, trimestre, token).subscribe(
      (data:IGameInfo[]) => {

        this.setArrayWithGameInfos(data);

        this.setGameButtonsInfo();
        
        setTimeout(() => {
          this.showGameButtons();
        }, 50);

        //comprobamos si tenemos que desbloquear el diploma
        if(this.areAllGamesCompleted()){
          this.allGamesCompleted = true;
        }

        this.spinnerController.hide();
        if(!this.isProfe()){
          const pathToVideo = kPathToAssets  + kVideoFileName;
          this.flotanteVideoController.show(pathToVideo);
        }
      }, error => {
        console.log("ERROR desde el componente!");
        this.spinnerController.hide();
        if(!this.isProfe()){
          const pathToVideo = kPathToAssets + kVideoFileName;
          this.flotanteVideoController.show(pathToVideo);
        }
      }
    );
  }

  private setArrayWithGameInfos(info:IGameInfo[]){

    for (let i = 0; i < info.length; i++) {
      this.arrayGameInfo.push(new GameInfo(info[i].unicId, info[i].urlRecurso, info[i].title, info[i].urlMini, (info[i].sesion == null) ? 0:info[i].sesion as number));
    }
  }

  private setGameButtonsInfo(){

    let gameInfo: GameInfo;

    for (let i = 0; i < this.arrayGameInfo.length; i++) {
      
      gameInfo = this.arrayGameInfo[i];
      let btnGame = new BtnJuegoInfo(gameInfo, kArrayImgNameAnillos[i],  kArrayImgNameAnillosAnimados[i]);
      this.arrayGameButtonsInfoForTemplate.push(btnGame);
    }
  }

  private showGameButtons()
  {
    let delay = 105;
    let counter = 0;
    this.btnGameControllers.forEach(button => {
      setTimeout(() => {
        button.show();
        //this.soundPlayer.playSoundFromDefinition(SoundDefinition.FLIPCARD);
      }, delay*counter);
      counter++;
    });
  }

  private areAllGamesCompleted():boolean{

    for (let i = 0; i < this.arrayGameButtonsInfoForTemplate.length; i++) {
      if(this.arrayGameButtonsInfoForTemplate[i].gameInfo.session < this.gameManager.getMaxSessions()){
        return false;
      }
    }

    return true;
  }

  private goToGame(info:GameInfo)
  {
    
    //ocultamos submenu de usuario
    this.topBarController.hideUserSubMenu();

    //paramos animaciones pendientes
    this.stopCurrentAnimations();

    //guardamos id del juego
    this.idCurrentGameSelected = info.id;
    
    //mostramos el juego
    this.visorGameController.showGame(info);
  }

  private updateCurrentGameSessions()
  {

    //controlador de la info del juego actual
    const gameController = this.btnGameControllers.find(info => info.btnInfo.gameInfo.id === this.idCurrentGameSelected);
    
    if(gameController == null) {
      console.error("[DistribuidorJuegos] Error: No hemos encontrado el controlador del juego: " + this.idCurrentGameSelected);
      return;
    }

    //sesion por la que vamos en este juego
    const currentSession = gameController.getSession();

    if(currentSession >= this.gameManager.getMaxSessions()) return;

    //actualizamos sesiones en la info del juego
    gameController.updateSessions(currentSession+1);
    
    //marcamos que hemos de dar un nuevo lingote cuando se cierre el juego
    this.newLingoteCompleted = true;
   
    //actualizamos sesion del juego en el servidor
    this.gamesService.updateGameSession(gameController.btnInfo.gameInfo.id, currentSession+1, this.storageManager.getToken()).subscribe(
      (data:any) => {
        
        if(data.exito){
          // console.log("Se ha actualizado la sesion del juego en el server con exito!!!");
          // console.log("game id: " + gameController.btnInfo.gameInfo.id);
          // console.log("sesion: " + currentSession+1);
          // console.log("Token: " + this.storageManager.getToken());
        }
      }, error => {
        
        console.log("ERROR desde el componente!");
      }
    );
  }

  private deleteLogros(){

    const language = this.appState.getLanguage().toString();
    const age = this.appState.getAge();
    const trimestre = this.appState.getNumberOfTrimestre();
    const token = this.storageManager.getToken();

    this.gamesService.deleteGameSessions(language, age, trimestre, token).subscribe(
      (data:IGameInfo[]) => {
      
        //quitamos lingotes y anillos
        this.btnGameControllers.forEach(button => {
          button.resetLogros();
        });

        //bloqueamos diploma
        this.btnDiplomaController.block();

      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  private enableNewLingoteForCurrentGame(){

    this.newLingoteCompleted = false;
    this.btnGameControllers.forEach(button => {
      if(button.btnInfo.gameInfo.id == this.idCurrentGameSelected){
        button.enableNextLingote();
      }
    });
  }

  //#region toques
  onGameButtonClicked(info:GameInfo){
    
    if(info.url != "")
    {

      this.goToGame(info);
    }
  }

  onClickBtnDiploma(){
    
    //ocultamos submenu de usuario
    this.topBarController.hideUserSubMenu();
    
    this.flotanteCartelaController.show(kTextWhenDiplomaLocked);

    this.soundPlayer.playSoundFromUrl(kPathToNarrations + kNarrationDiplomaLocked);
  }

  onClickBtnIntro(){
    
    this.soundPlayer.playSoundClick();

    //ocultamos submenu de usuario
    this.topBarController.hideUserSubMenu();

    const pathToVideo = kPathToAssets + kVideoFileName;

    this.flotanteVideoController.show(pathToVideo);
  }

  onClikBtnBorrarPremios(){
    
    this.soundPlayer.playSoundClick();

    //ocultamos submenu de usuario
    this.topBarController.hideUserSubMenu();

    this.flotanteConfirmacionController.show();

    this.soundPlayer.playSoundFromUrl(kPathToNarrations + kNarrationDeleteLogros);
  }

  onClickFlotanteCartela(){
    this.flotanteCartelaController.hide();
    this.soundPlayer.stopSoundFromFileName(kPathToNarrations + kNarrationDiplomaLocked);
  }

  onClickFlotanteDeleteLogros(deleteLogros:boolean){
    
    if(deleteLogros){
      this.deleteLogros();
    }

    this.soundPlayer.stopSoundFromFileName(kPathToNarrations + kNarrationDeleteLogros);
  }

  onEventFromGame(data:any){
    
    //console.log("He recibido un evento del juego: " + data);
    if(data == TEventFromGame.LOADED){

      //this.fadeIn();
      //this.spinnerController.hide();
    }

    if(data == TEventFromGame.SUCCESS){

      //update de sesiones completadas
      this.updateCurrentGameSessions();
    }

    if(data == TEventFromGame.CLOSE){

      //vemos si hemos de dar algun lingote
      if(this.newLingoteCompleted){
        this.enableNewLingoteForCurrentGame();
      }
    }
  }

  onCloseFlotanteAnilloCentral(){
  
    //vemos si hemos acabamos de completar el ultimo lingote que nos faltaba
    if(this.areAllGamesCompleted()){
      
      //Desbloqueamos boton de diploma
      this.btnDiplomaController.unblock();
    }
  }

  onAnilloReceived(){
    //console.log("Me he enterado de que hemos conseguido un anillo!!!");
    if(this.areAllGamesCompleted()){
      //console.log("Deberia mostrar animacion del anillo central!!!");
      this.succesRingController.show();
    }
  }


  //#endregion toquess

  //#region template
  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.JUEGOS;
  }

  getPageBackgroundcolorForTemplate():string{

    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  isDisabledBtnDiplomaForTemplate():boolean{
    
    return !this.allGamesCompleted;
  }
  //#endregion


  isProfe(){

    return this.loginService.isTeacher();
  }

}
