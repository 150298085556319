import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TCuadernosMenuRecursoItem } from '../../classes/t-cuadernos-menu-recurso-item';
import { BtnFichaMenuDesplegableInfo } from '../../models/btn-ficha-menu-desplegable-info';

const kPathToAssets = "./assets/cuadernos_barra_menu/";
const kImgNameDescargable = "descargable.png";
const kImgNameVideo = "video.png";
const kImgNameInteractivo = "interactivo.png";
const kImgNameAudio = "audio.png";
const kImageTrazo = "trazos.png";
const kImageEntrenamiento = "entrenamiento_desplegable.png";

@Component({
  selector: 'app-btn-ficha-menu-desplegable',
  templateUrl: './btn-ficha-menu-desplegable.component.html',
  styleUrls: ['./btn-ficha-menu-desplegable.component.css']
})
export class BtnFichaMenuDesplegableComponent implements OnInit {

  @Input() info:BtnFichaMenuDesplegableInfo = new BtnFichaMenuDesplegableInfo();
  @Output() clickEvent = new EventEmitter<BtnFichaMenuDesplegableInfo>();

  constructor(private soundPlayer: SoundPlayerService) { }

  ngOnInit(){
    
  }

  onClick(){

    this.soundPlayer.playSoundClick();
    
    this.clickEvent.emit(this.info);
  }

  getBackgroundImageForTemplate():string{

    let imageName = "";
    switch(this.info.type)
    {
      case TCuadernosMenuRecursoItem.DESCARGABLE:
        imageName = kImgNameDescargable;
        break;
      case TCuadernosMenuRecursoItem.INTERACTIVO:
        imageName = kImgNameInteractivo;
        break;
      case TCuadernosMenuRecursoItem.VIDEO:
        imageName = kImgNameVideo;
        break;
      case TCuadernosMenuRecursoItem.AUDIO:
        imageName = kImgNameAudio;
        break;
      case TCuadernosMenuRecursoItem.TRAZO:
        imageName = this.info.name; 
        // document.getElementById("titleResource")!.hidden = true;
        break;
      case TCuadernosMenuRecursoItem.ENTRENAMIENTO:
        imageName = kImageEntrenamiento;
        break;
    }

    return "url('" + kPathToAssets + imageName + "')";
  }

  getTitle():string{

    if( this.info.type == TCuadernosMenuRecursoItem.TRAZO)
      return "";
    else
      return this.info.name;
  }
  getOpacityForTemplate():number{
    
    return (this.isDisabled()) ? 0.3 : 1;
  }

  isDisabled():boolean{
    return false;
    //return (this.info.type != TCuadernosMenuRecursoItem.ENTRENAMIENTO);
  }

}
