<div class="wrapper"
    [style.opacity]="getOpacityForTemplate()"
    [style.visibility]="getVisibilityForTemplate()">
    <div class="cartela">
        <div class="containerText">
            <p i18n="@@juegos.borrar_logros">¿Seguro que quieres borrar todos tus progresos?</p>
        </div>
        <div class="containerButtons">
            <button class="btnDelete" (click)="onClick(true)" i18n="@@varios.si">Sí</button>
            <button class="btnNoDelete" (click)="onClick(false)" i18n="@@juegos.no_borrar_logros">No, volver a juegos</button>
        </div>
    </div>
</div>

