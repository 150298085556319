import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TTrimestre } from '../../classes/t-trimestre';
import { Utils } from '../../classes/utils';
import { AppStateService } from '../../services/app-state.service';
import { SoundPlayerService } from '../../services/sound-player.service';
import { TrimestreStaticDataService } from '../../services/trimestre-static-data.service';
import '@angular/localize/init';

@Component({
  selector: 'app-trimestre-banner',
  templateUrl: './trimestre-banner.component.html',
  styleUrls: ['./trimestre-banner.component.css']
})
export class TrimestreBannerComponent implements OnInit {

  @Input() idTrimestre:TTrimestre = TTrimestre['3_1'];
  @Output() onClickHomeButton = new EventEmitter();

  constructor(private soundPlayer:SoundPlayerService, 
      private trimestreDataService:TrimestreStaticDataService,
      private appState:AppStateService) { }

  ngOnInit(): void {
  }

  getLabel():string{

    let result = "";

    switch(this.idTrimestre)
    {
      case TTrimestre['3_1']: 
      case TTrimestre['4_1']: 
      case TTrimestre['5_1']:
        //result = "Primer trimestre";
        result = $localize`:@@varios.primer_trimestre:Primer trimestre`;
        break;
      case TTrimestre['3_2']:
      case TTrimestre['4_2']:
      case TTrimestre['5_2']:
        //result = "Segundo trimestre";
        result = $localize`:@@varios.segundo_trimestre:Segundo trimestre`;
        break;
      case TTrimestre['3_3']:
      case TTrimestre['4_3']:
      case TTrimestre['5_3']:
        //result = "Tercer trimestre";
        result = $localize`:@@varios.tercer_trimestre:Tercer trimestre`;
        break;
    }
    return result;
  }

  getColor():string{
    
    let result = "";

    switch(this.idTrimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['3_2']:
      case TTrimestre['3_3']:
        result = Utils.colorTitleByAge[0].rgbString;
        break;
      case TTrimestre['4_1']:
      case TTrimestre['4_2']: 
      case TTrimestre['4_3']:
        result = Utils.colorTitleByAge[1].rgbString;
        break;
      case TTrimestre['5_1']:
      case TTrimestre['5_2']: 
      case TTrimestre['5_3']:
        result = Utils.colorTitleByAge[2].rgbString;
        break;
    }
    
    return result;
  }

  getBackgroundImage():string{
    
    const iconImageName = this.trimestreDataService.getIconImageNameForTrimestre(this.appState.getTrimestre());
    // console.log("Icon imageName: " + iconImageName);
    return iconImageName;
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    this.onClickHomeButton.emit();
  }

}
