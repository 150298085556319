import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { RegisterInfo } from '../../../shared/models/register-info';

@Component({
  selector: 'app-flotante-register',
  templateUrl: './flotante-register.component.html',
  styleUrls: ['./flotante-register.component.css']
})
export class FlotanteRegisterComponent implements OnInit {

  @Output() eventRegisterCompleted = new EventEmitter(); 

  showStep1Form:boolean = false;
  showStep2Form:boolean = false;
  showStep3Form:boolean = false;

  private opacityForTemplate = 0;
  private isProfe = false;
  private licencesInfoFromStep1:RegisterInfo | null = null;
  private registerInfo:RegisterInfo | null = null;
  private currentStep:number = 1;
  
  constructor(private soundPlayer:SoundPlayerService) { 
  }

  ngOnInit(): void {
  }

  show(){
    this.showStep(1);
  }

  hide(){
    this.opacityForTemplate = 0;
  }

  private showStep(step:number){
    
    switch(step){
      case 1:
        this.showStep1Form = true;
        this.showStep2Form = false;
        this.showStep3Form = false;
        this.currentStep = 1;
        break;
      case 2:
        this.showStep1Form = false;
        this.showStep2Form = true;
        this.showStep3Form = false;
        this.currentStep = 2;
        break;
      case 3:
        this.showStep1Form = false;
        this.showStep2Form = false;
        this.showStep3Form = true;
        this.currentStep = 3;
        break;
    }

    this.opacityForTemplate = 1;
  }

  //#region toques
  onClick(){

  }

  onRegisterCancelled(){
    this.hide();
  }

  //#endregion toques
  
  onStep1Completed(licencesInfo:RegisterInfo){
   
    this.licencesInfoFromStep1 = licencesInfo;

    setTimeout(() => {
      this.showStep(2);
    }, 50);
    
  }

  onStep2Completed(registerInfo:RegisterInfo){
    
    this.registerInfo = registerInfo;
    
    setTimeout(() => {
      this.showStep(3);
    }, 50);
    
  }

  onStep3Completed(){
    
    console.log("Me he enterado de que hemos completado el paso 3");
    this.eventRegisterCompleted.emit();
    this.hide();
  }

  //#region template
  getOpacityForFlotante():number{
    
    return this.opacityForTemplate;
  }

  isProfeForTemplate():boolean{
    return this.isProfe;
  }

  getLicencesInfoFromStep1ForTemplate(){
    
    return this.licencesInfoFromStep1;
  }

  getRegisterInfo():RegisterInfo | null{
    
    return this.registerInfo;
  }

  getCurrentPageForTemplate(){
    return this.currentStep;
  }

  //#endregion template

}
