<div class="wrapper" 
    [style.opacity]="getOpacityForTemplate()"
    [style.transform]="getTransformForTemplate()">
    <button (click)="onBtnClicked()"
        [style.background-image]="getBackgroundImage()"
        [style.border-color]="getBorderColor()">
        <div class="iconDownload"></div>
    </button>
    <p>{{info.title}}</p>
</div>
