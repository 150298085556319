import { Component, OnInit, ViewChild } from '@angular/core';
import { LisaAnimadaComponent } from '../../../shared/components/lisa-animada/lisa-animada.component';
import { MaxAnimadoComponent } from '../../../shared/components/max-animado/max-animado.component';
import { TeoAnimadoComponent } from '../../../shared/components/teo-animado/teo-animado.component';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { FlotanteLoginComponent } from '../../components/flotante-login/flotante-login.component';
import { FlotantePasswordRecoveryComponent } from '../../components/flotante-password-recovery/flotante-password-recovery.component';
import { FlotanteRegisterComponent } from '../../components/flotante-register/flotante-register.component';
import { LoginService } from '../../../shared/services/login.service';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.css']
})
export class LoginViewComponent extends BaseViewComponent implements OnInit {

  @ViewChild(FlotanteLoginComponent,  {static: true}) loginController!: FlotanteLoginComponent;
  @ViewChild(FlotanteRegisterComponent,  {static: true}) registerController!: FlotanteRegisterComponent;
  @ViewChild(LisaAnimadaComponent,  {static: true}) linaController!: LisaAnimadaComponent;
  @ViewChild(TeoAnimadoComponent,  {static: true}) teoController!: TeoAnimadoComponent;
  @ViewChild(MaxAnimadoComponent,  {static: true}) maxController!: MaxAnimadoComponent;
  @ViewChild(FlotantePasswordRecoveryComponent,  {static: true}) passwordRecoveryController!: FlotantePasswordRecoveryComponent;

  maxClasses: string = "max";
  styleAlertOpacity:string = "0";

  constructor( 
    private loginService:LoginService, 
    private soundPlayer:SoundPlayerService, 
    protected appState: AppStateService, 
    protected navigationManager:NavigationManagerService) {
    
    super(navigationManager, appState); 

    this.autoScaleView = false;
  }

  ngOnInit(){

    if(!this.loginService.isUserLogged()){
      //this.loginController.show();
    }
    else{
      console.log("Ya tenemos usuario logueado: " + this.loginService.getName())
    }

    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundColor="white";
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundImage="url('/assets/login/fondocompleto.png')";

    super.ngAfterViewInit();

    //ponemos en marcha las animaciones
    setTimeout(() => {
      this.linaController.playRandomAnimationsByAge(3);
      this.maxController.playRandomAnimationsByAge(3);
      this.teoController.playRandomAnimationsByAge(3);
    }, 50);
  } 

  onBtnDemoClicked(){
    
    this.soundPlayer.playSoundClick();

    document.location.href = 'https://demobadabam.santillana.es';
  }

  onBtnLoginClicked(){

    this.soundPlayer.playSoundClick();

    if(!this.loginService.isUserLogged()){
      this.loginController.show();
    }
    else{
      console.log("Ya tenemos usuario logueado: " + this.loginService.getName());

      this.navigateTo("selector");
    }
  }

  onBtnRegisterClicked(){

    this.soundPlayer.playSoundClick();

    this.registerController.show();
  }

  onPasswordRecoveryClicked(){

    this.passwordRecoveryController.show();
  }

  onLoginCompleted(){
    //console.log("Me he enterado de que hemos completado el login");
    this.navigateTo("selector");
  }

  onRegisterCompleted(){
    //console.log("Me he enterado de que hemos completado el registro");
    this.navigateTo("selector");
  }

  getVersionForTemplate():string{
    return this.appState.getVersion();
  }
}
