import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './classes/in-memory-data-service';
import { SharedModule } from './modules/shared/shared.module';
import { FrisoModule } from './modules/friso/friso.module';
import { LibroModule } from './modules/libro/libro.module';
import { JuegosModule } from './modules/juegos/juegos.module';
import { KaraokeModule } from './modules/karaoke/karaoke.module';
import { MaterialComplementarioModule } from './modules/material-complementario/material-complementario.module';
import { LoginModule } from './modules/login/login.module';
import { FichasModule } from './modules/fichas/fichas.module';
import { MenuTrimestreModule } from './modules/menu-trimestre/menu-trimestre.module';
import { SelectorTrimestresModule } from './modules/selector-trimestres/selector-trimestres.module';
import { GeneradorTarjetasModule } from './modules/generador-tarjetas/generador-tarjetas.module';
import { TrazosModule } from './modules/trazos/trazos.module';
import { DashboardProfesorModule } from './modules/dashboard-profesor/dashboard-profesor.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedGamesModule } from './modules/shared-games/shared-games.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    LibroModule,
    FrisoModule,
    KaraokeModule,
    JuegosModule,
    MaterialComplementarioModule,
    LoginModule,
    FichasModule,
    MenuTrimestreModule,
    SelectorTrimestresModule,
    SharedGamesModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    GeneradorTarjetasModule,
    TrazosModule,
    DashboardProfesorModule
    // HttpClientInMemoryWebApiModule.forRoot(
    //   InMemoryDataService, { dataEncapsulation: false }
    // )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
