import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TNavBarButtons } from '../../classes/t-nav-bar-buttons';
import { SoundPlayerService } from '../../services/sound-player.service';
import { AppStateService } from '../../services/app-state.service';
import { FlotanteDinamizadorAplausosComponent } from '../flotante-dinamizador-aplausos/flotante-dinamizador-aplausos.component';
import { FlotanteDinamizadorSemaforoComponent } from '../flotante-dinamizador-semaforo/flotante-dinamizador-semaforo.component';
import { FlotanteDinamizadorCarasComponent } from '../flotante-dinamizador-caras/flotante-dinamizador-caras.component';
import { FlotanteDinamizadorRelojComponent } from '../flotante-dinamizador-reloj/flotante-dinamizador-reloj.component';
import { Router } from '@angular/router';
import '@angular/localize/init';
import { TrimestreInfo } from '../../models/trimestre-info';
import { TrimestreStaticDataService } from '../../services/trimestre-static-data.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  @Input() buttonsToShow:TNavBarButtons[] = [];
  @Output() onBtnNavBarClicked = new EventEmitter<TNavBarButtons>();
  @ViewChild(FlotanteDinamizadorAplausosComponent, { static: false }) flotanteAplausosController?: FlotanteDinamizadorAplausosComponent;
  @ViewChild(FlotanteDinamizadorSemaforoComponent, { static: false }) flotanteSemaforoController?: FlotanteDinamizadorSemaforoComponent;
  @ViewChild(FlotanteDinamizadorCarasComponent, { static: false }) flotanteCarasController?: FlotanteDinamizadorCarasComponent;
  @ViewChild(FlotanteDinamizadorRelojComponent, { static: false }) flotanteRelojController?: FlotanteDinamizadorRelojComponent;

  public titleGeneradorTarjetasForTemplate = $localize`:@@navBar.generador_tarjetas:Generador \n de tarjetas`;
  public titleGeneradorTextosForTemplate = $localize`:@@navBar.generador_textos:Generador \n de textos`;
  public titleMaterialComplementarioForTemplate = $localize`:@@navBar.material_complementario:Material complementario`;
  public titleDinamizadorAulaForTemplate = $localize`:@@navBar.dinamizador:Dinamizador \n de aula`;
  public titleFrisoForTemplate = $localize`:@@navBar.friso:Friso`;
  public titleVideoForTemplate = $localize`:@@navBar.video:Video`;

  public showDinamizadorDesplegable:boolean = false;
  private showingDinamizadorAplausos:boolean = false;
  private showingDinamizadorReloj:boolean = false;
  private showingDinamizadorSemaforo:boolean = false;
  private showingDinamizadorCaras:boolean = false;
  private trimestreInfo:TrimestreInfo = new TrimestreInfo();
  
  constructor(private loginService:LoginService, 
    private soundPlayer:SoundPlayerService, 
    private appState:AppStateService, 
    private router:Router) { 

    // //Observamos cambios en la navegacion para ocultar los dinamizadores
    // this.router.events.subscribe((event: Event) => {
    //   if (event instanceof NavigationStart || event instanceof NavigationError) {
    //       console.log("Navegamos...");
    //   }
    // });
  }

  ngOnInit(): void {

    this.trimestreInfo = this.appState.getTrimestreInfo();
  }

  //#region  template
  canShowTarjetasButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.GENERADOR_TARJETAS) && this.loginService.isTeacher();
  }

  canShowTextosButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.GENERADOR_TEXTOS) && this.loginService.isTeacher();
  }

  canShowComplementarioButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.MATERIAL_COMPLEMENTARIO) && this.loginService.isTeacher();
  }

  canShowDinamizadorButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.DINAMIZADOR) && this.loginService.isTeacher();
  }

  canShowFrisoButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.FRISO) && this.loginService.isTeacher();
  }

  canShowKarokeButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.KARAOKE);
  }

  canShowVideoButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.VIDEO);
  }

  canShowGamesTitle():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.GAMES_TITLE);
  }

  canShowDinamizadorDesplegable():boolean
  {
    return this.showDinamizadorDesplegable;
  }

  getOpacityForDinamizadorDesplegable():string{
    
    if(this.showDinamizadorDesplegable) return "1";

    return "0";
  }

  getClassForDisableDinamizadorDesplegable():string{
    if(!this.showDinamizadorDesplegable) return "dinamizador_desplegable_hidden";

    return "";
  }

  getBackgroundColorForTemplate():string{
    
    return this.trimestreInfo.colorPrimary.rgbString;
    //return this.appState.getTrimestreInfo().colorPrimary.rgbString;
    //return this.colorManager.getPrimaryColorForAge(this.appState.getAge()).rgbString;
  }

  getTitleColorForTemplate():string{
    return this.trimestreInfo.colorTitles.rgbString;
    //return this.appState.getTrimestreInfo().colorTitles.rgbString;
    //return this.colorManager.getTitleColorForAge(this.appState.getAge()).rgbString;
  }

  getGeneradorTarjetasButtonImageForTemplate():string{
    return this.trimestreInfo.pathToNavButtonGeneradorTarjetas;
    //return this.appState.getTrimestreInfo().pathToNavButtonGeneradorTarjetas;
  }

  getGeneradorTextosButtonImageForTemplate():string{
    return this.trimestreInfo.pathToNavButtonGeneradorTextos;
    //return this.appState.getTrimestreInfo().pathToNavButtonGeneradorTextos;
  }

  getDinamizadorButtonImageForTemplate():string{
    return this.trimestreInfo.pathToNavButtonDinamizador;
    //return this.appState.getTrimestreInfo().pathToNavButtonDinamizador;
  }

  getMaterialComplementarioButtonImageForTemplate():string{
    return this.trimestreInfo.pathToNavButtonComplementario;
    //return this.appState.getTrimestreInfo().pathToNavButtonComplementario;
  }

  getFrisoButtonImageForTemplate():string{
    return this.trimestreInfo.pathToNavButtonFriso;
    //return this.appState.getTrimestreInfo().pathToNavButtonFriso;
  }

  getDinamizadorDesplegableBackgroundColorForTemplate():string{
    return this.trimestreInfo.colorPrimary.rgbString;
    //return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getDinamizadorButtonBackgroundColorForTemplate():string{
    return this.trimestreInfo.colorTitles.rgbString;
    //return this.appState.getTrimestreInfo().colorTitles.rgbString;
  } 

  canShowDinamizadorAplausos():boolean{
    return this.showingDinamizadorAplausos;
  }

  canShowDinamizadorReloj():boolean{
    return this.showingDinamizadorReloj;
  }

  canShowDinamizadorCaras():boolean{
    return this.showingDinamizadorCaras;
  }

  canShowDinamizadorSemaforo():boolean{
    return this.showingDinamizadorSemaforo;
  }

  //#endregion template

  onBtnTarjetasClicked(){
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.GENERADOR_TARJETAS);
  }

  onBtnTextosClicked(){

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.GENERADOR_TEXTOS);
  }

  onBtnComplementarioClicked()
  {

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.MATERIAL_COMPLEMENTARIO);
  }

  onBtnDinamizadorClicked(){
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = !this.showDinamizadorDesplegable;
   
    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR);
  }

  onBtnDinamizadorPalmasClicked()
  {

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR_PALMAS);

    this.showingDinamizadorAplausos = true;

    setTimeout(() => {
      this.flotanteAplausosController!.showFlotante();
    }, 50);
    
  }

  onBtnDinamizadorSemaforoClicked()
  {
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR_SEMAFORO);

    this.showingDinamizadorSemaforo = true;

    setTimeout(() => {
      this.flotanteSemaforoController!.showFlotante();
    }, 50);
    
  }

  onBtnDinamizadorRelojClicked()
  {
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR_RELOJ);

    this.showingDinamizadorReloj = true;

    setTimeout(() => {
      this.flotanteRelojController!.showFlotante();
    }, 50);
    
  }

  onBtnDinamizadorCaraClicked()
  {
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR_CARA);

    this.showingDinamizadorCaras = true;

    setTimeout(() => {
      this.flotanteCarasController!.showFlotante();
    }, 50);
    
  }

  onBtnFrisoClicked()
  {

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.FRISO);
  }

  onKarokeClicked(){

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.KARAOKE);
  }

  onInstrumentalClicked()
  {

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.INSTRUMENTAL);
  }

  onBtnVideoClicked(){
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.INSTRUMENTAL);
  }

  onDinamizadorAplausosClosed(){
    this.showingDinamizadorAplausos = false;
  }

  onDinamizadorRelojClosed(){
    this.showingDinamizadorReloj = false;
  }

  onDinamizadorSemaforoClosed(){
    this.showingDinamizadorSemaforo = false;
  }

  onDinamizadorCarasClosed(){
    this.showingDinamizadorCaras = false;
  }

  showLogoBddbm(){

    if(!this.loginService.isTeacher() && !this.canShowGamesTitle())
      return true;


    return false;
  }

}
