import { UntypedFormGroup } from "@angular/forms";

export function LicencesForTrimestreValidator(controlNameTrimestre1: string, controlNameTrimestre2: string, controlNameTrimestre3:string) {
    return (formGroup: UntypedFormGroup) => {
        
        const controlTrimestre1 = formGroup.controls[controlNameTrimestre1];
        const controlTrimestre2 = formGroup.controls[controlNameTrimestre2];
        const controlTrimestre3 = formGroup.controls[controlNameTrimestre3];

        if(controlTrimestre1.value.length == 0 && controlTrimestre2.value.length == 0 && controlTrimestre3.value.length == 0){
            controlTrimestre1.setErrors({mustEnterOneLicence:true});
        }
        else{
            controlTrimestre1.setErrors(null);
        }
    }
}
