import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DistribuidorJuegosComponent } from './views/distribuidor-juegos/distribuidor-juegos.component';
import { BtnJuegoComponent } from './components/btn-juego/btn-juego.component';
import { SharedModule } from '../shared/shared.module';
import { LingoteComponent } from './components/lingote/lingote.component';
import { BtnDiplomaComponent } from './components/btn-diploma/btn-diploma.component';
import { FlotanteDeleteLogrosComponent } from './components/flotante-delete-logros/flotante-delete-logros.component';
import { VisorJuegoComponent } from './components/visor-juego/visor-juego.component';
import { FlotanteAnilloCentralComponent } from './components/flotante-anillo-central/flotante-anillo-central.component';


@NgModule({
  declarations: [DistribuidorJuegosComponent, BtnJuegoComponent, LingoteComponent, BtnDiplomaComponent, FlotanteDeleteLogrosComponent, VisorJuegoComponent, FlotanteAnilloCentralComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [DistribuidorJuegosComponent]
})
export class JuegosModule { }
