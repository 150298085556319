import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { ThumbInfo } from '../../models/thumb-info';
import { TCuadernoFicha } from '../../../shared/classes/t-cuaderno-ficha';
import { LoginService } from '../../../shared/services/login.service';

const kPathToAssetsAdaptacion = "./assets/cuadernos_adaptacion/miniaturas/";
const kPathToAssetsTematicos = "./assets/cuadernos_tematicos/miniaturas/";
const kPathToAssetsEntrenamiento = "./assets/cuadernos_entrenamiento/miniaturas/";
const kArraySubfoldersNamesByTrimestre = ["3A_1T", "3A_2T", "3A_3T", "4A_1T", "4A_2T", "4A_3T", "5A_1T", "5A_2T", "5A_3T"];

@Component({
  selector: 'app-btn-thumb',
  templateUrl: './btn-thumb.component.html',
  styleUrls: ['./btn-thumb.component.css']
})
export class BtnThumbComponent implements OnInit {

  @Input() category:TCuadernoFicha = TCuadernoFicha.ADAPTACION;
  @Input() info:ThumbInfo = new ThumbInfo();
  @Output() clickEvent = new EventEmitter<ThumbInfo>();

  showAsFavorito = false;

  private scaleForTemplate = 0;

  constructor(private loginService:LoginService, private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {

    this.showAsFavorito = this.info.favorita;
  }

  onBtnClicked(){

    this.soundPlayer.playSoundClick();

    this.clickEvent.emit(this.info);
  }

  getPathToAssets():string{

    let pathToAssets = "";

    switch(+this.category){
      case TCuadernoFicha.ADAPTACION:
        pathToAssets = kPathToAssetsAdaptacion;
        break;
      case TCuadernoFicha.TEMATICO:
        pathToAssets = kPathToAssetsTematicos;
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        pathToAssets = kPathToAssetsEntrenamiento;
        break;
      default:
        console.error("ATENCION!!! No se ha recibido una categoria de fichas valida");
    }

    return pathToAssets + this.getPathSubfolder() + "/";
  }

  getPathSubfolder():string{
    
    const trimestre = this.appState.getTrimestre();
    
    return kArraySubfoldersNamesByTrimestre[trimestre];
  }

  //#region template

  getBackgroundImage():string
  {

    let pathToThumbImage:string = this.getPathToAssets() + this.info.urlMini;
    //console.log("path to thumb: " + pathToThumbImage);
    return "url('" + pathToThumbImage + "')";
  }

  getBorderColor():string
  {
  
    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getFichaURL():string
  {
    return "/visor_adaptacion/" + this.info.unicId;
  }

  getTransformForTemplate():string
  {
    return "scale(" + this.scaleForTemplate + ")";
  }

  getOpacityForTemplate(){
    
    // return (this.info.enabled)? 1:0.3;
    return 1;
  }

  isProfe(){
    if(this.loginService.isTeacher())
      return true;
    else
      return false;
  }


  //#endregion

  //#region public methods
  public showThumb(){
    this.scaleForTemplate = 1;
  }
  //#endregion

}
