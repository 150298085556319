<div class="flotanteLicencia" *ngIf="!this.mostrarBotones">
    <div class="box">
        <button class="btnClose" (click)="onClickClose()"></button>
        <div class="scroll">
        <div style="margin:44px;">
        <b style="font: 20px 'omnes-semibold';">Créditos</b><br>
        <br>
        Este material es una obra colectiva concebida, diseñada y creada en el Departamento de Ediciones Educativas de Santillana, dirigido por <b>Teresa Grence Ruiz.</b><br>
        <br>
        En su elaboración ha participado el siguiente equipo:<br>
        <br>
        <b style="font: 18px 'omnes-semibold';">DIRECCIÓN DEL PROYECTO</b><br>
        <br>
        <b style="font: 18px 'omnes-semibold';">Dirección editorial de productos digitales:</b> José Manuel Cerezo Gallego<br>
        <b style="font: 18px 'omnes-semibold';">Dirección del proyecto:</b> Ana Isabel Uguina Orozco<br>
        <b style="font: 18px 'omnes-semibold';">Coordinación editorial digital:</b> Silvia Escalante Torres<br>
        <b style="font: 18px 'omnes-semibold';">Edición digital:</b> Silvia Escalante Torres<br>
        <b style="font: 18px 'omnes-semibold';">Definición y creación de productos digitales:</b> Silvia Escalante Torres<br>
        <b style="font: 18px 'omnes-semibold';">Coordinación del desarrollo digital:</b> Francisco Carmona Martínez y Guillermo Aceña León<br>
        <b style="font: 18px 'omnes-semibold';">Coordinación técnica:</b> Ángel Manuel García González<br>
        <b style="font: 18px 'omnes-semibold';">Programación y desarrollo digital:</b> Itbook<br>
        <b style="font: 18px 'omnes-semibold';">Coordinación de la producción digital:</b> Javier Sánchez Claramonte<br>
        <b style="font: 18px 'omnes-semibold';">Coordinación de recursos digitales:</b> Juan Carlos Villa Tejedor<br>
        <b style="font: 18px 'omnes-semibold';">Producción de recursos digitales:</b> Itbook, Juan Carlos Paimes Alcubilla, Beltrán Alonso-Martínez, Marcos Díaz Navazo y Juan Carlos Villa Tejedor<br>
        <b style="font: 18px 'omnes-semibold';">Diseño y maquetación:</b> Itbook<br>
        <br>
        <b style="font: 18px 'omnes-semibold';">DIRECCIÓN DE ARTE</b><br><br>
        <b style="font: 18px 'omnes-semibold';">Dirección de arte:</b> José Crespo González<br>
        <b style="font: 18px 'omnes-semibold';">Jefa de proyecto gráfico:</b> Rosa Marín González<br>
        <b style="font: 18px 'omnes-semibold';">Coordinación de ilustración:</b> Carlos Aguilera Sevillano<br>
        <br>
        <b style="font: 18px 'omnes-semibold';">DIRECCIÓN TÉCNICA</b><br><br>
        <b style="font: 18px 'omnes-semibold';">Dirección técnica:</b> Jorge Mira Fernández<br>
        <b style="font: 18px 'omnes-semibold';">Corrección:</b>  Juan Antonio Segal Flores<br>
        <b style="font: 18px 'omnes-semibold';">Documentación y selección fotográfica:</b> Marina de León-Sotelo Barberá<br>
        <br>
        <b style="font: 18px 'omnes-semibold';">Ilustración</b><br>
        <div class="ex1">
        · Cuaderno temático: Begoña Pons Inchaurraga (3 años), Lidia Cal Gomá (4 años) y Sandra de la Prada López (5 años)</div>
        <div class="ex1">
        · Cuaderno de entrenamiento: Pillet (3 y 4 años) y Marta Chaves Vega (5 años)</div>
        <div class="ex1">
        · Cuentos del mundo (vídeo y pasapáginas): Maite Mutuberria (<i>La oveja tigre</i>, <i>El paisajista</i> y <i>La luciérnaga que no quería volar</i>), Cecilia Moreno (<i>Ratón de campo y ratón de ciudad</i>, <i>Las princesas danzarinas</i> y <i>Ubuntu</i>), Sandra Aguilar (<i>Kahukura y la red</i>, <i>El bosque de los cuentos</i> y <i>El pescador y su mujer</i>), Carmen Queralt (<i>Pequeño Búho</i>, <i>El encantador de serpientes</i> y <i>El tesoro de Bat</i>), Marta Álvarez (<i>El tesoro de los trolls</i>, <i>La bolsa de los cuentos</i> y <i>El hombre del agua</i>) y Ayesha L. Rubio (<i>La casa del Sol y de la Luna</i>, <i>Hailibu, el cazador</i> y <i>La piel del cocodrilo</i>)</div>
        <div class="ex1">
        · Karaoke: Inés Burgos Pía y Mar Ferrero Barrio</div>
        <div class="ex1">
        · Friso: Victoria Fernández López</div>
        <b style="font: 18px 'omnes-semibold';">Imagen y sonido</b><br>
        <div class="ex1">
        · Canciones:<br>
        Música y letra: Jair López<br>
        Estudio de grabación: creamostumusica.com</div>
        <div class="ex1">
        · Intro del juego Badabam:<br>
        Música y letra: Jair López<br>
        Estudio de grabación: creamostumusica.com</div>
        <div class="ex1">
        · Aplausómetro:<br>
        123RF/Craig Nimens; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · Friso (3 años):<br>
        Trimestre 1: 123RF/soundjay, stenrock, floraphonic, kilmermedia, okanakdeniz, Aleksandar Stamenkovic, alanay; ARCHIVO SANTILLANA<br>
        Trimestre 2: 123RF/fxprosound, Craig Nimens, Christian Suroy, piccadillycircus, Aleksandar Stamenkovic, warpefx; ARCHIVO SANTILLANA<br>
        Trimestre 3: 123RF/Craig Nimens, Shawn Hempel, Ladislav Weyrostek, Volodymyr Tsymbaliuk, soundhotdog; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · Friso (4 años):<br>
        Trimestre 1: 123RF/soundjay, stenrock, floraphonic, okanakdeniz, Ladislav Weyrostek, Aleksandar Stamenkovic, alanay; ARCHIVO SANTILLANA<br>
        Trimestre 2: 123RF/Craig Nimens, Christian Suroy, piccadillycircus, Jozsef Szasz-Fabian, Aleksandar Stamenkovic, warpefx; ARCHIVO SANTILLANA<br>
        Trimestre 3: 123RF/Craig Nimens, Shawn Hempel, Ladislav Weyrostek, soundhotdog; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · Friso (5 años):<br>
        Trimestre 1: 123RF/soundjay, stenrock, floraphonic,  okanakdeniz, Ladislav Weyrostek, Aleksandar Stamenkovic, alanay; ARCHIVO SANTILLANA<br>
        Trimestre 2: 123RF/Craig Nimens, Christian Suroy, piccadillycircus, Jozsef Szasz-Fabian, Volodymyr Tsymbaliuk, warpefx; ARCHIVO SANTILLANA<br>
        Trimestre 3: 123RF/soundhotdog, Craig Nimens, Shawn Hempel, Ladislav Weyrostek, Mirko; ARCHIVO SANTILLANA
        </div>
        <div class="ex1">
        · Bits: J. Jaime; J. Lucas; S. Padura; 123RF; GETTY IMAGES SALES SPAIN/ Thinkstock, Photos.com Plus, theverest; HIGHRES PRESS STOCK/ AbleStock.com; ISTOCKPHOTO/Getty Images Sales Spain; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · ¿Qué parte del cuerpo suena? (3 años): 123RF/fxprosound, Volodymyr Tsymbaliuk, Craig Nimens; GETTY IMAGES SALES SPAIN/jaroon; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · Puzles (3 años): S. Padura; 123RF/Irina Schmidt, bubutu, Katarzyna Bialasiewicz, rclassenlayouts, Oleg Mikhaylov; A. G. E. FOTOSTOCK/ Natan Rubio; GETTY IMAGES SALES SPAIN/Ron Watts, blinow61, gerenme, REDA&CO/Riccardo Lombardo, picture alliance, Bicho_raro,  JacobH,  John M. Chase,  10'000 Hours, dm909, Harald Sund, Jose Luis Pelaez Inc, Uwe Krejci,  LeManna; ISTOCKPHOTO/Getty Images Sales Spain; PHOTODISC; SERIDEC PHOTOIMAGENES CD; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · Puzles (4 años): 123RF/Olga Yastremska, oksix, David Pereiras Villagrá, Sue Martin, Anat Chantrakool, maddesigner, Irina Schmidt; GETTY IMAGES SALES SPAIN/Howard Chen, romrodinka, Thinkstock, monkeybusinessimages, picture alliance, fizkes, quintanilla, Leonid Andronov, BraunS, Klaus Brauner, Silvia Bianchini, Jupiterimages, LightFieldStudios, kali9, 3sbworld; María Antònia Rossinyol Seguí; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · Puzles (5 años): 123RF/Mariana Ianovska, tonobalaguer, Andrey Armyagov, Clemens Dünser; GETTY IMAGES SALES SPAIN/vovashevchuk, arousa, aapsky, Thinkstock, Alex, Philartphace, Chris Clor, Frank Lukasseck, SeanPavonePhoto, robertcicchetti, CreativeNature_nl, Stocktrek Images, EyeEm/Eric Métille, Tomás Guardia Bencomo, 3DSculptor; ISTOCKPHOTO/Getty Images Sales Spain; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · ¿Cómo se comunican los animales? (5 años): 123RF/warpefx, Mikhail Tamashou, Ladislav Weyrostek, abloomaudio, Aleksandar Stamenkovic, Thomas Forliti; GETTY IMAGES SALES SPAIN/Thinkstock, EyeEm/Eric Métille,  Helein Weide; ISTOCKPHOTO/Getty Images Sales Spain; ARCHIVO SANTILLANA</div>
        <div class="ex1">
        · Las radiografías (5 años): A. Toril; GETTY IMAGES SALES SPAIN/Thinkstock, alengo; ISTOCKPHOTO; ARCHIVO SANTILLANA</div>
        <br>
        ©2024, Sanoma Educación, S. L. U.<br>
        <br>
        Santillana es una marca registrada directa o indirectamente por Grupo Santillana Educación Global, S. L. U., licenciada a Sanoma Educación, S. L. U.
        </div>
        </div>
    </div>
</div>
