<div class="container"
[style.visibility]="getVisibilityForTemplate()"
[style.opacity]="getOpacityForTemplate()">
    <div class="containerThumbs" [style.opacity]="getOpacityContainerThumbsForTemplate()">
        <ng-container *ngFor="let descargable of arrayThumbsInfo;">
            <app-btn-descargables-thumb
                [info]="descargable"
                (eventOnClick)="eventOnClickDescargableThumb($event)">
            </app-btn-descargables-thumb>
        </ng-container>
    </div>
    <div class="footer">  
        <p i18n="@@varios.descargar_todo">Descargar todo</p>
        <button class="btnDescargarTodo" (click)="onClickDescargarTodo()" ></button>
    </div>
</div>

