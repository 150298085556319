import { Component, OnInit } from '@angular/core';
import { TLoginError } from '../../classes/t-login-error';
import { LoginErrorMessagesService } from '../../services/login-error-messages.service';

@Component({
  selector: 'app-login-error-message',
  templateUrl: './login-error-message.component.html',
  styleUrls: ['./login-error-message.component.css']
})
export class LoginErrorMessageComponent implements OnInit {

  private opacityForMessages = 0;
  private arrayErrors:TLoginError[] = [];
  
  constructor(private errorMessagesService:LoginErrorMessagesService) { }

  ngOnInit(): void {
  }

  showError(errorType:TLoginError){

    //no dejamos mostrar un error que ya estamos mostrando
    for (let i = 0; i < this.arrayErrors.length; i++) {
      if(this.arrayErrors[i] == errorType){
        return;
      }
    }

    this.arrayErrors.push(errorType);
    this.opacityForMessages = 1;
  }

  hideError(errorType:TLoginError){
    
    const indexErrorInArray = this.arrayErrors.indexOf(errorType);

    if(indexErrorInArray == -1) return;

    this.arrayErrors.splice(indexErrorInArray, 1);
    
    //sino quedan mas mensajes que mostrar ocultamos los errores
    if(this.arrayErrors.length == 0){
      this.opacityForMessages = 0;
    }
  }

  resetErrors(){

    this.arrayErrors = [];
    this.opacityForMessages = 0;
  }

  getOpacityForMessages():number{
    return this.opacityForMessages;
  }

  getErrorMessage():string{
    
    //devolvemos siempre el mensaje del primer error en el array
    if(this.arrayErrors.length > 0){
      return this.errorMessagesService.getErrorMessageForType(this.arrayErrors[0]);
    }
    
    return "";
  }

}
