import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThumbsViewComponent } from './views/thumbs-view/thumbs-view.component';
import { BtnThumbComponent } from './components/btn-thumb/btn-thumb.component';
import { SharedModule } from '../shared/shared.module';
import { VisorFichasViewComponent } from './views/visor-fichas-view/visor-fichas-view.component';
import { FichasMenuBarComponent } from './components/fichas-menu-bar/fichas-menu-bar.component';
import { BtnFichaMenuBarComponent } from './components/btn-ficha-menu-bar/btn-ficha-menu-bar.component';
import { BtnFichaMenuDesplegableComponent } from './components/btn-ficha-menu-desplegable/btn-ficha-menu-desplegable.component';


@NgModule({
  declarations: [ThumbsViewComponent, BtnThumbComponent, VisorFichasViewComponent, FichasMenuBarComponent, BtnFichaMenuBarComponent, BtnFichaMenuDesplegableComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[ThumbsViewComponent, BtnFichaMenuBarComponent]
})
export class FichasModule { }
