<div class="pageWrapper" [style.background-color]="getBackgroundColorForTemplate()">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'menu_adaptacion'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="titleBar">
        <p class="title" [style.color]="getColorTitleForTemplate()">{{getFichaTitleForTemplate()}}> </p>
        <p class="subtitle" [style.color]="getColorTitleForTemplate()">{{currentFichaInfo.title}}</p>
    </div>
    <div class="mainContainer">
        <div class="leftBar">
            <!--Btn favorito para el PROFE-->
            <button *ngIf="isAlumno()"
            [ngClass]="{'favorite':canShowFlipButton(), 'favoriteBigger':!canShowFlipButton()}" 
            (click)="onClickFavorite()" >
                <div [ngClass]="{'imgFavorite':favoriteForTemplate, 'imgNoFavorite':!favoriteForTemplate}"></div>
            </button>
            <button class="flip" *ngIf="canShowFlipButton()" (click)="onClickFlip()" ></button><!-- [style.height]="(isAlumno())? '67px':'134px'" -->
            <!--Barra lateral con el menu-->
            <app-fichas-menu-bar
                [fichaType] = "getFichaType()"
                (clickMenuItem)="onClickMenuBarItem($event)"
                (clickDesplegableItem)="onClickMenuBarItemDesplegable($event)">
            </app-fichas-menu-bar>

            <!--Btn favorito para el alumno-->
            <button *ngIf="!isAlumno()"
            [ngClass]="{'alumoRealizada':favoriteForTemplate, 'alumoNoRealizada':!favoriteForTemplate}"
            (click)="onClickFavorite()" >
                <div [ngClass]="{'imgFavoriteAlumno':favoriteForTemplate, 'imgNoFavoriteAlumno':!favoriteForTemplate}"></div>
                <p class="fichaCompletada" >FICHA<br>COMPLETADA</p>
            </button>
            <button class="anterior" (click)="onClickAnterior()" [disabled]="!showManejadores() || isFirstPage()"  [style.background-color]="(!showManejadores() || isFirstPage())? 'rgb(225,180,229)':'rgb(127,54,154)'"></button>
        </div>
        <!--Imagen de la ficha-->
        <div class="main" 
            [style.background-image]="getImgNameForTemplate()"
            [style.opacity]="getImgOpacityForTemplate()">
            <div class="containerPainter">
                <app-painter
                    [width]="widthForCanvas"
                    [height]="heightForCanvas"
                    [top]="topForCanvas"
                    [left]="leftForCanvas"></app-painter>
            </div>
        </div>
        <div class="rightBar">
            <app-painter-bar
                (onClickColorItem)="onEventClickColorItem($event)"
                (onClickBrushSize)="onEventClickBrushSize($event)"
                (onClickEraser)="onEventClickEraser()"
                (onClickTextTool)="onEventClickTextTool($event)"
                (onClickTrash)="onEventClickTrash()">
            </app-painter-bar>
            <button class="siguiente" (click)="onClickSiguiente()" [disabled]="!showManejadores() ||  isLastPage()" [style.background-color]="(!showManejadores() || isLastPage())? 'rgb(225,180,229)':'rgb(127,54,154)'"></button>
        </div>
    </div>
</div>
<app-flotante-ficha-interactiva
    [urlFicha]="getUrlFichaInteractiva()"
    (eventFicha)="onEventFichaInteractiva($event)">
</app-flotante-ficha-interactiva> 


<div id="infoModal" class="modal">

    <!-- Modal content -->
    <div class="modal-content" oncontextmenu="return false;">
      <button class="btnClose"  (click)="closeVideo()"></button>
      <video id="videoPlayer" width="1280" height="720" controls controlsList="nodownload" class="videoPlayer">
        <source src="" type="video/mp4">
      </video>
    </div>
  
  </div>



