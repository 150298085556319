import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { AuthorizationService } from '../../../shared/services/authorization.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TLoginError } from '../../../shared/classes/t-login-error';
import { LoginService } from '../../../shared/services/login.service';
import { LoginErrorMessageComponent } from '../../../shared/components/login-error-message/login-error-message.component';


const kMessageInvalidCredentials = "Usuario o contraseña incorrectos";

@Component({
  selector: 'app-flotante-login',
  templateUrl: './flotante-login.component.html',
  styleUrls: ['./flotante-login.component.css']
})
export class FlotanteLoginComponent implements OnInit {

  @Output() eventLoginCompleted = new EventEmitter(); 
  @Output() eventPasswordRecovery = new EventEmitter(); 
  @ViewChild(SpinnerComponent,  {static: true}) spinnerController!: SpinnerComponent;
  @ViewChild(LoginErrorMessageComponent,  {static: false}) errorsController!: LoginErrorMessageComponent;

  formLogin: UntypedFormGroup;

  private opacityForTemplate = 0;
  
  constructor(private soundPlayer:SoundPlayerService, 
    private formBuilder: UntypedFormBuilder,
    private loginService:LoginService, 
    private authService:AuthorizationService) { 

    this.formLogin = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
  }

  show(){
    this.opacityForTemplate = 1;
  }

  hide(){
    this.opacityForTemplate = 0;
  }

  login(){
    
    let username = this.formLogin!.get('user')!.value!;
    let password = this.formLogin!.get('password')!.value!;
    
    const noBackgroundForSpinner = true;
    this.spinnerController.show(noBackgroundForSpinner);

    this.loginService.login(username, password).subscribe(
      resp => {
        console.log("Licencias: " + resp.licences);
        console.log("Token: " + resp.token);
        this.authService.setToken(resp.token);
        this.loginService.setLoggedUser(resp);
      },
      error => {
        console.log("Error desde el componente: " + error);
        this.errorsController.showError(TLoginError.CREDENTIALS_WRONG);
        this.spinnerController.hide();
      },
      () => {
        this.spinnerController.hide();
        this.hide();
        this.eventLoginCompleted.emit();
      }
    );
  }

  //#region toques
  onClick(){

  }

  //#endregion toques
  onSubmit(values:any) {
  
    this.soundPlayer.playSoundClick();

    //login
    if(this.formLogin.valid) {
      this.errorsController.resetErrors();
      this.login();
    }
  }

  onClickPasswordRecovery(){
    
    this.soundPlayer.playSoundClick();

    this.eventPasswordRecovery.emit();
  }

  //#region template
  getOpacityForFlotante():number{
    return this.opacityForTemplate;
  }

  get User() {
    return this.formLogin.get('user');
  }

  get Password() {
    return this.formLogin.get('password');
  }

  //#endregion template

}
