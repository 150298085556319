<div class="topToolBar">
    <div class="topMessagesBar" *ngIf="status == 1 || status == 2">
        <p *ngIf="status == 1" i18n="@@generador_tarjetas.selecciona_tarjetas_borrar">Selecciona las tarjetas y pulsa el botón OK para borrar.</p>
        <p *ngIf="status == 2" i18n="@@generador_tarjetas.selecciona_tarjetas_imprimir">Selecciona hasta cuatro tarjetas y pulsa el botón OK para imprimir.</p>
    </div>
    <div class="topButtonsBar" *ngIf="status == 1 || status == 2">
        <button class="btnOk" (click)="onOkClick()"></button>
        <button class="btnCancel" (click)="onCancelClick()"></button>
    </div>
</div>
