<div *ngIf="isUserLogged()" id="btnFooter">
    <div class="icon-legal" (click)="openFooter = !openFooter">
        <img class="icon" [class]="{'open': openFooter}" src="assets/barra_navegacion/vector.png" />
        <span *ngIf="!openFooter" class="legal">Legal</span>
    </div>
    <div *ngIf="openFooter" class="links-legal">
        <a class="footer-link" href="https://securitypolicy.santillana.es/" target="_blank">Política de privacidad</a>
        <span class="separator">|</span>
        <a class="footer-link" href="https://terms.santillana.es/" target="_blank">Términos de uso</a>
        <span class="separator">|</span>
        <a class="footer-link" href="https://prod.santillana.es/divulgacion/es/index.html" target="_blank">Declaración de divulgación responsable</a>
    </div>
</div>