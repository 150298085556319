import { TLoginError } from "./t-login-error";

export class LoginErrorMessage {
    private type:TLoginError;
    private message:string = "";

    constructor(type:TLoginError, message:string){
        this.type = type;
        this.message = message;
    }

    getType():TLoginError{
        return this.type;
    }

    getMessage():string{
        return this.message;
    }
}
