import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectorTrimestresViewComponent } from './views/selector-trimestres-view/selector-trimestres-view.component';
import { BtnTrimestreComponent } from './components/btn-trimestre/btn-trimestre.component';
import { SharedModule } from '../shared/shared.module';
import { CreditosViewComponent } from './components/creditos-view/creditos-view.component';


@NgModule({
  declarations: [SelectorTrimestresViewComponent, BtnTrimestreComponent, CreditosViewComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [SelectorTrimestresViewComponent]
})
export class SelectorTrimestresModule { }
