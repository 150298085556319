import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { RegisterInfo } from '../../../shared/models/register-info';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';


@Component({
  selector: 'app-register-form-step3',
  templateUrl: './register-form-step3.component.html',
  styleUrls: ['./register-form-step3.component.css']
})
export class RegisterFormStep3Component implements OnInit {

  @Input() registerInfo:RegisterInfo | null = null;
  @Output() eventCompleted = new EventEmitter(); 
  @ViewChild(SpinnerComponent,  {static: true}) spinnerController!: SpinnerComponent;

  usernameForTemplate:string = "<Nombre de usuario>";
  passwordForTemplate:string = "<Password>";
  questionForTemplate:string = "<Pregunta de seguridad>";
  answerForTemplate:string = "<Respuesta de seguridad>";

  private blockedButtons:boolean = false;

  constructor(private soundPlayer:SoundPlayerService,
    private navigationManager:NavigationManagerService) { 
  }

  ngOnInit(){}  

  ngAfterViewInit(){

    setTimeout(() => {

      if(this.registerInfo != null){
        this.usernameForTemplate = this.registerInfo!.username;
        this.passwordForTemplate = this.registerInfo!.password;
        this.questionForTemplate = this.registerInfo!.question;
        this.answerForTemplate = this.registerInfo!.answerToQuestion;
      }
      
    }, 50);
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    if(this.blockedButtons) return;

    this.blockedButtons = true;

    this.eventCompleted.emit();

  }
}
