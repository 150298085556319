import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TButtonStatus } from '../../../shared/classes/t-button-status';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TCuadernosMenuItem } from '../../classes/t-cuadernos-menu-item';
import { BtnFichaMenuBarInfo } from '../../models/btn-ficha-menu-bar-info';
import { BtnFichaMenuDesplegableInfo } from '../../models/btn-ficha-menu-desplegable-info';

const kPathToAssets = "./assets/cuadernos_barra_menu/";
const kOpacityWhenDisabled = 0.3;

@Component({
  selector: 'app-btn-ficha-menu-bar',
  templateUrl: './btn-ficha-menu-bar.component.html',
  styleUrls: ['./btn-ficha-menu-bar.component.css']
})
export class BtnFichaMenuBarComponent implements OnInit {

  @Input() info!:BtnFichaMenuBarInfo;
  @Output() eventOnClick = new EventEmitter<BtnFichaMenuBarInfo>();
  @Output() eventOnClickItemDesplegable = new EventEmitter<BtnFichaMenuDesplegableInfo>();

  opacityRecursosDesplegableForTemplate=0;
  visibilityRecursosDesplegableForTemplate="hidden";

  private backgroundImageForTemplate = "";
  private title = "";
  private titleFichaInteractiva = $localize`:@@menuFichas.ficha_interactiva:Ficha Interactiva`;
  private titleFichaEntrenamiento = $localize`:@@menuFichas.ficha_entrenamiento:Ficha de Entrenamiento`;
  private titleRecursos = $localize`:@@menuFichas.recursos:Recursos`;
  private titleCurricular = $localize`:@@menuFichas.adaptacion_curricular:Adaptación Curricular`;
  private titleTrazos = $localize`:@@menuFichas.trazos:Trazos`;
  
  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.setBackgroundImage();
      this.setTitle();
    }, 10);
  }

  private setBackgroundImage(){

    let urlImg = "";
    switch(this.info.type){
      case TCuadernosMenuItem.INTERACTIVA:
        urlImg = "ficha_interactiva.png";
        break;
      case TCuadernosMenuItem.ENTRENAMIENTO:
        urlImg = "ficha_entrenamiento.png";
        break;
      case TCuadernosMenuItem.RECURSOS:
        urlImg = "recursos.png";
        break;
      case TCuadernosMenuItem.CURRICULAR:
        urlImg = "adaptacion_curricular.png";
        break;
      case TCuadernosMenuItem.TRAZOS:
        urlImg = "trazos.png";
        break;
    }

    this.backgroundImageForTemplate = kPathToAssets + urlImg;
  }

  private setTitle(){

    switch(this.info.type){
      case TCuadernosMenuItem.INTERACTIVA:
        this.title = this.titleFichaInteractiva;
        break;
      case TCuadernosMenuItem.ENTRENAMIENTO:
        this.title = this.titleFichaEntrenamiento;
        break;
      case TCuadernosMenuItem.RECURSOS:
        this.title = this.titleRecursos;
        break;
      case TCuadernosMenuItem.CURRICULAR:
        this.title = this.titleCurricular;
        break;
      case TCuadernosMenuItem.TRAZOS:
        console.log("seteotitulo");
        this.title = this.titleTrazos;
        break;
    }
  }

  public setStatus(status:TButtonStatus){

    this.info.status = status;
  }

  public setUrlAsociada(url:string)
  {
    this.info.urlAsociada = url;
  }

  public setRecursos(recursos:BtnFichaMenuDesplegableInfo[]){
    
    this.info.recursos = recursos;
  }

  public setEntrenamiento(entrenamiento:BtnFichaMenuDesplegableInfo[]){
    
    this.info.recursos = entrenamiento;
  }

  public setTrazos(trazos:BtnFichaMenuDesplegableInfo[]){
    this.info.recursos = trazos;
  }

  public hideDesplegables(){
    this.hideDesplegableRecursos();
  }


  //#region  toques
  onClick(){
    
    this.soundPlayer.playSoundClick();

    if((this.info.type == TCuadernosMenuItem.RECURSOS || this.info.type == TCuadernosMenuItem.ENTRENAMIENTO || this.info.type == TCuadernosMenuItem.TRAZOS) && this.info.recursos.length>0){
      if(this.opacityRecursosDesplegableForTemplate == 0)
      {
        this.showDesplegableRecursos();
      }
      else{
        this.hideDesplegableRecursos();
      }
    }

    this.eventOnClick.emit(this.info);

  }

  onClickRecurso(info:BtnFichaMenuDesplegableInfo){

    //console.log("Hola soy un boton de menu y me he enterado de que hemos pulsado un boton de desplegable: " + info.url);
    this.eventOnClickItemDesplegable.emit(info);
  }

  private showDesplegableRecursos()
  {
    this.opacityRecursosDesplegableForTemplate = 1;
    this.visibilityRecursosDesplegableForTemplate = "visible";
  }

  private hideDesplegableRecursos()
  {
    this.opacityRecursosDesplegableForTemplate = 0;
    this.visibilityRecursosDesplegableForTemplate = "hidden";
  }

  //#endregion toques
  //#region accesos desde el template 

  isDisabled(){
   
    return (this.info.status == TButtonStatus.DISABLED);
  }

  canShow(){
    return (this.info.status == TButtonStatus.DISABLED || this.info.status == TButtonStatus.ENABLED);
  }

  getBackgroundImage():string{

    return this.backgroundImageForTemplate;
  }

  isDesplegableButton():boolean{
    
    return (this.info.type == TCuadernosMenuItem.RECURSOS || 
      this.info.type == TCuadernosMenuItem.ENTRENAMIENTO || 
      this.info.type == TCuadernosMenuItem.TRAZOS);
  }

  getTitle():string
  {
      return this.title;
  }

  

  getRecursos():BtnFichaMenuDesplegableInfo[]{
    
    return this.info.recursos;
  }

  getFichasEntrenamiento():BtnFichaMenuDesplegableInfo[]{
    return [];
  }

  getTrazos():BtnFichaMenuDesplegableInfo[]{
    return [];
  }

  getOpacity():number{

    return (this.info.status == TButtonStatus.ENABLED) ? 1 : kOpacityWhenDisabled;
  }
  //#endregion

}
