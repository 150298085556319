<button class="user" (click)="onClick()">
    <p>{{getName()}}</p>
</button>
<div class="desplegable" *ngIf="canShowSubMenu()" [style.opacity]="getSubMenuOpacity()">
    <button *ngIf="isTeacher()" class="btnSubMenu btnAlumnos" (click)="onClickSubMenuButton(0)" i18n="@@userTools.alumnos">Alumnado</button>
    <button *ngIf="isTeacher()" class="btnSubMenu btnLicencia" (click)="onClickSubMenuButton(1)" i18n="@@userTolls.licencia">Licencia</button>
    <button *ngIf="!isTeacher()" class="btnSubMenu btnLicencia" (click)="onClickSubMenuButton(3)" i18n="@@userTolls.licencia">Licencia</button>
    <button class="btnSubMenu btnSalir" (click)="onClickSubMenuButton(2)" i18n="@@userTools.salir">Salir</button>
</div>
<app-flotante-licence (eventOnCloseLicenceSplash)="onCloseLicenceSplash()"></app-flotante-licence>
<app-add-licences-form (eventOnCloseAddLicenceSplash)="onCloseAddLicenceSplash()"></app-add-licences-form>

