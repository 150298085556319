import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Color } from '../../../shared/classes/color';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';

const kAnilloAnimationClassName = "anilloWithAnimation";
const kSoundAnilloFileName = "anillo_final.mp3";
const kSoundEnhorabuenaFileName = "enhorabuena.mp3";

@Component({
  selector: 'app-flotante-anillo-central',
  templateUrl: './flotante-anillo-central.component.html',
  styleUrls: ['./flotante-anillo-central.component.css']
})
export class FlotanteAnilloCentralComponent implements OnInit {

  @Output() onClose = new EventEmitter();

  private opacity = 0;
  private canShow:boolean = false;
  private diplomaScale = 0;
  private diplomaRotation = 0;
  private canShowAnillo = false;
  private intervalAnillo: any;
  private intervalDiploma:any;
  
  constructor(private appState:AppStateService, private soundManager:SoundPlayerService) { }

  ngOnInit(): void {
  }

  public show(){
    
    this.opacity = 1;
    this.canShow = true;
    this.intervalAnillo = setTimeout(() => {
      this.showAnillo();
    }, 500);
    this.intervalDiploma = setTimeout(() => {
      this.showDiploma();
    }, 3000);
  }

  public cancelShowOperation(){

    this.stopSounds();
    this.clearAllIntervals();
  }


  private hide(){
    
    this.opacity = 0;
    this.stopSounds();
    this.clearAllIntervals();
    setTimeout(() => {
      this.canShow = false;
      this.onClose.emit();
    }, 200);
    
  }

  private showDiploma(){
    this.diplomaScale = 1;
    this.diplomaRotation = 720;
    this.soundManager.playSoundFromFileName(kSoundEnhorabuenaFileName);
  }

  private showAnillo(){
    this.canShowAnillo = true;
    setTimeout(() => {
      this.soundManager.playSoundFromFileName(kSoundAnilloFileName);
    }, 200);
  }

  private stopSounds(){

    this.soundManager.stopSoundFromFileName(kSoundAnilloFileName);
    this.soundManager.stopSoundFromFileName(kSoundEnhorabuenaFileName);
  }

  private clearAllIntervals(){

    clearInterval(this.intervalAnillo);
    clearInterval(this.intervalDiploma);
  }
  //#region toques
  onClick(){
    
    this.hide();
    this.soundManager.playSoundClick();
  }
  //#endregion toques

  //#region template
  canShowForTemplate():boolean{
    return this.canShow;
  }

  getBackgroundColorForTemplate():string{

    let color:Color = this.appState.getTrimestreInfo().colorPrimary;
    color.a = 0.7;
    return "rgb(" + color.r.toString() + "," + color.g.toString() + "," + color.b.toString() + "," + color.a + ")";
  }

  getPathToDiplomaForTemplate():string{
    return this.appState.getTrimestreInfo().pathToDiplomaSuccess;
  }

  getDiplomaScaleForTemplate():number{
    return this.diplomaScale;
  }

  getDiplomaRotationForTemplate():number{
    return this.diplomaRotation;
  }

  getVisibiltyAnilloForTemplate():string{
    return (this.canShowAnillo) ? "visible":"hidden";
  }

  getClassForAnilloAnimation():string{
    return (this.canShowAnillo) ? kAnilloAnimationClassName:"none";
  }

  getOpacitiyForTemplate():number{
    return this.opacity;
  }

  //#endregion template

}
