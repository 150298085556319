import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface FrisoUrlInfo{
  urlRecurso:string;
}

@Injectable({
  providedIn: 'root'
})
export class FrisoService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private urlFrisoApi = environment.apiUrl + "friso/getFriso/";

  constructor(private http:HttpClient) { }

  getFrisoUrl(language:string, age:number, trimestre:number, token:string):Observable<FrisoUrlInfo>
  {
    const url = this.urlFrisoApi + language + "/" + age + "/" + trimestre;
    //console.log("Url api friso: " + url);
    const tokenParameter = new HttpParams().set('token', token);

    return this.http.get<FrisoUrlInfo>(url, {params:tokenParameter})
    .pipe(catchError(this.handleError<FrisoUrlInfo>('getFrisoUrl')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    //console.log("ERROR!!!");
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
