<div *ngIf="getOpacityForFlotante() > 0" class="pageWrapper" (click)="onClick()" [ngStyle]="{'opacity':getOpacityForFlotante()}">
    <div class="box">
        <div class="topContainer">
            <div class="logo"></div>
            <p class="subTitle" i18n="@@login.registro_de_usuarios">REGISTRO DE USUARIOS</p>
            <p class="pageCounter">{{getCurrentPageForTemplate()}}/3</p>
        </div>
        
        <div class="middleContainer">
             <!-- Paso 1 -->
            <div *ngIf="showStep1Form" class="registerFormStepContainer">
                <app-register-form-step1
                    (eventCompleted)="onStep1Completed($event)"
                    (eventCancelled)="onRegisterCancelled()">
                </app-register-form-step1>
            </div>

            <!-- Paso 2 -->
            <div *ngIf="showStep2Form" class="registerFormStepContainer">
                <app-register-form-step2
                    [registerInfoFromStep1]="getLicencesInfoFromStep1ForTemplate()"
                    (eventCompleted)="onStep2Completed($event)"
                    (eventCancelled)="onRegisterCancelled()">
                </app-register-form-step2>
            </div>

            <!-- Paso 3 -->
            <div *ngIf="showStep3Form" class="registerFormStepContainer">
                <app-register-form-step3
                    [registerInfo]="getRegisterInfo()"
                    (eventCompleted)="onStep3Completed()">
                </app-register-form-step3>
            </div>
        </div>
    </div>
</div>
