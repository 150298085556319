<div class="container"
[style.visibility]="getVisibilityForTemplate()"
[style.opacity]="getOpacityForTemplate()">
    <div class="containerThumbs">
        <ng-container *ngFor="let cuento of arrayThumbsInfo;">
            <app-btn-cuento-thumb
                [info]="cuento"
                (eventOnClick)="eventOnClickCuentoThumb($event)">
            </app-btn-cuento-thumb>
        </ng-container>
    </div>
</div>

