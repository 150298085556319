import { Component, OnInit } from '@angular/core';
import { PersonajeAnimation } from '../../classes/personaje-animation';
import { Utils } from '../../classes/utils';

const kIdleClass="teo";
const kAnimationParpadeoClass="teoParpadeo";
const kAnimationSaludaClass="teoSaluda";
const kAnimationRieClass = "teoRie";
const kAnimationSaltaClass= "teoSalta";
const kTimeSaluda = 1780;
const kTimeSalta = 2071;
const kTimeRie = 1850;
const kTimeParpadeo = 714;

@Component({
  selector: 'app-teo-animado',
  templateUrl: './teo-animado.component.html',
  styleUrls: ['./teo-animado.component.css']
})
export class TeoAnimadoComponent implements OnInit {

  private cssClassName:string = kIdleClass;
  private animParpadeo:PersonajeAnimation = new PersonajeAnimation(kAnimationParpadeoClass, kTimeParpadeo);
  private animSaluda:PersonajeAnimation = new PersonajeAnimation(kAnimationSaludaClass, kTimeSaluda);
  private animRie:PersonajeAnimation = new PersonajeAnimation(kAnimationRieClass, kTimeRie);
  private animSalta:PersonajeAnimation = new PersonajeAnimation(kAnimationSaltaClass, kTimeSalta);
  private arrayAvailableAnimations:PersonajeAnimation[] = [];

  constructor() { }

  ngOnInit(): void {}

  public playRandomAnimationsByAge(age:number){

    this.arrayAvailableAnimations = [this.animParpadeo];
    
    switch(age){
      case 3:
        this.arrayAvailableAnimations.push(this.animSalta);
        break;
      case 4:
        this.arrayAvailableAnimations.push(this.animRie);
        break;
      case 5:
        this.arrayAvailableAnimations.push(this.animSaluda);
        break;
    }

    this.playRandomAnimation();
  }

  public playAllRandomAnimations(){
    
    this.arrayAvailableAnimations = [this.animParpadeo, this.animSalta, this.animSaluda, this.animRie];

    this.playRandomAnimation();
  }

  private playRandomAnimation(){

    setTimeout(() => {
      const randomIndexAnimation = Utils.getRandomInt(0, this.arrayAvailableAnimations.length-1);
      this.playAnimation(this.arrayAvailableAnimations[randomIndexAnimation]);
    }, Utils.getRandomInt(1000, 5000));
  }

  private playAnimation(animation:PersonajeAnimation){
    
    this.cssClassName = animation.cssStyleNameForAnimation;
    setTimeout(() => {
      this.teoReset();
      this.playRandomAnimation();
    }, animation.duration);
  }

  teoReset(){
    this.cssClassName = kIdleClass;
  }

  getClassForTemplate():string
  {
    return this.cssClassName;
  }

}
