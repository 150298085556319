<div class="wrapper">
    <form [formGroup]="formRegister" class="subForm" (ngSubmit)="onSubmit(formRegister.value)">
        
        <input type="text" id="username" formControlName="username" (blur)="onBlurUsername()" placeholder="Introduce tu nombre de usuario" i18n-placeholder>

        <input type="password" id="password" formControlName="password" (input)="onInputPasswords()" placeholder="Introduce tu contraseña" i18n-placeholder>

        <input type="password" id="passwordRepeat" formControlName="passwordRepeat" (input)="onInputPasswords()" placeholder="Repite tu contraseña" i18n-placeholder>
        
        <input type="text" id="classroomName" formControlName="classroomName" placeholder="Introduce tu nombre en clase" i18n-placeholder>

        <select formControlName="comboQuestion"
            class="form-control" 
            (change)="changeRole($event)">
            <option value="" disabled i18n="@@login.pregunta_seguridad">Selecciona una pregunta de seguridad</option>
            <option *ngFor="let role of questionDropDownOptions" [value]="role.id">
                {{role.name}}
        </select>

        <textarea class ="answer" formControlName="answer" placeholder="Respuesta a la pregunta de seguridad" i18n-placeholder></textarea>

        <button type="submit" class="btnRegistro" [disabled]="formRegister.invalid || !isUsernameAvailable()" i18n="@@login.registro">Registro</button>
        <p class="separator">-</p>
        <button class="btnCancelar" (click)="onCancel()" i18n="@@varios.cancelar">Cancelar</button>
    </form>
    <div class="containerMessages">
        <app-login-error-message></app-login-error-message>
    </div>
</div>
<app-spinner></app-spinner>
