import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardProfesorViewComponent } from './views/dashboard-profesor-view/dashboard-profesor-view.component';
import { DashboardProfesorDetalleViewComponent } from './views/dashboard-profesor-detalle-view/dashboard-profesor-detalle-view.component';
import { SharedModule } from '../shared/shared.module';



@NgModule({
  declarations: [DashboardProfesorViewComponent, DashboardProfesorDetalleViewComponent],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports : [
    DashboardProfesorViewComponent,
    DashboardProfesorDetalleViewComponent
  ]
})
export class DashboardProfesorModule { }
