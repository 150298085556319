import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TEventFromFichaInteractiva } from '../../../shared/classes/t-event-from-ficha-interactiva';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { KaraokeConfigService, KaraokeUrlInfo } from '../../services/karaoke-config.service';


@Component({
  selector: 'app-karaoke',
  templateUrl: './karaoke.component.html',
  styleUrls: ['./karaoke.component.css']
})
export class KaraokeComponent extends BaseViewComponent implements OnInit {

  // @Input() urkKaraoke:string = "./assets/karaokes/3A_1T/index.html";
  @Output() eventFromFrame = new EventEmitter<TEventFromFichaInteractiva>();
  @ViewChild('iframe') iframe!: ElementRef;
  safeUrl!:SafeResourceUrl;
  
  private isShowingFlotanteForTemplate: boolean = false;
  private opacityFlotanteForTemplate: number = 0;

  constructor(public sanitizer:DomSanitizer, 
    protected navigationManager:NavigationManagerService, 
    protected appState: AppStateService, 
    private storageManager:StorageManagerService, 
    private serverConfig: KaraokeConfigService) { 

    super(navigationManager, appState);

    if (window.addEventListener) {
      window.addEventListener("message", this.eventFromFrameReceived.bind(this), false);
    } else {
       (<any>window).attachEvent("onmessage", this.eventFromFrameReceived.bind(this));
    }
  }

  ngOnInit(){
    
    super.ngOnInit();

    this.getUrlForKaraoke();
    
  }

  getUrlForKaraoke()
  {

    const language = this.appState.getLanguage();
    const age = this.appState.getAge();
    const trimestre = this.appState.getNumberOfTrimestre();
    const token = this.storageManager.getToken();

    this.serverConfig.getUrlForKaraoke(age, trimestre, language, token).subscribe(
      (data:KaraokeUrlInfo) => {
        //console.log("Data: " + data.urlRecurso);
        this.showFrameWithUrl(data.urlRecurso);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  eventFromFrameReceived: any = (event: any) =>  {
    
    // console.log("Soy el flotante y he recibido un mensaje: " + event.data);
    this.eventFromFrame.emit(event.data);

    //Si se ha recibido evento de cerrar, cerramos la ficha
    if(event.data == TEventFromFichaInteractiva.CLOSE)
    {
      this.hideFrame();
    }
  }

  //#region public methods
  showFrameWithUrl(url:string){

    //console.log("Voy a mostrar el flotante con la ficha: " + this.urlFicha);
    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    
    //mostramos flotante
    this.opacityFlotanteForTemplate = 0;
    this.isShowingFlotanteForTemplate = true;


    setTimeout(() => {
      this.opacityFlotanteForTemplate = 1;
    }, 150);
  }

  hideFrame()
  {
    this.opacityFlotanteForTemplate = 0;
    setTimeout(() => {
      this.isShowingFlotanteForTemplate = false;
    }, 300);
  }

  public onIframeLoaded() {
    if(this.iframe){
      if(sessionStorage.getItem('cancionIndice')){
        console.log("tenemos almacenado un indice de cancion " + sessionStorage.getItem('cancionIndice'));
        this.iframe.nativeElement.contentWindow.postMessage(sessionStorage.getItem('cancionIndice'), "*");
          sessionStorage.removeItem('cancionIndice');
      }
    }
  }

  //#endregion

  //#region template
  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.KARAOKE;
  }

  getVisibilityFlotanteForTemplate():string{
    return (this.isShowingFlotanteForTemplate) ? "visible" : "hidden";
  }

  getOpacityFlotanteForTemplate():number{

    return this.opacityFlotanteForTemplate;
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimaryDark.rgbString;
  }

  getColorTitleForTemplate():string{
    return this.appState.getTransversalColor().rgbString;
  }

  haveValidUrlToShow():boolean{
    
    return(this.safeUrl != null);
  }
  //#endregion

}
