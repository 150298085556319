import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SoundDefinition } from '../../classes/sound-definition';
import { AppStateService } from '../../services/app-state.service';
import { SoundPlayerService } from '../../services/sound-player.service';
import { BtnCaraInfo } from './models/btn-cara-info';

const kPathToAssets = "./assets/dinamizador/";
const kTapadoBackgroundImageByAge = ["btn_dinamizador_minimizado_3A.png", "btn_dinamizador_minimizado_4A.png", "btn_dinamizador_minimizado_5A.png"]
const kPathToCaras = "caras/";
const kArrayButtonsId = ["1_min", "2_min", "3_min", "4_min", "5_min", "6_min", "7_min", "8_min", "9_min"];
const kArrayFaceImageFileNames = ["1.png", "2.png", "3.png", "4.png", "5.png", "6.png", "7.png", "8.png", "9.png"];
const kImageExtension = ".png";
const kDictFaceImagesByButtonId: Map<string, string> = new Map([
  [kArrayButtonsId[0], kArrayFaceImageFileNames[0]],
  [kArrayButtonsId[1], kArrayFaceImageFileNames[1]],
  [kArrayButtonsId[2], kArrayFaceImageFileNames[2]],
  [kArrayButtonsId[3], kArrayFaceImageFileNames[3]],
  [kArrayButtonsId[4], kArrayFaceImageFileNames[4]],
  [kArrayButtonsId[5], kArrayFaceImageFileNames[5]],
  [kArrayButtonsId[6], kArrayFaceImageFileNames[6]],
  [kArrayButtonsId[7], kArrayFaceImageFileNames[7]],
  [kArrayButtonsId[8], kArrayFaceImageFileNames[8]],
  [kArrayButtonsId[9], kArrayFaceImageFileNames[9]]]);

@Component({
  selector: 'app-flotante-dinamizador-caras',
  templateUrl: './flotante-dinamizador-caras.component.html',
  styleUrls: ['./flotante-dinamizador-caras.component.css']
})
export class FlotanteDinamizadorCarasComponent implements OnInit {

  @Output() onEventClose = new EventEmitter();
  private isShowingFlotanteForTemplate: boolean = false;
  private opacityFlotanteForTemplate: number = 0;
  private faceSelectedImagename:string = kArrayFaceImageFileNames[0];
  private scaleForFace:number = 1;
  private transitionFaceForTemplate = "none";
  private animationFaceForTemplate = "none";
  
  public isMinimizedForTemplate: boolean = false;
  public arrayButtonsForTemplate:BtnCaraInfo[] = [];
  public arrayImagesForPreload:string[] = [];

  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {

    if(this.arrayButtonsForTemplate.length == 0){
      this.setButtonsForCaras();
    }

    //array de imagenes grandes para precarga
    let path = kPathToAssets + kPathToCaras;
    for (let i = 0; i <kArrayFaceImageFileNames.length; i++) {
      
      this.arrayImagesForPreload.push(path + kArrayFaceImageFileNames[i]);
    }
  }

  //#region public methods
  public showFlotante(){

    //mostramos flotante
    this.show();
  }

  private setButtonsForCaras(){

    kArrayButtonsId.forEach(buttonId => {
      const pathToImage = kPathToAssets + kPathToCaras + buttonId + kImageExtension;
      const btnFace = new BtnCaraInfo(buttonId, pathToImage);
      this.arrayButtonsForTemplate.push(btnFace);
    });
  }


  private show(){

    this.opacityFlotanteForTemplate = 0;
    this.isShowingFlotanteForTemplate = true;
    setTimeout(() => {
      this.opacityFlotanteForTemplate = 1;
    }, 50);
  }

  hideFlotante()
  {
    this.opacityFlotanteForTemplate = 0;
    setTimeout(() => {
      this.isShowingFlotanteForTemplate = false;
      this.soundPlayer.stopSoundFromDefinition(SoundDefinition.APLAUSOS);
    }, 300);
  }

  private showNewFace(faceImageName:string){

    this.transitionFaceForTemplate = "none";
    this.animationFaceForTemplate = "none";
    this.scaleForFace = 0;
    setTimeout(() => {
      //this.transitionFaceForTemplate = "transform 0.1s";
      this.animationFaceForTemplate = "scaleWithBounce 0.3s";
      this.faceSelectedImagename = faceImageName;
      this.scaleForFace = 1;
      this.soundPlayer.playSoundFromDefinition(SoundDefinition.BOING);
    }, 50);
  }

  //#endregion

  //#region toques
  onCloseClick(){

    this.soundPlayer.playSoundClick();

    this.hideFlotante();

    setTimeout(() => {
      this.onEventClose.emit();
    }, 300);
  }

  onMinimizeClick(){

    this.soundPlayer.playSoundClick();

    //tiempo que tarda en estar minimizado
    setTimeout(() => {
      this.isMinimizedForTemplate = true;
    }, 50);
  }

  onClick(){
    
    if(this.isMinimizedForTemplate){
      
      this.soundPlayer.playSoundClick();
      this.isMinimizedForTemplate = false;
    }
  }

  onEventBtnFaceClicked(btnCaraInfo:BtnCaraInfo){

    if(kDictFaceImagesByButtonId.has(btnCaraInfo.id)){
      const faceImageName = kDictFaceImagesByButtonId.get(btnCaraInfo.id)!;
      this.showNewFace(faceImageName);
    }
  }

  //#endregion toques

  //#region template
  getVisibilityFlotanteForTemplate():string{
    return (this.isShowingFlotanteForTemplate) ? "visible" : "hidden";
  }

  getOpacityFlotanteForTemplate():number{

    return this.opacityFlotanteForTemplate;
  }

  getTitleBarBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getBackgroundColorForTemplate():string{

    if(this.isMinimizedForTemplate){
      switch(this.appState.getAge()){
        case 3:
          return "#045a53";
        break;
        case 4:
          return "#5b2d65";
        break;
        case 5:
          return "#942b2b";
        break;
      }
      return "";
    }
    else{
      return this.appState.getTrimestreInfo().colorPrimaryLight.rgbString;
    }
    
  }
  getBackgroundImageTapadoForTemplate():string{

    return kPathToAssets + kTapadoBackgroundImageByAge[this.appState.getAge() % 3];
  }

  getFaceBackgroundImageForTemplate():string{
    return kPathToAssets + kPathToCaras + this.faceSelectedImagename;
  }

  getTransformFaceForTemplate():string
  {
    return "scale(" + this.scaleForFace + ")";
  }

  getTransitionFaceForTemplate():string{
    return this.transitionFaceForTemplate;
  }

  getAnimationFaceForTemplate():string{
    return this.animationFaceForTemplate;
  }

  onImageLoaded(){
    console.log("Se ha terminado de cargar la imagen...");
  }

  //#endregion

}
