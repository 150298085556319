import { Injectable } from '@angular/core';
import { Color } from '../classes/color';

const kColorPrimaryAge3 = new Color(247, 163, 213);
const kColorPrimaryAge4 = new Color(255, 182, 18);
const kColorPrimaryAge5 = new Color(99, 206, 202);
const kColorPrimaryDarkAge3 = new Color(215, 157, 191);
const kColorPrimaryDarkAge4 = new Color(231, 159, 70);
const kColorPrimaryDarkAge5 = new Color(104, 178, 184);
const kColorPrimaryLightAge3 = new Color(240, 210, 233);
const kColorPrimaryLightAge4 = new Color(247, 228, 188);
const kColorPrimaryLightAge5 = new Color(184, 244, 239);
const kColorTitlesAge3 = new Color(0,135,124);
const kColorTitlesAge4 = new Color(128,55,155);
const kColorTitlesAge5 = new Color(222,56,49);
const kColorTitlesLightAge3 = new Color(157,198,193);
const kColorTitlesLightAge4 = new Color(195,161,208);
const kColorTitlesLightAge5 = new Color(234,125,121);
const kColorTransversal = new Color(128,55,155);


@Injectable({
  providedIn: 'root'
})
export class ColorTrimestresManagerService {

  private colorPrimaryByAge:Color[] = [kColorPrimaryAge3, kColorPrimaryAge4, kColorPrimaryAge5];
  private colorPrimaryDarkByAge:Color[] = [kColorPrimaryDarkAge3, kColorPrimaryDarkAge4, kColorPrimaryDarkAge5];
  private colorPrimaryLightByAge:Color[] = [kColorPrimaryLightAge3, kColorPrimaryLightAge4, kColorPrimaryLightAge5];
  private colorTitleByAge:Color[] = [kColorTitlesAge3, kColorTitlesAge4, kColorTitlesAge5];
  private colorTitleLightByAge:Color[] = [kColorTitlesLightAge3, kColorTitlesLightAge4, kColorTitlesLightAge5];

  constructor() { }

  // public getPrimaryColorForAge(age:number):Color
  //   {   
  //       return this.colorPrimaryByAge[age % 3];
  //   }

  // public getDarkColorForAge(age:number):Color
  // {   

  //     return this.colorPrimaryDarkByAge[age % 3];
  // }

  // public getLightColorForAge(age:number):Color
  // {   

  //     return this.colorPrimaryLightByAge[age % 3];
  // }

  // public getTitleColorForAge(age:number):Color{
  //   return this.colorTitleByAge[age % 3];
  // }

  // public getTitleLightColorForAge(age:number):Color{
  //   return this.colorTitleLightByAge[age % 3];
  // }

  // public getTransversalColor():Color{
  //   return kColorTransversal;
  // }
}
