<div class="pageWrapper">
<div style="background-color: white; height: 100%;">
    <header>
        <button class="btnClose"  (click)="goBack()"></button>

        <div class="title"></div>
        <div class="tabs">
            <div class="tab" (click)="setLesson(0)" id="adaptacionTab">
                <span [ngStyle]="this.getStyle(0)" class="tab-title">Periodo de <br> adaptación</span>
                <img *ngIf="this.lesson != 0" src="assets/dashboard_profesor/pestana1.png" alt="" style="height: 78px;">
                <img *ngIf="this.lesson == 0" src="assets/dashboard_profesor/pestana2.png" alt="" style="height: 78px;">
            </div>
            <div class="tab" (click)="setLesson(1)">
                <span [ngStyle]="this.getStyle(1)" class="tab-title">Primer <br> trimestre</span>
                <img *ngIf="this.lesson != 1" src="assets/dashboard_profesor/pestana1.png" alt="" style="height: 78px;">
                <img *ngIf="this.lesson == 1" src="assets/dashboard_profesor/pestana2.png" alt="" style="height: 78px;">
            </div>
            <div class="tab" (click)="setLesson(2)">
                <span [ngStyle]="this.getStyle(2)" class="tab-title">Segundo <br> trimestre</span>
                <img *ngIf="this.lesson != 2" src="assets/dashboard_profesor/pestana1.png" alt="" style="height: 78px;">
                <img *ngIf="this.lesson == 2" src="assets/dashboard_profesor/pestana2.png" alt="" style="height: 78px;">
            </div>
            <div class="tab" (click)="setLesson(3)">
                <span [ngStyle]="this.getStyle(3)" class="tab-title"> Tercer <br> trimestre</span>
                <img *ngIf="this.lesson != 3" src="assets/dashboard_profesor/pestana1.png" alt="" style="height: 78px;">
                <img *ngIf="this.lesson == 3" src="assets/dashboard_profesor/pestana2.png" alt="" style="height: 78px;">
            </div>
        </div>
    </header>
    <table>
        <thead>
        <tr>
            <!-- <th  style="border: transparent;padding: 0px;"> 
                <button class="btnAtras"  (click)="goBack()"></button>
            </th> -->
            <th colspan="6"  style="border: transparent;"> 
                <!-- <button class="btnAtras"  (click)="goBack()"></button> -->

                <p class="titulo-tabla">{{ this.name }}</p>
            </th>
        </tr>
        <!-- <tr>
            <th width="2%" class="head-lower"></th>
            <th width="58%" class="head-lower">Ficha</th>
            <th width="11%">INTERACTIVAS</th>
            <th width="11%">TEMÁTICAS</th>
            <th width="16%" *ngIf="this.lesson != 0">JUEGOS</th>
        </tr> -->
    </thead>
    <tbody>
        <tr>
            <!-- <th width="2%" class="head-lower"></th> -->
            <th width="55%" class="head-lower">Ficha</th>
            <th width="11%">INTERACTIVAS</th>
            <th width="16%">NO INTERACTIVAS</th>
            <th width="16%" *ngIf="this.lesson != 0" style="background-color: white;">JUEGOS</th>
        </tr>
        <tr *ngFor="let tematica of tematicas">
        <!-- <td width="2%">{{ tematica.index }}</td> -->
        <td width="55%">{{ tematica.title}}</td>
        <td width="11%" class="check-field">
            <img *ngIf="tematica.hecha == 1" src="assets/dashboard_profesor/CHECK.png" alt="">
            <img *ngIf="tematica.hecha != 1" src="assets/dashboard_profesor/CHECK_PLACEHOLDER.png" alt="">
        </td>
        <td width="16%" class="check-field" >
            <img *ngIf="tematica.favorita == 1" src="assets/dashboard_profesor/CHECK.png" alt="">
            <img *ngIf="tematica.favorita != 1" src="assets/dashboard_profesor/CHECK_PLACEHOLDER.png" alt="">
        </td>
        <td width="16%" class="bar-field" *ngIf="([0,3,6,9].includes(tematica.index-1) || tematica.index-1 >= 12) && this.lesson !=0" [attr.rowspan]="[0,3,6,9].includes(tematica.index-1) ? 3 : 1" [ngStyle]="getJuegosStyle(tematica.index-1)" style="background-color: white;border: none;">
            <div *ngIf="[0,3,6,9].includes(tematica.index-1)" style="width: 285px;padding: 21px;">
            <span>
                {{ this.juegos[(tematica.index-1)/3].title }}
            </span>
            <span class="percentage">{{ getFloor((this.juegos[(tematica.index-1)/3].sesion/3*100)) | number }}%</span>
                <div class="wrapper">
                    <div class="progress-bar">
                        <span class="progress-bar-fill" [ngStyle]="{'width': (this.juegos[(tematica.index-1)/3].sesion/3*100)+'%' }"></span>
                    </div>
                </div>
            </div>
        </td>
        </tr>
    </tbody>
    </table>
</div>
</div>