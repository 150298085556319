<div *ngIf="isMinimizedForTemplate" class="tapadoDinamizador"
    [style.background-image]="'url(' +  getBackgroundImageTapadoForTemplate() + ')'"></div>
<div class="flotante" 
        [ngClass]="{'flotanteMinimized': isMinimizedForTemplate}"
        [ngStyle]="{'cursor':(isMinimizedForTemplate) ? 'pointer' : 'default'}"
        (click)="onClick()"
        [style.background-color]="getBackgroundColorForTemplate()"
        [style.visibility]="getVisibilityFlotanteForTemplate()"
        [style.opacity]="getOpacityFlotanteForTemplate()">
        <div *ngIf="!isMinimizedForTemplate" class="titleBar" [style.background-color]="getTitleBarBackgroundColorForTemplate()">
            <button class="btnClose" (click)="onCloseClick()"></button>
            <button class="btnMinimiza" (click)="onMinimizeClick()"></button>
        </div>
        <div class="middleContainer">
            <button [ngClass]="isSoundEnabledForTemplate() == false ? 'btnSoundOff' : 'btnSoundOn'"
                *ngIf="!isMinimizedForTemplate"
                (click)="onBtnSoundClick()"></button>
            <app-timer></app-timer>
            <app-reloj-animado></app-reloj-animado>
        </div>
        <div class="bottomContainer" *ngIf="!isMinimizedForTemplate">
            <button [ngClass]="isTimerRunning() ? 'btnPause' : 'btnPlay'"  (click)="onClickBtnPlay()"></button>
            <div class="containerTimer">
                <div class="containerSettings" *ngIf="canShowSettings" [style.opacity]="getSettingsOpacityForTemplate()">
                    <ng-container *ngFor="let btnSetTimeInfo of arrayButtonsSettingsForTemplate;">
                        <app-btn-set-time
                            [btnInfo]="btnSetTimeInfo"
                            (onClickEvent)="onClickBtnSetTime($event)">
                        </app-btn-set-time>
                    </ng-container>
                </div>
                <button class="btnAjustes" (click)="onClickBtnSettings()"></button>
                <p>{{getTimeSelectedForTemplate()}}</p>
            </div>
            <button class="btnReset" (click)="onClickBtnReset()"></button>
        </div>
</div>

