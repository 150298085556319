import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const kUrlCreateCard =  environment.apiUrl + "fichasProfesores/createFicha";
const kUrlGetAllCards = environment.apiUrl + "fichasProfesores/getAllFichas";
const kUrlDeleteCards = environment.apiUrl + "fichasProfesores/deleteFicha";

export interface ICard{
  unicId:string,
  name:string,
  urlRecurso:string,
  base64Image:string
}

@Injectable({
  providedIn: 'root'
})

export class GeneradorTarjetasService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http:HttpClient) { }

  createCard(_token:string, imageFile:File, title:string, id:string):Observable<any>{

    const tokenParameter = new HttpParams().set('token', _token);
    const httpOptions = {
      headers: new HttpHeaders(), //{"Content-Type": "multipart/form-data"}
      params: tokenParameter
    };
    // const postInfo = {token:_token, image:imageFile, nombre:title, unicId:id};
    // console.log(postInfo);


    var myFormData = new FormData();
    myFormData.append('image', imageFile, imageFile.name);
    myFormData.append('token', _token);
    myFormData.append('nombre', title);
    myFormData.append('fichaId', id);
    console.log(myFormData);

    return this.http.post<any>(kUrlCreateCard, myFormData, httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );

  }

  updateCardWithoutImage(_token:string, title:string, id:string):Observable<any>{

    const tokenParameter = new HttpParams().set('token', _token);
    const httpOptions = {
      headers: new HttpHeaders(), //{"Content-Type": "multipart/form-data"}
      params: tokenParameter
    };
    // const postInfo = {token:_token, image:imageFile, nombre:title, unicId:id};
    // console.log(postInfo);


    var myFormData = new FormData();
    // myFormData.append('image', imageFile, imageFile.name);
    myFormData.append('token', _token);
    myFormData.append('nombre', title);
    myFormData.append('fichaId', id);

    console.log(myFormData);

    return this.http.post<any>(kUrlCreateCard, myFormData, httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );

  }

  createCardAsForm(data:any):Observable<any>{
    //console.log("Vamos a enviar la info del formulario: " + data.image);

    var formData: any = new FormData();
    formData.append("token", data.token);
    formData.append("nombre", data.title);
    formData.append("fichaId", data.id);
    formData.append("image", data.image);

    // console.log("token: " + formData.get('token'));
    // console.log("title: " + formData.get('nombre'));
    // console.log("id: " + formData.get('fichaId'));
    // console.log("image: " + formData.get('image'));

    const tokenParameter = new HttpParams().set('token', data.token);

    const httpOptions = {
      headers: new HttpHeaders({"Content-Type": "multipart/form-data"}),
      params: tokenParameter
    };

    return this.http.post<any>(kUrlCreateCard, formData, httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );

  }

  getAllCards(token:string):Observable<ICard[]>{

    const tokenParameter = new HttpParams().set('token', token);
    //console.log("Token: " + token);

    return this.http.get<ICard[]>(kUrlGetAllCards, {params:tokenParameter})
      .pipe(
        // tap(_ => console.log('Ficha entrenamiento recibida')),
        catchError(this.handleError)
      );
  }

  deleteCard(id:string, _token:string):Observable<any>{

    const headers = { 'token': _token};
    return this.http.delete<any>(kUrlDeleteCards + "/" + id, {headers}).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  deleteCards(_ids:string[], _token:string):Observable<any>{

    const postInfo = {ids:_ids, token:_token, numfichas:_ids.length, identificadores:_ids};

    //console.log("Post info: " + postInfo.numfichas);
    return this.http.post<any>(kUrlDeleteCards, postInfo, this.httpOptions).pipe(
      retry(3),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse){

    if(error.error instanceof ErrorEvent){
      console.error("Ha ocurrido un error: ", error.error.message);
    }
    else{ //Codigo de error desde el backend
      console.error("Error desde el backend!!!");
      console.error("Codigo: ", error.status);
      console.error("Descripcion: ", error.error);
    }

    return throwError("Ha ocurrido un error en el acceso al servidor.");
  }
}
