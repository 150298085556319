import { Component, OnInit } from '@angular/core';
import { Sound } from '../../../../classes/sound';
import { SoundDefinition } from '../../../../classes/sound-definition';
import { AppStateService } from '../../../../services/app-state.service';
import { SoundPlayerService } from '../../../../services/sound-player.service';
import { TTimerStatus } from '../../classes/t-timer-status';

@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.css']
})
export class TimerComponent implements OnInit {

  private isMinimized = false;
  private interval:any;
  private duration:number = 10;
  private status:TTimerStatus = TTimerStatus.STOPPED;
  private soundEnabled:boolean = true;

  public timerForTemplate:number = this.duration;

  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  ngOnDestroy():void{
    clearInterval(this.interval);
    this.soundPlayer.stopSoundFromDefinition(SoundDefinition.CUENTA_ATRAS);
  }

  public start(){

    this.timerForTemplate = this.duration;
    this.setStatus(TTimerStatus.RUNNING);

    this.startInterval();
  }

  public pause(){

    clearInterval(this.interval);
    this.setStatus(TTimerStatus.PAUSED);
    this.soundPlayer.stopSoundFromDefinition(SoundDefinition.CUENTA_ATRAS);
  }

  public resume(){

    this.setStatus(TTimerStatus.RUNNING);
    this.startInterval();
    
  }

  public stop(){

    clearInterval(this.interval);
    this.setStatus(TTimerStatus.STOPPED);
    this.timerForTemplate = this.duration;
    this.soundPlayer.stopSoundFromDefinition(SoundDefinition.CUENTA_ATRAS);
  }

  public getStatus():TTimerStatus{
    return this.status;
  }

  public setDuration(_duration:number){
    this.stop();
    this.duration = _duration;
    this.timerForTemplate = this.duration;
  }

  public setAsMinimized(){
    this.isMinimized = true;
  }

  public setAsMaximized(){
    this.isMinimized = false;
  }

  public toggleSound(on:boolean){
    this.soundEnabled = on;
  }

  private startInterval(){

    this.interval = setInterval(() => {
      this.timerForTemplate--;
      
      //sonido cuenta atras
      if(this.soundEnabled){
        if(this.timerForTemplate <= 10 && !this.soundPlayer.isAudioPlaying(SoundDefinition.CUENTA_ATRAS)){
          this.soundPlayer.playSoundFromDefinition(SoundDefinition.CUENTA_ATRAS, true);
        }

        if(this.timerForTemplate <= 0){
          this.soundPlayer.playSoundFromDefinition(SoundDefinition.TIMER_OVER);
        }
      }
      else{ //sonido desactivado
        if(this.soundPlayer.isAudioPlaying(SoundDefinition.CUENTA_ATRAS)){
          this.soundPlayer.stopSoundFromDefinition(SoundDefinition.CUENTA_ATRAS);
        }
      }

      //comprobamos si hemos llegado a 0
      if(this.timerForTemplate == 0){
        clearInterval(this.interval);
        this.setStatus(TTimerStatus.STOPPED);
        this.soundPlayer.stopSoundFromDefinition(SoundDefinition.CUENTA_ATRAS);
      }
    }, 1000);
  }

  private setStatus(_status:TTimerStatus){
    this.status = _status;
  }

  getTimerColorForTemplate():string{
    if(this.isMinimized){
      return "rgb(255, 255, 255)";
    }
    else{
      return this.appState.getTransversalColor().rgbString;
    }
  }

}
