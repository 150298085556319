import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { CardInfo } from '../../models/card-info';


@Component({
  selector: 'app-btn-card',
  templateUrl: './btn-card.component.html',
  styleUrls: ['./btn-card.component.css']
})
export class BtnCardComponent implements OnInit {

  @Input() cardInfo:CardInfo = new CardInfo("", "", "","");
  @Output() eventOnCardClicked = new EventEmitter<BtnCardComponent>();
  
  selected:boolean = false;
  scale:number = 0;

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  show(){
    this.scale = 1;
  }

  setAsSelected(){
    this.selected = !this.selected;
  }

  //#region  toques
  onClick(){

    this.soundPlayer.playSoundClick();

    this.eventOnCardClicked.emit(this);
  }
  //#endregion toques

}
