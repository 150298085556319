import { TGamesId } from "../../shared-games/classes/t-games-id";
import { GameInfo } from "./game-info";

export class BtnJuegoInfo {
    
    gameInfo:GameInfo = new GameInfo("", "", "", "", 0);
    url:string = "";
    type:TGamesId = TGamesId.LABERINTOS;
    title:string = "";
    urlImg:string = "";
    stars:number = 0;
    urlImgRing:string = "";
    urlImgRingAnimated:string = "";
    newLingote:boolean = false; //indica que acabamos de conseguir un lingote para este juego
    enabled:boolean = true;

    constructor(gameInfo:GameInfo, urlImgRing:string, urlImgRingAnimated:string){
        
        this.gameInfo = gameInfo;
        this.urlImgRing = urlImgRing;
        this.urlImgRingAnimated = urlImgRingAnimated;
    }

    // constructor(gameInfo:GameInfo, url:string, type:TGamesId, title:string, urlImg:string, stars:number, urlImgAnillo:string, urlImgAnilloAnimado:string, newLingote: boolean=false, enabled:boolean=true){

    //     this.gameInfo = GameInfo;
    //     this.url = url;
    //     this.type = type;
    //     this.title = title;
    //     this.urlImg = urlImg;
    //     this.stars = stars;
    //     this.urlImgAnillo = urlImgAnillo;
    //     this.urlImgAnilloAnimado = urlImgAnilloAnimado;
    //     this.newLingote = newLingote;
    //     this.enabled = enabled;
    // }
}
