<div *ngIf="isMinimizedForTemplate" class="tapadoDinamizador"
    [style.background-image]="'url(' +  getBackgroundImageTapadoForTemplate() + ')'"></div>
<div class="flotante" 
        [ngClass]="{'flotanteMinimized': isMinimizedForTemplate}"
        [ngStyle]="{'cursor':(isMinimizedForTemplate) ? 'pointer' : 'default'}"
        (click)="onClick()"
        [style.visibility]="getVisibilityFlotanteForTemplate()"
        [style.opacity]="getOpacityFlotanteForTemplate()">
        <div *ngIf="!isMinimizedForTemplate" class="titleBar" [style.background-color]="getTitleBarBackgroundColorForTemplate()">
            <button class="btnClose" (click)="onCloseClick()"></button>
            <button class="btnMinimiza" (click)="onMinimizeClick()"></button>
        </div>
        <div class="semaforoContainer" 
            [style.background-color]="getBackgroundColorForTemplate()">
            <div class="lucesContainer">
                <div class="toqueLuz toqueLuzRoja" (click)="onClickLight(tLights.RED)"></div>
                <div class="toqueLuz toqueLuzAmarilla" (click)="onClickLight(tLights.YELLOw)"></div>
                <div class="toqueLuz toqueLuzVerde" (click)="onClickLight(tLights.GREEN)"></div>
                <div class="luz luzRoja" [ngClass]="{'luzRojaOn': lightRedOnForTemplate}"></div>
                <div class="luz luzAmarilla" [ngClass]="{'luzAmarillaOn': lightYellowOnForTemplate}"></div>
                <div class="luz luzVerde" [ngClass]="{'luzVerdeOn': lightGreenOnForTemplate}"></div>
            </div>
        </div>
</div>
