<div class="wrapper">
    <form [formGroup]="formCheckTrimestreLicences" (ngSubmit)="onSubmit(formCheckTrimestreLicences.value)">

        <span class="advice-links"><strong>Aviso:</strong> Esta plataforma está dirigida a alumnos de infantil por lo que sólo el  padre/madre o tutor/a legal del alumno está  legitimado a activar la cuenta de usuario.</span>

        <input type="text" id="licenciaProfesor" formControlName="licenciaProfesor" placeholder="Introduce la licencia del/de la profesor/a">

        <select formControlName="comboAge"
            class="form-control" 
            (change)="changeRole($event)">
            <option *ngFor="let role of ageDropDownOptions; let i = index" [disabled]="i==0" [value]="role.id">
                {{role.name}}
        </select>

        <div class="trimestreLicenceContainer">
            <label for="licenciaTrimestre1" i18n="@@login.primer_trimestre">Primer trimestre</label>
            <input type="text" id="licenciaTrimestre1"  class="inputLicencia" [readonly]="isFormDisabled()" formControlName="licenciaTrimestre1" placeholder="Introduce la licencia">
        </div>
        <div class="trimestreLicenceContainer">
            <label for="licenciaTrimestre2" i18n="@@login.segundo_trimestre">Segundo trimestre</label>
            <input type="text" id="licenciaTrimestre2"  class="inputLicencia" [readonly]="isFormDisabled()" formControlName="licenciaTrimestre2" placeholder="Introduce la licencia">
        </div>
        <div class="trimestreLicenceContainer">
            <label for="licenciaTrimestre3" i18n="@@login.tercer_trimestre">Tercer trimestre</label>
            <input type="text" id="licenciaTrimestre3"  class="inputLicencia" [readonly]="isFormDisabled()" formControlName="licenciaTrimestre3" placeholder="Introduce la licencia">
        </div>
        <!-- <input type="text" id="licenciaTrimestre1" formControlName="licenciaTrimestre1" placeholder="Introduce la licencia">
        <input type="text" id="licenciaTrimestre2" formControlName="licenciaTrimestre2" placeholder="Introduce la licencia">
        <input type="text" id="licenciaTrimestre3" formControlName="licenciaTrimestre3" placeholder="Introduce la licencia"> -->
        
        <button class="btnComprobar" type="submit" [disabled]="formCheckTrimestreLicences.invalid" i18n="@@varios.comprobar">Comprobar</button>
    </form>
    <!-- <button class="btnContinuar" (click)="onContinue()" i18n="@@varios.continuar" [disabled]="isContinuarButtonEnabled()">Continuar</button> -->
    <p class="separator">-</p>
    <button class="btnCancelar" (click)="onCancel()" i18n="@@varios.cancelar">Cancelar</button>
    <div class="containerMessages">
        <app-login-error-message></app-login-error-message>
    </div>
</div>
<app-spinner></app-spinner>
