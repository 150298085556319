import { TButtonStatus } from "../../shared/classes/t-button-status";
import { TCuadernosMenuItem } from "../classes/t-cuadernos-menu-item";
import { BtnFichaMenuDesplegableInfo } from "./btn-ficha-menu-desplegable-info";

export class BtnFichaMenuBarInfo {

    type:TCuadernosMenuItem = TCuadernosMenuItem.ENTRENAMIENTO;
    status:TButtonStatus = TButtonStatus.ENABLED;
    recursos: BtnFichaMenuDesplegableInfo[] = [];
    urlAsociada:string = "";

    constructor(type:TCuadernosMenuItem, status:TButtonStatus, url="", recursos:BtnFichaMenuDesplegableInfo[] = [])
    {
        this.type = type;
        this.status = status;
        this.urlAsociada = url;
        this.recursos = recursos;
    }
}
