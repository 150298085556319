import { Injectable } from '@angular/core';
import '@angular/localize/init';

const kQuestion1 = $localize`:@@registro.pregunta_seguridad_1:¿En qué localidad vives?`;
const kQuestion2 = $localize`:@@registro.pregunta_seguridad_2:¿Cuál es tu color favorito?`;
const kQuestion3 = $localize`:@@registro.pregunta_seguridad_3:¿Cómo se llama tu mejor amigo o amiga?`;
const kQuestion4 = $localize`:@@registro.pregunta_seguridad_4:¿Cuál es tu personaje favorito de dibujos?`;
const kQuestion5 = $localize`:@@registro.pregunta_seguridad_5:¿Cuál es tu animal preferido?`;


//Interface para las preguntas se seguridad
export interface ISecurityQuestion{
  id:number, 
  question:string
}

@Injectable({
  providedIn: 'root'
})
export class SecurityQuestionService {

  private arraySecurityQuestions:ISecurityQuestion[] = [];

  constructor() { 
    this.arraySecurityQuestions.push({id:0, "question":kQuestion1});
    this.arraySecurityQuestions.push({id:1, "question":kQuestion2});
    this.arraySecurityQuestions.push({id:2, "question":kQuestion3});
    this.arraySecurityQuestions.push({id:3, "question":kQuestion4});
    this.arraySecurityQuestions.push({id:4, "question":kQuestion5});
  }

  getSecurityQuestions():ISecurityQuestion[]{

    return this.arraySecurityQuestions
  }

  getSecurityQuestionForId(id:number):string{

    for (let i = 0; i < this.arraySecurityQuestions.length; i++) {
      if(this.arraySecurityQuestions[i].id == id){
        return this.arraySecurityQuestions[i].question;
      }
    }

    console.error("[SecurityQuestionService] ERROR: No hemos encontrado la pregunta de seguridad con id: " + id);
    return "";
  }
}
