<div class="pageWrapper" *ngIf="canShow"
    [style.background-color]="getBackgroundColorForTemplate()"
    [style.opacity]="getOpacityForTemplate()">
    <div class="topBar" [style.background-color]="getTopBarBackgroundColorForTemplate()">
        <button class="btnClose" (click)="onCloseClick()"></button>
    </div>
    <div class="titleBar" 
        [style.background-color]="getTitleBarColorForTemplate()"
        [style.color]="getColorTitleForTemplate()">
        <p i18n="@@generador_tarjetas.title">GENERADOR DE TARJETAS</p>
    </div>
    <div class="mainContainer">
        <div class="leftContainer">
            <app-tool-bar 
                [status]="status"
                (eventOnOkClicked)="onOkClick()"
                (eventOnCancelClicked)="onCancelClick()">
            </app-tool-bar>
            <div class="tarjetasFrame">
                <app-card-thumbs
                    [status]="status"
                    (eventOnCardClicked)="onCardClicked($event)"
                    (eventOnNewCardClicked)="onNewCardClicked()">
                </app-card-thumbs>
            </div>
        </div>
        <div class="rightContainer">
            <div class="rightButtonsBar">
                <div class="bntWithTextContainer">
                    <button class="btnDelete" (click)="onDeleteClick()"></button>
                    <p i18n="@@generador_tarjetas.borrar">Borrar</p>
                </div>
                <div class="bntWithTextContainer">
                    <button class="btnPrint" (click)="onPrintClick()"></button>
                    <p i18n="@@generador_tarjetas.imprimir">Imprimir</p>
                </div>
                
            </div>
        </div>
    </div>
</div>
<app-editor-tarjetas (eventOnCardUpdated)="onCardUpdated()"></app-editor-tarjetas>
<app-spinner></app-spinner>
