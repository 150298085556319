import { Component, EventEmitter, Input, OnInit, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { TButtonStatus } from '../../../shared/classes/t-button-status';
import { TCuadernoFicha } from '../../../../modules/shared/classes/t-cuaderno-ficha';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TCuadernosMenuItem } from '../../classes/t-cuadernos-menu-item';
import { FichaEntrenamientoInfo } from '../../models/ficha-entrenamiento-info';
import { FichaInfo } from '../../models/ficha-info';
import { FichaTematicaInfo } from '../../models/ficha-tematica-info';
import { BtnFichaMenuBarInfo } from '../../models/btn-ficha-menu-bar-info';
import { BtnFichaMenuDesplegableInfo } from '../../models/btn-ficha-menu-desplegable-info';
import { BtnFichaMenuBarComponent } from '../../components/btn-ficha-menu-bar/btn-ficha-menu-bar.component';
import { TCuadernosMenuRecursoItem } from '../../classes/t-cuadernos-menu-recurso-item';
import { LoginService } from '../../../shared/services/login.service';


@Component({
  selector: 'app-fichas-menu-bar',
  templateUrl: './fichas-menu-bar.component.html',
  styleUrls: ['./fichas-menu-bar.component.css']
})
export class FichasMenuBarComponent implements OnInit {

  @Input() fichaType: TCuadernoFicha = TCuadernoFicha.ADAPTACION;
  @Output() clickMenuItem = new EventEmitter<BtnFichaMenuBarInfo>();
  @Output() clickDesplegableItem = new EventEmitter<BtnFichaMenuDesplegableInfo>();
  @ViewChildren(BtnFichaMenuBarComponent) menuButtonsControllers!: QueryList<BtnFichaMenuBarComponent>;

  arrayMenuButtons:BtnFichaMenuBarInfo[] = [];

  opacityRecursosDesplegableForTemplate = 0;
  visibilityRecursosDesplegableForTemplate = "hidden";
  disabledInteractiva = false;
  disabledEntrenamiento = false;
  disabledRecursos = false;
  disabledCurricular = false;
  disabledTrazos = false;
  opacityInteractiva = 1;

  private showInteractiva = false;
  private showEntrenamiento = false;
  private showRecursos = false;
  private showCurricular = false;
  private showTrazos = false;
  
  constructor(private loginService:LoginService,
    private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  
  }

  ngAfterViewInit(): void {

    //generamos botonera para este tipo de ficha
    // setTimeout(() => {
    //   this.generateMenuForCuaderno(this.fichaType);
    // }, 10);
    
  }

  //Escuchamos cambios porque si cambia el tipo de ficha hemos de recargar la botonera
  ngOnChanges(changes: SimpleChanges) {
    
    if(changes.fichaType.previousValue != changes.fichaType.currentValue){
      this.arrayMenuButtons = [];
      this.generateMenuForCuaderno(changes.fichaType.currentValue);  
    }
  }

  private generateMenuForCuaderno(cuaderno:TCuadernoFicha){

    switch(+cuaderno){
      case TCuadernoFicha.ADAPTACION:
        this.generateMenuForCuadernoAdaptacion();
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        this.generateMenuForCuadernoEntrenamiento();
        break;
      case TCuadernoFicha.TEMATICO:
        this.generateMenuForCuadernoTematico();
        break;
      default:
        break;
    }
  }

  private generateMenuForCuadernoTematico(){

    let btnInteractiva = new BtnFichaMenuBarInfo(TCuadernosMenuItem.INTERACTIVA, TButtonStatus.DISABLED);
    let btnEntramiento = new BtnFichaMenuBarInfo(TCuadernosMenuItem.ENTRENAMIENTO, TButtonStatus.DISABLED);
    let btnRecursos = new BtnFichaMenuBarInfo(TCuadernosMenuItem.RECURSOS, TButtonStatus.DISABLED);
    let btnCurricular = new BtnFichaMenuBarInfo(TCuadernosMenuItem.CURRICULAR, TButtonStatus.DISABLED);
    
    this.arrayMenuButtons.push(btnInteractiva, btnEntramiento, btnRecursos, btnCurricular);
  }

  private generateMenuForCuadernoAdaptacion(){

    let btnInteractiva = new BtnFichaMenuBarInfo(TCuadernosMenuItem.INTERACTIVA, TButtonStatus.DISABLED);

    this.arrayMenuButtons.push(btnInteractiva);
  }

  private generateMenuForCuadernoEntrenamiento(){

    let btnTrazo = new BtnFichaMenuBarInfo(TCuadernosMenuItem.TRAZOS, TButtonStatus.ENABLED);

    this.arrayMenuButtons.push(btnTrazo);
  }

  public setMenuForFicha(info:FichaInfo)
  {
  
    switch(+this.fichaType){
      case TCuadernoFicha.ADAPTACION:
        let adaptacionFicha = info as FichaInfo;
        this.setMenuForCuadernosAdaptacion(adaptacionFicha);
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        let entrenamientoFicha = info as FichaEntrenamientoInfo;
        this.setMenuForCuadernosEntrenamiento(entrenamientoFicha);
        break;
      case TCuadernoFicha.TEMATICO:
        let tematicoInfo  = info as FichaTematicaInfo;
        this.setMenuForCuadernosTematicos(tematicoInfo);
        break;
      default:
        console.error("ERROR: No se ha recibido el tipo de ficha correcto");
        break;
    }
  }

  private setMenuForCuadernosTematicos(info:FichaTematicaInfo){
    
    let status = TButtonStatus.ENABLED;
    let urlAsociada = "";
    
    //ficha interactiva
    status = (info.urlRecurso != "") ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    urlAsociada = info.urlRecurso;
    this.udpateMenuButton(TCuadernosMenuItem.INTERACTIVA, status, urlAsociada);
    
    //fichas entrenamiento
    status = (info.entrenamientos.length > 0) ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    let arrayRecursosDesplegablesEntrenamiento:BtnFichaMenuDesplegableInfo[] = [];
    
    //PRUEBAS FICHAS ENTRENAMIENTO
    // console.log("Entrenamiento: " + info.entrenamientos.length);
    // info.entrenamientos = [];
    // let entranamiento = new RecursoFichaEntrenamiento();
    // entranamiento.unicId="E3_1_03";
    // entranamiento.title="titulo entrenamiento";
    // info.entrenamientos.push(entranamiento);
    //FIN PRUEBAS
    
    info.entrenamientos.forEach(recurso => {
      let recursoDesplegableEntrenamiento = new BtnFichaMenuDesplegableInfo();
      recursoDesplegableEntrenamiento.id = recurso.unicId;
      recursoDesplegableEntrenamiento.name = recurso.title;
      recursoDesplegableEntrenamiento.type = TCuadernosMenuRecursoItem.ENTRENAMIENTO;
      arrayRecursosDesplegablesEntrenamiento.push(recursoDesplegableEntrenamiento);
    });
    this.udpateMenuButton(TCuadernosMenuItem.ENTRENAMIENTO, status, "", [], arrayRecursosDesplegablesEntrenamiento);

    //recursos multimedia
    //PRUEBAS
    // let recursoMultimedia = new RecursoFichaMultimedia();
    // recursoMultimedia.tipo = TCuadernosMenuRecursoItem.AUDIO;
    // recursoMultimedia.titulo = "Multimedia";
    // recursoMultimedia.urlRecurso = "urlMultimedia"
    // info.recursos = [];
    // info.recursos.push(recursoMultimedia);

    //FIN PRUEBAS
    status = (info.recursos.length > 0) ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    let arrayRecursosDesplegablesMultimedia:BtnFichaMenuDesplegableInfo[] = [];
    info.recursos.forEach(recurso => {
      let recursoDesplegableMultimedia = new BtnFichaMenuDesplegableInfo();
      recursoDesplegableMultimedia.name = recurso.titulo;
      recursoDesplegableMultimedia.url = recurso.urlRecurso;
      recursoDesplegableMultimedia.type = recurso.tipoRecurso as TCuadernosMenuRecursoItem;
      arrayRecursosDesplegablesMultimedia.push(recursoDesplegableMultimedia);
    });
    this.udpateMenuButton(TCuadernosMenuItem.RECURSOS, status, "", arrayRecursosDesplegablesMultimedia);

    //adaptacion curricular
    status = (info.urlCurricular.length > 0) ? TButtonStatus.ENABLED : TButtonStatus.DISABLED; 
    urlAsociada = info.urlCurricular;
    this.udpateMenuButton(TCuadernosMenuItem.CURRICULAR, status, urlAsociada);
  }

  private setMenuForCuadernosEntrenamiento(info:FichaEntrenamientoInfo){
    
    //trazos
    let status = (info.trazos.length > 0) ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    
    let arrayRecursosDesplegablesTrazos:BtnFichaMenuDesplegableInfo[] = [];
    info.trazos.forEach(recurso => {
      let recursoDesplegableTrazo = new BtnFichaMenuDesplegableInfo();
      recursoDesplegableTrazo.id = recurso.index;
      recursoDesplegableTrazo.name = recurso.title;
      recursoDesplegableTrazo.url = recurso.urlRecurso;
      recursoDesplegableTrazo.type = TCuadernosMenuRecursoItem.TRAZO;
      arrayRecursosDesplegablesTrazos.push(recursoDesplegableTrazo);
    });
  
    this.udpateMenuButton(TCuadernosMenuItem.TRAZOS, status, "", [], [], arrayRecursosDesplegablesTrazos);
  }

  private setMenuForCuadernosAdaptacion(info:FichaInfo){

    //ficha interactiva
    let status = (info.urlRecurso != "") ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    let urlAsociada = info.urlRecurso;
    this.udpateMenuButton(TCuadernosMenuItem.INTERACTIVA, status, urlAsociada);

  }

  private udpateMenuButton(btnType:TCuadernosMenuItem, 
    status:TButtonStatus, 
    url:string = "", 
    recursos:BtnFichaMenuDesplegableInfo[] = [], 
    entrenamiento:BtnFichaMenuDesplegableInfo[] = [], 
    trazos:BtnFichaMenuDesplegableInfo[] = []){
    
    this.menuButtonsControllers.forEach(menuButton => {
      if(menuButton.info.type == btnType){
        menuButton.setStatus(status);
        if(url != "")
        {
          menuButton.setUrlAsociada(url);
        }
        
        if(recursos.length > 0)
        {
          menuButton.setRecursos(recursos);
        }

        if(entrenamiento.length > 0)
        {
          menuButton.setEntrenamiento(entrenamiento);
        }

        if(trazos.length > 0)
        {
          menuButton.setTrazos(trazos);
        }

        //ocultamos desplegables
        menuButton.hideDesplegables();
      }
    });
  }

  public hideAllDesplegables(){
    this.menuButtonsControllers.forEach(menuButton => {
          menuButton.hideDesplegables();
    });
  }

  //#region toques
  onClickMenuItem(buttonInfo:BtnFichaMenuBarInfo)
  {
    //console.log("Me he enterado de que nos han pulsado un boton de menu: " + buttonInfo.type);

    //vemos si hay que ocultar desplegables
    this.menuButtonsControllers.forEach(menuButton => {
      
      if(buttonInfo.type != TCuadernosMenuItem.RECURSOS)
      {
        if(menuButton.info.type == TCuadernosMenuItem.RECURSOS){
          menuButton.hideDesplegables();
        }
      }
      if(buttonInfo.type != TCuadernosMenuItem.ENTRENAMIENTO)
      {
        if(menuButton.info.type == TCuadernosMenuItem.ENTRENAMIENTO){
          menuButton.hideDesplegables();
        }
      }
    });

    //emitimos evento
    this.clickMenuItem.emit(buttonInfo);
  }

  onClickMenuItemDesplegable(btnInfo:BtnFichaMenuDesplegableInfo){

    //console.log("Hola soy la barra de menu y me he enterado de que hemos pulsado en un boton de desplegable: " + btnInfo.url);
    //emitimos evento
    this.clickDesplegableItem.emit(btnInfo);
  }

  showDesplegableRecursos()
  {
    this.opacityRecursosDesplegableForTemplate = 1;
    this.visibilityRecursosDesplegableForTemplate = "visible";
  }

  hideDesplegableRecursos()
  {
    this.opacityRecursosDesplegableForTemplate = 0;
    this.visibilityRecursosDesplegableForTemplate = "hidden";
  }


  isShortMenu(){
    if((this.fichaType == TCuadernoFicha.TEMATICO || this.fichaType == TCuadernoFicha.ENTRENAMIENTO)  && !this.loginService.isTeacher()){
      return true;
    }else{
      return false;
    }
  }
  //#endregion

  //#region acceso desde template

  // canShowInteractivaOption():boolean{
  
  //   return this.showInteractiva;
  // }

  // canShowEntrenamientoOption():boolean{


  //   return this.showEntrenamiento;

  //   // switch(this.fichaType){
  //   //   case TCuadernoFicha.ADAPTACION:
  //   //     return false;
  //   //   case TCuadernoFicha.ENTRENAMIENTO:
  //   //     return false;
  //   //   case TCuadernoFicha.TEMATICO:
  //   //     return true;
  //   //   default:
  //   //     return false;
  //   // }
  // }

  // canShowRecursosOption():boolean{
    
  //   return this.showRecursos;
  //   // switch(this.fichaType){
  //   //   case TCuadernoFicha.ADAPTACION:
  //   //     return false;
  //   //   case TCuadernoFicha.ENTRENAMIENTO:
  //   //     return false;
  //   //   case TCuadernoFicha.TEMATICO:
  //   //     return true;
  //   //   default:
  //   //     return false;
  //   // }
  // }

  // canShowCurricularOption():boolean{
    
  //   return this.showCurricular;
  //   // switch(this.fichaType){
  //   //   case TCuadernoFicha.ADAPTACION:
  //   //     return false;
  //   //   case TCuadernoFicha.ENTRENAMIENTO:
  //   //     return false;
  //   //   case TCuadernoFicha.TEMATICO:
  //   //     return true;
  //   //   default:
  //   //     return false;
  //   // }
  // }

  // canShowTrazosOption():boolean{
  //   return this.showTrazos;
  // }

  // getRecursos():BtnFichaMenuDesplegableInfo[]{
    
  //   // if(this.fichaType == TCuadernoFicha.TEMATICO){
  //   //   let tematicoInfo  = this.info as FichaTematicaInfo;
  //   //   return tematicoInfo.recursos;
  //   // }

  //   return [];
  // }

  //#endregion

}
