import { Component, OnInit } from '@angular/core';
import { SoundDefinition } from '../../../../classes/sound-definition';
import { SoundPlayerService } from '../../../../services/sound-player.service';

@Component({
  selector: 'app-reloj-animado',
  templateUrl: './reloj-animado.component.html',
  styleUrls: ['./reloj-animado.component.css']
})
export class RelojAnimadoComponent implements OnInit {

  private duration:number = 10;
  private soundEnabled:boolean = true;
  private timeLeft:number = 10;
  private interval:any;

  public isPaused:boolean = false;
  public isAnimationEnabled:boolean = false;

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  ngOnDestroy():void{
    clearInterval(this.interval);
    this.stopSoundArena();
  }

  public start(){

    console.log("Start!!!");
    this.isAnimationEnabled = false;
    this.isPaused = false;

    setTimeout(() => {
      this.isAnimationEnabled = true;
      //this.playSoundArena();
      this.startInterval();
    }, 5);
    
  }

  public pause(){

    this.isPaused = true;
    this.stopSoundArena();
    clearInterval(this.interval);
  }

  public stop(){
    this.isAnimationEnabled = false;
    this.stopSoundArena();
    clearInterval(this.interval);
  }

  public resume(){

    this.isPaused = false;
    this.isAnimationEnabled = true;
    //this.playSoundArena();
    this.startInterval();
  }

  public setDuration(_duration:number){
    this.duration = _duration;
    this.timeLeft = this.duration;
    this.isAnimationEnabled = false;
    this.stopSoundArena();
  }

  public toogleSound(on:boolean){
    this.soundEnabled = on;
    
    if(this.soundEnabled){
      if(this.isAnimationEnabled && !this.isPaused){
        //this.playSoundArena();
      }
    }
    else{
      this.stopSoundArena();
    }
  }

  public getAnimationDurationForTemplate():number{
    return this.duration;
  }

  private startInterval(){
    
    this.interval = setInterval(() => {
      this.timeLeft--;
      if(this.timeLeft <= 0){
        this.stopSoundArena();
        clearInterval(this.interval); 
        this.timeLeft = this.duration;
      }
    }, 1000);
  }

  private playSoundArena(){

    if(this.soundEnabled){
      this.soundPlayer.playSoundFromDefinition(SoundDefinition.ARENA, true);
    }
  }

  private stopSoundArena(){
    this.soundPlayer.stopSoundFromDefinition(SoundDefinition.ARENA);
  }

}
