import { Component, OnInit } from '@angular/core';
import { PersonajeAnimation } from '../../classes/personaje-animation';
import { Utils } from '../../classes/utils';

const kIdleClass="lina";
const kAnimationParpadeoClass="linaParpadeo";
const kAnimationSaludaClass="linaSaluda";
const kAnimationRieClass = "linaRie";
const kAnimationSaltaClass= "linaSalta";
const kTimeSaluda = 1785;
const kTimeSalta = 2714;
const kTimeRie = 1428;
const kTimeParpadeo = 710;
const kPathToParpadeoImageName = "./assets/personajes/animacion_lina_saluda.png";
const kPathToSaludaImageName = "./assets/personajes/animacion_lina_parpadeo.png";

@Component({
  selector: 'app-lisa-animada',
  templateUrl: './lisa-animada.component.html',
  styleUrls: ['./lisa-animada.component.css']
})
export class LisaAnimadaComponent implements OnInit {

  private cssClassName:string = "lina";
  
  private animParpadeo:PersonajeAnimation = new PersonajeAnimation(kAnimationParpadeoClass, kTimeParpadeo);
  private animSaluda:PersonajeAnimation = new PersonajeAnimation(kAnimationSaludaClass, kTimeSaluda);
  private animRie:PersonajeAnimation = new PersonajeAnimation(kAnimationRieClass, kTimeRie);
  private animSalta:PersonajeAnimation = new PersonajeAnimation(kAnimationSaltaClass, kTimeSalta);
  private arrayAvailableAnimations:PersonajeAnimation[] = [];

  constructor() { }

  ngOnInit(): void {}

  public playRandomAnimationsByAge(age:number){

    this.arrayAvailableAnimations = [this.animParpadeo];
    
    switch(age){
      case 3:
        this.arrayAvailableAnimations.push(this.animSaluda);
        break;
      case 4:
        this.arrayAvailableAnimations.push(this.animRie);
        break;
      case 5:
        this.arrayAvailableAnimations.push(this.animSalta);
        break;
    }

    this.playRandomAnimation();
  }

  public playAllRandomAnimations(){
    
    this.arrayAvailableAnimations = [this.animParpadeo, this.animSalta, this.animSaluda, this.animRie];

    this.playRandomAnimation();
  }

  private playRandomAnimation(){

    setTimeout(() => {
      const randomIndexAnimation = Utils.getRandomInt(0, this.arrayAvailableAnimations.length-1);
      this.playAnimation(this.arrayAvailableAnimations[randomIndexAnimation]);
    }, Utils.getRandomInt(1000, 5000));
  }

  private playAnimation(animation:PersonajeAnimation){
    
    this.cssClassName = animation.cssStyleNameForAnimation;
    setTimeout(() => {
      this.linaReset();
      this.playRandomAnimation();
    }, animation.duration);
  }

  linaReset(){
    this.cssClassName = kIdleClass;
  }

  getClassForTemplate():string
  {
    return this.cssClassName;
  }
}
