import { Component, OnInit, ViewChild } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { NavigationManagerService } from '../../../services/navigation-manager.service';
import { SoundPlayerService } from '../../../services/sound-player.service';
import { AddLicencesFormComponent } from '../add-licences-form/add-licences-form.component';
import { FlotanteLicenceComponent } from '../flotante-licence/flotante-licence.component';

enum tSubMenuButton {ALUMNOS, LICENCIA, SALIR, ADD_LICENCES}

@Component({
  selector: 'app-btn-user',
  templateUrl: './btn-user.component.html',
  styleUrls: ['./btn-user.component.css']
})
export class BtnUserComponent implements OnInit {

  @ViewChild(FlotanteLicenceComponent,  {static: true}) flotanteLicenceController!: FlotanteLicenceComponent;
  @ViewChild(AddLicencesFormComponent,  {static: true}) flotanteAddLicencesController!: AddLicencesFormComponent;

  private submenuOpacity:number = 0;
  private submenuVisible:boolean = false;

  constructor(private loginService:LoginService, 
    private soundPlayer:SoundPlayerService,
    private navigationManager:NavigationManagerService) { }

  ngOnInit(): void {
  }

  private showSubMenu(){

    this.submenuVisible = true;
    setTimeout(() => {
      this.submenuOpacity = 1;
    }, 50);
  }

  public hideSubMenu(){

    this.submenuOpacity = 0;
    setTimeout(() => {
      this.submenuVisible = false;
    }, 200);
  }

  private showLicence(){

    this.flotanteLicenceController.showLicence();
  }

  private showAddLicences(){

    this.flotanteAddLicencesController.show();
  }

  private showAlumnos(){

    this.navigationManager.navigateTo("/profesor", false);
  }

  

  private exit(){
    
    this.loginService.logOut();
    this.navigationManager.navigateTo("login");
  }

 //#region toques 
  onClick(){

    this.soundPlayer.playSoundClick();

    if(this.submenuVisible){
      this.hideSubMenu();
    }
    else{
      this.showSubMenu();
    }
  }

  onClickSubMenuButton(button:tSubMenuButton){
    
    this.soundPlayer.playSoundClick();

    switch(button){
      case tSubMenuButton.ALUMNOS:
        this.showAlumnos();
        break;
      case tSubMenuButton.LICENCIA:
        this.showLicence();
        break;
      case tSubMenuButton.ADD_LICENCES:
        this.showAddLicences();
        break;
      case tSubMenuButton.SALIR:
        setTimeout(() => {
          this.exit();
        }, 100);
        break;
    }
  }

  onCloseLicenceSplash(){
    this.hideSubMenu();
  }

  onCloseAddLicenceSplash(){
    this.hideSubMenu();
  }
  //#endregion toques

  //#region template
  getSubMenuOpacity():number{
    return this.submenuOpacity;
  }

  canShowSubMenu():boolean{
    return this.submenuVisible;
  }

  getName():string{
    return this.loginService.getName();
  }

  isTeacher(){
    return this.loginService.isTeacher();
  }
  //#endregion template

}
