import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { AppStateService } from 'src/app/modules/shared/services/app-state.service';
import { Utils } from '../../../shared/classes/utils';
import { SoundDefinition } from '../../../shared/classes/sound-definition';

const kAnimation = "buttonAnimation";

@Component({
  selector: 'app-btn-diploma',
  templateUrl: './btn-diploma.component.html',
  styleUrls: ['./btn-diploma.component.css']
})
export class BtnDiplomaComponent implements OnInit {

  @Input() isDisabled:boolean = false;
  @Output() eventOnClick = new EventEmitter();

  private animationClassName = "none";

  constructor(private soundPlayer:SoundPlayerService,
    protected appState: AppStateService
    ) { }

  ngOnInit(): void {

  }

  public unblock(){
    
    this.isDisabled = false;

    this.soundPlayer.playSoundFromDefinition(SoundDefinition.APARICION);

    setTimeout(() => {
      this.animationClassName = kAnimation;
    }, 100);
    
  }

  public block(){
    this.isDisabled = true;
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    if(!this.isDisabled){

      //descargamos diploma
      Utils.downloadLocalFile(this.appState.getTrimestreInfo().pathToDiplomaDownload, "diploma_ " + this.appState.getNumberOfTrimestre() + ".pdf");
    }
    else{
      this.eventOnClick.emit();
    }
  }

  getLockVisibilityForTemplate():string{
    return(this.isDisabled) ? "visible" : "hidden";
  }

  getBackgroundImage(){
  
    return this.appState.getTrimestreInfo().pathToDiplomaThumb;
  }

  getClassForAnimation():string{
    return this.animationClassName;
  }

}
