<div class="pageWrapper" [style.background-color]="getBackgroundColorForTemplate()">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'menu'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="titleBar" [style.background-color]="getTitleBarBackgroundColorForTemplate()">
        <p [style.color]="getTitleBackgroundColorForTemplate()" i18n="@@materialComplementario.title">MATERIAL COMPLEMENTARIO</p>
    </div>
    <div class="middleContainer">
        <div class="menuContainer">
            <app-menu-pestanyas
                (onClickBtnPestanya)="eventOnClickBtnPestanya($event)">
            </app-menu-pestanyas>
        </div>
        <div class="contentContainer" [style.background-color]="getThumbsContainerBackgroundColorForTemplate()">
            <app-frame-for-bits></app-frame-for-bits>
            <app-frame-for-cuentos (onCuentoSelected)="onEventCuentoSelected($event)" ></app-frame-for-cuentos>
            <app-frame-for-descargables></app-frame-for-descargables>
            <app-frame-for-curriculares></app-frame-for-curriculares>
        </div>
    </div>
</div>

<!-- visor de cuentos -->
<app-flotante-ficha-interactiva
    [urlFicha]="''"
    (eventFicha)="onEventFlotanteCuento($event)">
</app-flotante-ficha-interactiva>



