import { Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { AppStateService } from '../../../shared/services/app-state.service';
import { TEventFromGame } from '../../classes/t-event-from-game';
import { GameInfo } from '../../models/game-info';

@Component({
  selector: 'app-visor-juego',
  templateUrl: './visor-juego.component.html',
  styleUrls: ['./visor-juego.component.css']
})
export class VisorJuegoComponent implements OnInit {

  @Output() eventFromGame = new EventEmitter<TEventFromGame>();
  @ViewChild('iframe') iframe!: ElementRef;
  @ViewChild(SpinnerComponent, { static: true }) spinnerController!: SpinnerComponent;
  safeUrl:SafeResourceUrl | null = null;

  private isShowingFlotanteForTemplate: boolean = false;
  private opacityFlotanteForTemplate: number = 0;
  private transition:string = "none";
  private gameInfo:GameInfo | null = null;
  private backgroundColor:string = "";
  private loadingSplashOpacity:number = 0;

  constructor(public sanitizer:DomSanitizer, private appState:AppStateService) { 
    
    if (window.addEventListener) {
      window.addEventListener("message", this.onEventFromGameReceived.bind(this), false);
    } else {
       (<any>window).attachEvent("onmessage", this.onEventFromGameReceived.bind(this));
    }
  }

  ngOnInit(): void {
    
    this.backgroundColor = this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  onEventFromGameReceived: any = (event: any) =>  {
    
    //console.log("Soy el visor de juegos y he recibido un mensaje: " + event.data);
    this.eventFromGame.emit(event.data);

    //Si se ha recibido evento de cerrar, cerramos la ficha
    if(event.data == TEventFromGame.CLOSE)
    {
      this.hide();
    }

    //Si se ha recibido evento de load, quitamos pantalla de carga
    if(event.data == TEventFromGame.LOADED)
    {
      this.hideLoadingSplash();
      this.spinnerController.hide();
    }

    setTimeout(() => {
      
    }, 300);
  }

  private showLoadingSplash(){
    
    this.loadingSplashOpacity = 1;
  }

  private hideLoadingSplash(){
    
    this.loadingSplashOpacity = 0;
  }

  //#region public methods
  public showGame(gameInfo:GameInfo){

    this.gameInfo = gameInfo;

    if(gameInfo.url.length == 0) return;

    this.isShowingFlotanteForTemplate = true;

    this.spinnerController.show();

    this.showLoadingSplash();

    setTimeout(() => {
      
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(gameInfo.url);
    }, 300);  
  }

  public onIframeLoaded() {
    
    if(this.iframe){
      const session = (this.gameInfo!.session >= 3) ? 3:this.gameInfo!.session+1;
      this.iframe.nativeElement.contentWindow.postMessage({"trimestre":this.appState.getTrimestre(), "session":session}, "*");
    }
  }

  hide()
  {

    this.showLoadingSplash();
    setTimeout(() => {
      this.isShowingFlotanteForTemplate = false;
      this.hideLoadingSplash();
      this.safeUrl = null;  //Esto oculta el iframe
    }, 200);
  }

  //#endregion

  //#region template
  getVisibilityFlotanteForTemplate():string{
    return (this.isShowingFlotanteForTemplate) ? "visible" : "hidden";
  }

  haveValidUrlToShow():boolean{
    
    return(this.safeUrl != null);
  }

  getBackgroundColor():string{
    return this.backgroundColor;
  }

  getOpacityLoadingSplash():number{
    return this.loadingSplashOpacity;
  }
  //#endregion

}
