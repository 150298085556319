import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TLanguage } from '../../shared/classes/t-language';
import { CuentoInfo } from '../models/cuento-info';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ComplementarioConfigService {

  private cuentosUrl = environment.apiUrl + "cuentosMundo/getCuentos/";
  private bitsUrl = "https://sgo-auv.santillana.es/SBKOBJD/ESARC00288780/index.html";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http:HttpClient) { }

  getBitsUrl():string{
    return this.bitsUrl;
  }


  getCuentos(age:number, language:TLanguage, token:string):Observable<CuentoInfo[]>
  {

    let url = this.cuentosUrl + language + "/" + age;
    const tokenParameter = new HttpParams().set('token', token);

    return this.http.get<CuentoInfo[]>(url, {params:tokenParameter})
      .pipe(
        // tap(_ => console.log('Datos miniaturas recibidos')),
        catchError(this.handleError<CuentoInfo[]>('getCuentos', []))
      );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.log("ERROR!!!");
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
