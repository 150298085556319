<div *ngIf="getOpacityForFlotante() > 0" class="pageWrapper" [ngStyle]="{'opacity':getOpacityForFlotante()}">
    <div class="box" 
        
        [style.opacity]="getOpacityForContainerSteps()">
        <div class="topContainer">
            <div class="logo"></div>
            <p class="subTitle" i18n="@@login.recuperacion_contrasenya">RECUPERACIÓN DE CONTRASEÑA</p>
            <p class="pageCounter">{{getCurrentPageForTemplate()}}/3</p>
        </div>

        <!-- [style.width]="(showStep2Form == true || showStep3Form ==  true) ? '85%' : '70%'"
        [style.margin-top]="(showStep1Form == true) ? '9%' : (showStep2Form == true) ? '0%' : '-2%'" -->
        <div class="middleContainer">
            <div class="containerSteps">
                <!-- Paso 1 -->
                <div class="step1" *ngIf="showStep1Form">
                    <form [formGroup]="formInputUserName" class="subForm" (ngSubmit)="onSubmit(formInputUserName.value)">
        
                        <input type="text" id="username" formControlName="username" placeholder="Usuario">
                            
                        <button class="btnAceptar" type="submit" [disabled]="formInputUserName.invalid" i18n="@@varios.aceptar">ACEPTAR</button>
                    </form> 
                </div>
    
                <!-- Paso 2 -->
                <div class="step2" *ngIf="showStep2Form">
                    <form [formGroup]="formInputAnswer" class="subForm" (ngSubmit)="onSubmit(formInputAnswer.value)">
                        
                        <label for="answer">{{getSecurityQuestion()}}</label>
                        <textarea class ="answer" formControlName="answer" placeholder="Respuesta a la pregunta de seguridad" i18n-placeholder></textarea>
                            
                        <button class="btnAceptar" type="submit" [disabled]="formInputAnswer.invalid" i18n="@@varios.aceptar">ACEPTAR</button>
                    </form> 
                </div>
                
                <!-- Paso 3 -->
                <div class="step3" *ngIf="showStep3Form">
                    <form [formGroup]="formInputNewPassword" class="subForm" (ngSubmit)="onSubmit(formInputNewPassword.value)">
                        <input type="password" id="password" formControlName="password" (input)="onInputPasswords()" placeholder="Introduce tu contraseña" i18n-placeholder>
    
                        <input type="password" id="passwordConfirmed" formControlName="passwordConfirmed" (input)="onInputPasswords()" placeholder="Repite tu contraseña" i18n-placeholder>
                        
                        <button class="btnAceptar" type="submit" [disabled]="formInputNewPassword.invalid" i18n="@@varios.aceptar">ACEPTAR</button>
                    </form> 
                </div>
            </div>
            <p class="separator">-</p>
            <button class="btnCancelar" (click)="onCancel()" i18n="@@varios.cancelar">Cancelar</button> 
        </div>
        
        <div class="containerMessages">
            <app-login-error-message></app-login-error-message>
        </div>
    </div>
</div>
<app-spinner></app-spinner>
