import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LoginService } from '../../../services/login.service';
import { SoundPlayerService } from '../../../services/sound-player.service';

@Component({
  selector: 'app-flotante-licence',
  templateUrl: './flotante-licence.component.html',
  styleUrls: ['./flotante-licence.component.css']
})
export class FlotanteLicenceComponent implements OnInit {

  @Output() eventOnCloseLicenceSplash = new EventEmitter();

  private flotanteLicenciaOpacity = 0;
  private flotanteLicenciaVisible = false;
  private licence:string = "";

  constructor(private loginService:LoginService, private soundPlayer:SoundPlayerService) { 
    this.licence = loginService.getUserId();
  }

  ngOnInit(): void {
  }

  public showLicence(){
    this.show();
  }

  private show(){

    this.flotanteLicenciaVisible = true;
    setTimeout(() => {
      this.flotanteLicenciaOpacity = 1;
    }, 50);
  }

  private hide(){

    this.flotanteLicenciaOpacity = 0;
    this.eventOnCloseLicenceSplash.emit();
    setTimeout(() => {
      this.flotanteLicenciaVisible = false;
    }, 200);
  }

  onClickClose(){

    this.soundPlayer.playSoundClick();

    setTimeout(() => {
      this.hide();
    }, 100);
  }

  canShowFlotanteLicencia():boolean{
    return this.flotanteLicenciaVisible;
  }

  getFlotanteLicenciaOpacity():number{
    return this.flotanteLicenciaOpacity;
  }

  getLicenceToShow(){
    return this.licence;
  }

}
