import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SoundDefinition } from '../../classes/sound-definition';
import { AppStateService } from '../../services/app-state.service';
import { SoundPlayerService } from '../../services/sound-player.service';

const kPathToAssets = "./assets/dinamizador/";
const kTapadoBackgroundImageByAge = ["btn_dinamizador_minimizado_3A.png", "btn_dinamizador_minimizado_4A.png", "btn_dinamizador_minimizado_5A.png"]

export enum TLights{
  RED, YELLOw, GREEN
}

@Component({
  selector: 'app-flotante-dinamizador-semaforo',
  templateUrl: './flotante-dinamizador-semaforo.component.html',
  styleUrls: ['./flotante-dinamizador-semaforo.component.css']
})
export class FlotanteDinamizadorSemaforoComponent implements OnInit {

  @Output() onEventClose = new EventEmitter();
  private isShowingFlotanteForTemplate: boolean = false;
  private opacityFlotanteForTemplate: number = 0;

  public lightRedOnForTemplate = false;
  public lightGreenOnForTemplate = false;
  public lightYellowOnForTemplate = false;
  public isMinimizedForTemplate: boolean = false;

  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  //#region public methods
  public showFlotante(){

     //apagamos luces
     this.lightGreenOnForTemplate = false;
     this.lightRedOnForTemplate = false;
     this.lightYellowOnForTemplate = false;

    //mostramos flotante
    this.show();
  }


  private show(){

    this.opacityFlotanteForTemplate = 0;
    this.isShowingFlotanteForTemplate = true;
    setTimeout(() => {
      this.opacityFlotanteForTemplate = 1;
    }, 50);
  }

  hideFlotante()
  {
    this.opacityFlotanteForTemplate = 0;
    setTimeout(() => {
      this.isShowingFlotanteForTemplate = false;
      this.soundPlayer.stopSoundFromDefinition(SoundDefinition.APLAUSOS);
    }, 300);
  }

  //#endregion

  //#region toques
  onCloseClick(){

    this.soundPlayer.playSoundClick();

    this.hideFlotante();

    setTimeout(() => {
      this.onEventClose.emit();
    }, 300);
  }

  onMinimizeClick(){

    this.soundPlayer.playSoundClick();

    //tiempo que tarda en estar minimizado
    setTimeout(() => {
      this.isMinimizedForTemplate = true;
    }, 50);
  }

  onClickLight(light:TLights){

    if(this.isMinimizedForTemplate) return;

   //this.soundPlayer.playSoundClick();
   this.soundPlayer.playSoundFromDefinition(SoundDefinition.APARICION);
    
    switch(light){
      case TLights.GREEN:
        this.lightGreenOnForTemplate = !this.lightGreenOnForTemplate;
        this.lightRedOnForTemplate = false;
        this.lightYellowOnForTemplate = false;
        break;
      case TLights.YELLOw:
        this.lightYellowOnForTemplate = !this.lightYellowOnForTemplate;
        this.lightRedOnForTemplate = false;
        this.lightGreenOnForTemplate = false;
        break;
      case TLights.RED:
        this.lightRedOnForTemplate = !this.lightRedOnForTemplate;
        this.lightGreenOnForTemplate = false;
        this.lightYellowOnForTemplate = false;
        break;
      
    }
  }

  onClick(){
    
    if(this.isMinimizedForTemplate){
      
      this.soundPlayer.playSoundClick();
      this.isMinimizedForTemplate = false;
    }
  }

  //#endregion toques

  //#region template
  getVisibilityFlotanteForTemplate():string{
    return (this.isShowingFlotanteForTemplate) ? "visible" : "hidden";
  }

  getOpacityFlotanteForTemplate():number{

    return this.opacityFlotanteForTemplate;
  }

  getTitleBarBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getBackgroundColorForTemplate():string{

    if(this.isMinimizedForTemplate){
      switch(this.appState.getAge()){
        case 3:
          return "#045a53";
        break;
        case 4:
          return "#5b2d65";
        break;
        case 5:
          return "#942b2b";
        break;
      }
      return "";
    }
    else{
      return this.appState.getTrimestreInfo().colorPrimaryLight.rgbString;
    }
    
  }

  getBackgroundImageTapadoForTemplate():string{

    return kPathToAssets + kTapadoBackgroundImageByAge[this.appState.getAge() % 3];
  }

  public get tLights(): typeof TLights {
    return TLights; 
  }
  //#endregion

}
