import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import '@angular/localize/init';
import { ComboAgeValidator } from '../../../../login/classes/combo-age-validator';
import { LicencesForTrimestreValidator } from '../../../../login/classes/licences-for-trimestre-validator';
import { TLoginError } from '../../../classes/t-login-error';
import { TTrimestre } from '../../../classes/t-trimestre';
import { AuthorizationService } from '../../../services/authorization.service';
import { IResponseFromLicencesForTrimestres, LoginService } from '../../../services/login.service';
import { SoundPlayerService } from '../../../services/sound-player.service';
import { LoginErrorMessageComponent } from '../../login-error-message/login-error-message.component';
import { SpinnerComponent } from '../../spinner/spinner.component';

const kComboAge3 = $localize`:@@registro.combo_edad_3:3 años`;
const kComboAge4 = $localize`:@@registro.combo_edad_4:4 años`;
const kComboAge5 = $localize`:@@registro.combo_edad_5:5 años`;
const kComboSelectAge = $localize`:@@registro.combo_selecciona_edad:¿3, 4 o 5 años?`;

@Component({
  selector: 'app-add-licences-form',
  templateUrl: './add-licences-form.component.html',
  styleUrls: ['./add-licences-form.component.css']
})
export class AddLicencesFormComponent implements OnInit {

  @ViewChild(SpinnerComponent,  {static: true}) spinnerController!: SpinnerComponent;
  @ViewChild(LoginErrorMessageComponent,  {static: false}) errorsController!: LoginErrorMessageComponent;
  @Output() eventOnCloseAddLicenceSplash = new EventEmitter();


  formAddLicences: UntypedFormGroup;
  ageDropDownOptions: any = [{id:0, name:kComboSelectAge}, {id:3, name:kComboAge3}, {id:4, name:kComboAge4}, {id:5, name:kComboAge5}];

  private flotanteOpacity = 0;
  private flotanteVisible = false
  private formValidated:boolean = false;
  private disabledForm:boolean = false;
  
  constructor(private formBuilder: UntypedFormBuilder, 
    private soundPlayer:SoundPlayerService,
    private loginService:LoginService, 
    private authService:AuthorizationService) { 

    this.formAddLicences = this.formBuilder.group({
      licenciaProfesor: ['', Validators.required],
      licenciaTrimestre1: ['', Validators.nullValidator],
      licenciaTrimestre2: ['', Validators.nullValidator],
      licenciaTrimestre3: ['', Validators.nullValidator],
      comboAge: ['', [Validators.required, ComboAgeValidator()]]
    }, {validator: LicencesForTrimestreValidator('licenciaTrimestre1', 'licenciaTrimestre2', 'licenciaTrimestre3')});
  }


  ngOnInit(): void {
  }


  public show(){

    this.flotanteVisible = true;
    setTimeout(() => {
      this.resetForm();
      this.flotanteOpacity = 1;
    }, 50);
  }

  private hide(){

    this.flotanteOpacity = 0;
    this.eventOnCloseAddLicenceSplash.emit();
    setTimeout(() => {
      this.flotanteVisible = false;
    }, 200);
  }

  private resetForm(){

    this.disabledForm = false;
    this.formAddLicences.get("licenciaProfesor")!.setValue("");
    this.formAddLicences.get("licenciaTrimestre1")!.setValue("");
    this.formAddLicences.get("licenciaTrimestre2")!.setValue("");
    this.formAddLicences.get("licenciaTrimestre3")!.setValue("");
    this.comboAge!.setValue(0);
  }

  private checkLicences(){
    
    this.spinnerController.show();

    const licenceTeacher = this.formAddLicences.get('licenciaProfesor')!.value;
    const age:number = this.formAddLicences!.get('comboAge')!.value;
    const licenceTrimestre1 = this.formAddLicences.get('licenciaTrimestre1')!.value;
    const licenceTrimestre2 = this.formAddLicences.get('licenciaTrimestre2')!.value;
    const licenceTrimestre3 = this.formAddLicences.get('licenciaTrimestre3')!.value;

    this.loginService.checkLicencesForRegister(licenceTeacher, age, licenceTrimestre1, licenceTrimestre2, licenceTrimestre3).subscribe(
      resp => {
        
        //comprobamos si hay errores
        if(this.checkLicencesResponseForErrors(resp)){
          this.formValidated = true;
          this.disabledForm = true; 
        }       
      },
      error => {
        console.log("Error desde el componente: " + error);
        this.spinnerController.hide();
      },
      () => {
        this.spinnerController.hide();
      }
    );
  }

  private checkLicencesResponseForErrors(resp:IResponseFromLicencesForTrimestres):boolean{

    if(!resp.profesor.idProfesor){
      console.log("licencia de profesor no valida");
      this.errorsController.showError(TLoginError.LICENCE_TEACHER_WRONG);
      return false;
    }

    if(!resp.licencias.license_trimestre_1.correct && resp.licencias.license_trimestre_1.licencia != null){
      console.log("licencia trimestre 1 no valida");
      this.errorsController.showError(TLoginError.LICENCE_TRIMESTRE_1_WRONG);
      return false;
    }

    if(!resp.licencias.license_trimestre_2.correct && resp.licencias.license_trimestre_2.licencia!= null){
      console.log("licencia trimestre 2 no valida"); 
      this.errorsController.showError(TLoginError.LICENCE_TRIMESTRE_2_WRONG);
      return false;
    }

    if(!resp.licencias.license_trimestre_3.correct && resp.licencias.license_trimestre_3.licencia != null){
      console.log("licencia trimestre 3 no valida");
      this.errorsController.showError(TLoginError.LICENCE_TRIMESTRE_3_WRONG);
      return false;
    }

    return true;
  }

  private addLicences(licenceTeacher:string, age:number, licenceTrimestre1:string, licenceTrimestre2:string, licenceTrimestre3:string){

    this.spinnerController.show();

    const token = this.authService.getToken();
    
    this.loginService.addLicences(licenceTeacher, age, licenceTrimestre1, licenceTrimestre2, licenceTrimestre3, token).subscribe(
      resp => {
        if(resp.success){
          this.saveLicences(age, licenceTrimestre1, licenceTrimestre2, licenceTrimestre3);
        }
        else{
          console.log("Algo ha fallado");
          this.errorsController.showError(TLoginError.UNKNOWN);
        }
      },
      error => {
        console.log("Error desde el componente: " + error);
        this.spinnerController.hide();
      },
      () => {
        console.log("Se ha completado el añadido de licencias");
        this.spinnerController.hide();
        this.hide();
      }
    );
  }

  private saveLicences(age:number, licenceTrimestre1:string, licenceTrimestre2:string, licenceTrimestre3:string){

    let trimestresToAdd:TTrimestre[] = [];

    switch(Number(age)){
      case 3:
        if(licenceTrimestre1.length > 0){
          trimestresToAdd.push(TTrimestre['3_1']);
        }
        if(licenceTrimestre2.length > 0){
          trimestresToAdd.push(TTrimestre['3_2']);
        }
        if(licenceTrimestre3.length > 0){
          trimestresToAdd.push(TTrimestre['3_3']);
        }
        break;
      case 4:
        if(licenceTrimestre1.length > 0){
          trimestresToAdd.push(TTrimestre['4_1']);
        }
        if(licenceTrimestre2.length > 0){
          trimestresToAdd.push(TTrimestre['4_2']);
        }
        if(licenceTrimestre3.length > 0){
          trimestresToAdd.push(TTrimestre['4_3']);
        }
        break;
      case 5:
        if(licenceTrimestre1.length > 0){
          trimestresToAdd.push(TTrimestre['5_1']);
        }
        if(licenceTrimestre2.length > 0){
          trimestresToAdd.push(TTrimestre['5_2']);
        }
        if(licenceTrimestre3.length > 0){
          trimestresToAdd.push(TTrimestre['5_3']);
        }
        break;
    }

    this.loginService.addTrimestresAvailables(trimestresToAdd);
  }

  changeRole(e:any) {

    this.comboAge!.setValue(e.target.value, {
      onlySelf: true
    });
  }


  onSubmit(values:any) {
    
    this.soundPlayer.playSoundClick();

    this.errorsController.resetErrors();

    this.checkLicences();
  }

  onCancel(){

    this.soundPlayer.playSoundClick();

    setTimeout(() => {
      
      this.eventOnCloseAddLicenceSplash.emit();
      this.hide();
    }, 50);
  }

  onContinue(){
    
    this.soundPlayer.playSoundClick();

    const licenceTeacher = this.formAddLicences.get('licenciaProfesor')!.value;
    const age = this.formAddLicences!.get('comboAge')!.value;
    const licenceTrimestre1 = this.formAddLicences.get('licenciaTrimestre1')!.value;
    const licenceTrimestre2 = this.formAddLicences.get('licenciaTrimestre2')!.value;
    const licenceTrimestre3 = this.formAddLicences.get('licenciaTrimestre3')!.value;

    this.addLicences(licenceTeacher, age, licenceTrimestre1, licenceTrimestre2, licenceTrimestre3);
    
  }

  get comboAge(){
    return this.formAddLicences.get('comboAge');
  }

  isContinuarButtonEnabled():boolean{
    return !this.formValidated;
  }

  canShowFlotante():boolean{
    return this.flotanteVisible;
  }

  getFlotanteOpacity():number{
    return this.flotanteOpacity;
  }

  isFormDisabled():boolean{
    return this.disabledForm;
  }

}
