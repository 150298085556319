import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { ConfirmPasswordValidator } from '../../classes/confirm-password-validator';
import { TLoginError } from '../../../shared/classes/t-login-error';
import { LoginService } from '../../../shared/services/login.service';
import { SecurityQuestionService } from '../../services/security-question.service';
import { LoginErrorMessageComponent } from '../../../shared/components/login-error-message/login-error-message.component';

@Component({
  selector: 'app-flotante-password-recovery',
  templateUrl: './flotante-password-recovery.component.html',
  styleUrls: ['./flotante-password-recovery.component.css']
})
export class FlotantePasswordRecoveryComponent implements OnInit {

  @ViewChild(SpinnerComponent,  {static: true}) spinnerController!: SpinnerComponent;
  @ViewChild(LoginErrorMessageComponent,  {static: false}) errorsController!: LoginErrorMessageComponent;
  
  showStep1Form:boolean = false;
  showStep2Form:boolean = false;
  showStep3Form:boolean = false;

  formInputUserName: UntypedFormGroup;
  formInputAnswer: UntypedFormGroup;
  formInputNewPassword: UntypedFormGroup;

  securityQuestionForTemplate:string = "";

  private opacityForTemplate = 0;
  private opacityForContainerSteps = 0;
  private username:string = "";
  private securityAnswer:string = "";
  private currentPage:number = 1;
  
  constructor(private formBuilder: UntypedFormBuilder, 
    private soundPlayer:SoundPlayerService, 
    private loginService:LoginService, 
    private securityQuestionService:SecurityQuestionService) { 
    
    //formulario para introducir username
    this.formInputUserName = this.formBuilder.group({
      username: ['', Validators.required]
    });

    //formulario para introducir respuesta de seguridad
    this.formInputAnswer = this.formBuilder.group({
      answer: ['', Validators.required]
    });

    //formulario para introducir nueva contraseña
    this.formInputNewPassword = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      passwordConfirmed: ['', [Validators.required]],
    },{validator: ConfirmPasswordValidator('password', 'passwordConfirmed')});
  }

  ngOnInit(): void {
  }

  checkUserName(username:string){
    
    this.spinnerController.show();
    this.errorsController.resetErrors();

    this.loginService.checkUsername(username).subscribe(
      resp => {
        
        //Si la API devuelve false es que el nombre no existe
        if(resp.success){
          
          this.onStep1Completed(username);
        }
        else{
          
          this.errorsController.showError(TLoginError.USERNAME_NOT_FOUND);
          this.spinnerController.hide();
        }
      },
      error => {
        console.log("Error desde el componente: " + error);
        //this.showErrorMessage(kMessageInvalidLicence);
      },
      () => {
        console.log("Se ha completado el check del username");
      }
    );  
  }

  setSecurityQuestionForUser(username:string){
    
    this.spinnerController.show();
    this.errorsController.resetErrors();

    this.loginService.getSecurityQuestionIdForUser(username).subscribe(
      resp => {
        
        //Si la API devuelve success es que hemos encontrado la pregunta
        if(resp.success){
          this.securityQuestionForTemplate = this.securityQuestionService.getSecurityQuestionForId(resp.preguntaId);    
        }
        else{
          this.errorsController.showError(TLoginError.SECURITY_QUESTION_NOT_FOUND);
        }
      },
      error => {
        console.log("Error desde el componente: " + error);
      },
      () => {
        console.log("Se ha completado el set de la pregunta de seguridad");
        this.spinnerController.hide();
        this.showStep(2);
      }
    );  
  }

  checkSecurityAnswer(username:string, answer:string){
    
    this.spinnerController.show();
    this.errorsController.resetErrors();

    this.loginService.checkSecurityQuestion(username, answer).subscribe(
      resp => {
        
        if(resp.success){
          console.log("Respuesta de seguridad correcta");
          this.onStep2Completed(answer);
        }
        else{
          
          this.errorsController.showError(TLoginError.SECURITY_ANSWER_WRONG);
        }
      },
      error => {
        console.log("Error desde el componente: " + error);
        //this.showErrorMessage(kMessageInvalidLicence);
      },
      () => {
        console.log("Se ha completado el check del username");
        this.spinnerController.hide();
      }
    );  
  }

  sendNewPassword(username:string, answer:string, password:string){

    this.spinnerController.show();
    this.errorsController.resetErrors();

    this.loginService.resetPasswordForUser(username, answer, password).subscribe(
      resp => {
        
        if(resp.success){
          console.log("Password reseteada");
          this.onStep3Completed();
        }
        else{
          
          this.errorsController.showError(TLoginError.RESET_PASSWORD_WRONG);
        }
      },
      error => {
        console.log("Error desde el componente: " + error);
        //this.showErrorMessage(kMessageInvalidLicence);
      },
      () => {
        console.log("Se ha completado el reset de contraseña");
        this.spinnerController.hide();
      }
    );  
  }

  checkPasswordMismatch(){

    if(this.formInputNewPassword.get('password')!.value == null || this.formInputNewPassword.get('passwordConfirmed')!.value == null){
      return;
    }

    if(this.formInputNewPassword.get('password')!.value.length == 0 || this.formInputNewPassword.get('passwordConfirmed')!.value.length == 0)
    {
      return;
    }

    const inputPassword = this.formInputNewPassword!.get('password');
    const inputPasswordConfirmed = this.formInputNewPassword!.get('passwordConfirmed');

    const passwordErrors = inputPassword!.errors;
    const passwordConfirmedErrors = inputPasswordConfirmed!.errors;

    if(passwordErrors){
      //console.log("Tenemos errores en el password")
      if(Object.keys(passwordErrors)[0] == "minlength"){
        this.errorsController.resetErrors();
        this.errorsController.showError(TLoginError.PASSWORD_MIN_LENGTH);
        return;
      }
    }
    else{
      this.errorsController.resetErrors();
    }

    if(passwordConfirmedErrors){
      //console.log("Tenemos errores en el password confirmed")
      if(Object.keys(passwordConfirmedErrors)[0] == "mustMatch"){
        this.errorsController.resetErrors();
        this.errorsController.showError(TLoginError.PASSWORD_MISMATCH);
      }
    }
    else{
      this.errorsController.resetErrors();
    }
  }


  onStep1Completed(username:string){

    this.username = username;
    this.setSecurityQuestionForUser(this.username);
  }

  onStep2Completed(answer:string){

    this.securityAnswer = answer;
    this.showStep(3);
  }

  onStep3Completed(){

    setTimeout(() => {
      this.hide();
    }, 300);
    
  }

  show(){
    
    this.showStep(1);
    this.opacityForTemplate = 1;
  }

  hide(){
    this.opacityForTemplate = 0;
    this.formInputAnswer.reset();
    this.formInputUserName.reset();
    this.formInputNewPassword.reset();
  }

  private showStep(step:number){

    this.opacityForContainerSteps = 0;

    setTimeout(() => {
      switch(step){
        case 1:
          this.showStep1Form = true;
          this.showStep2Form = false;
          this.showStep3Form = false;
          this.currentPage = 1;
          break;
        case 2:
          this.showStep1Form = false;
          this.showStep2Form = true;
          this.showStep3Form = false;
          this.currentPage = 2;
          break;
        case 3:
          this.showStep1Form = false;
          this.showStep2Form = false;
          this.showStep3Form = true;
          this.currentPage = 3;
          break;
      }
  
      this.opacityForContainerSteps = 1;
    }, 200);
  }

  //#region toques
  onSubmit(values:any){

    this.soundPlayer.playSoundClick();

    if(this.showStep1Form){
      const username = this.formInputUserName.get('username')!.value as string;
      this.checkUserName(username);
      return;
    }

    if(this.showStep2Form){
      const answer = this.formInputAnswer.get('answer')!.value as string;
      this.checkSecurityAnswer(this.username, answer);
      return;
    }

    if(this.showStep3Form){
      const password = this.formInputNewPassword.get('password')!.value as string;
      this.sendNewPassword(this.username, this.securityAnswer, password);
      return;
    }
  }

  onCancel(){

    this.soundPlayer.playSoundClick();

    this.hide();
  }

  onInputPasswords(){

    this.checkPasswordMismatch();
  }

  //#endregion

  //#region template
  getOpacityForFlotante():number{
    return this.opacityForTemplate;
  }

  getOpacityForContainerSteps():number{
    return this.opacityForContainerSteps;
  }

  getSecurityQuestion():string{
    
    return  this.securityQuestionForTemplate;
  }

  getCurrentPageForTemplate(){
    return this.currentPage;
  }

  get User() {
    return this.formInputUserName.get('username');
  }

  get Answer(){
    return this.formInputAnswer.get('answer');
  }

  get Password(){
    return this.formInputAnswer.get('password');
  }

  get PasswordConfirmed(){
    return this.formInputAnswer.get('passwordConfirmed');
  }

  //#endregion

}
