import { Component, OnInit, ViewChild } from '@angular/core';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { BtnPestanyaInfo } from '../../models/btn-pestanya-info';
import { FrameForBitsComponent } from '../../components/frame-for-bits/frame-for-bits.component';
import { FrameForCuentosComponent } from '../../components/frame-for-cuentos/frame-for-cuentos.component';
import { FrameForDescargablesComponent } from '../../components/frame-for-descargables/frame-for-descargables.component';
import { TBtnPestanya } from '../../classes/t-btn-pestanya';
import { TDescargable } from '../../classes/t-descargable';
import { FlotanteFichaInteractivaComponent } from '../../../shared/components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';
import { TEventFromFichaInteractiva } from '../../../shared/classes/t-event-from-ficha-interactiva';
import { FrameForCurricularesComponent } from '../../components/frame-for-curriculares/frame-for-curriculares.component';

@Component({
  selector: 'app-material-complementario-view',
  templateUrl: './material-complementario-view.component.html',
  styleUrls: ['./material-complementario-view.component.css']
})
export class MaterialComplementarioViewComponent extends BaseViewComponent implements OnInit {

  @ViewChild(FrameForBitsComponent,  {static: true}) frameBitsController!: FrameForBitsComponent;
  @ViewChild(FrameForCuentosComponent,  {static: true}) frameCuentosController!: FrameForCuentosComponent;
  @ViewChild(FrameForDescargablesComponent,  {static: true}) frameDescargablesController!: FrameForDescargablesComponent;
  @ViewChild(FrameForCurricularesComponent,  {static: true}) frameCurricularesComponent!: FrameForCurricularesComponent;
  @ViewChild(FlotanteFichaInteractivaComponent,  {static: true}) flotanteCuentoController!: FlotanteFichaInteractivaComponent;

  private showingPestanya = TBtnPestanya.BITS;

  constructor(private soundPlayer:SoundPlayerService, 
    protected appState:AppStateService, 
    protected navigationManager:NavigationManagerService) { 
    
      super(navigationManager, appState);
  }


  ngAfterViewInit(){
    super.ngAfterViewInit();

    setTimeout(() => {
      this.frameBitsController.showFrame();
    }, 50);
  }

  //#region cuento
  onEventCuentoSelected(url:string){

    this.flotanteCuentoController.showFlotanteWithUrl(url);
  }

  onEventFlotanteCuento(eventFicha:TEventFromFichaInteractiva){
    //console.log("Hemos recibido un evento del cuento: " + eventFicha);
  }
  //#endregion cuento

  //#region toques
  eventOnClickBtnPestanya(info:BtnPestanyaInfo)
  {
    
    switch(info.type){
      case TBtnPestanya.BITS:
        this.showingPestanya = TBtnPestanya.BITS;
        this.frameBitsController.showFrame();
        this.frameCuentosController.hideFrame();
        this.frameDescargablesController.hideFrame();
        this.frameCurricularesComponent.hideFrame();
        break;
      case TBtnPestanya.CUENTOS:
        this.showingPestanya = TBtnPestanya.CUENTOS;
        this.frameCuentosController.showFrame();
        this.frameBitsController.hideFrame();
        this.frameDescargablesController.hideFrame();
        this.frameCurricularesComponent.hideFrame();

       
        break;
      case TBtnPestanya.RUTINAS:
        this.showingPestanya = TBtnPestanya.RUTINAS;
        this.frameDescargablesController.showFrame(TDescargable.RUTINA);
        this.frameBitsController.hideFrame();
        this.frameCuentosController.hideFrame();
        this.frameCurricularesComponent.hideFrame();

        break;
      case TBtnPestanya.CANCIONERO:
        this.showingPestanya = TBtnPestanya.CANCIONERO;
        this.frameDescargablesController.showFrame(TDescargable.CANCIONERO);
        this.frameBitsController.hideFrame();
        this.frameCuentosController.hideFrame();
        this.frameCurricularesComponent.hideFrame();

        break;
      case TBtnPestanya.AMPLIACION:
        this.showingPestanya = TBtnPestanya.AMPLIACION;
        this.frameDescargablesController.showFrame(TDescargable.AMPLIACION);
        this.frameBitsController.hideFrame();
        this.frameCuentosController.hideFrame();
        this.frameCurricularesComponent.hideFrame();

        break;
      case TBtnPestanya.CURRICULAR:
        this.showingPestanya = TBtnPestanya.CURRICULAR;
        this.frameCurricularesComponent.showFrame(TDescargable.CURRICULARES);

        this.frameBitsController.hideFrame();
        this.frameCuentosController.hideFrame();
        this.frameDescargablesController.hideFrame();

        break;
    }
  }
  //#endregion toques

  //#region template
  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.MATERIAL_COMPLEMENTARIO;
  }

  getThumbsContainerBackgroundColorForTemplate():string{
    return this.appState.getTrimestreInfo().colorPrimaryLight.rgbString;
  }

  getTitleBarBackgroundColorForTemplate():string{
    return this.appState.getTrimestreInfo().colorPrimaryDark.rgbString;
  }

  getBackgroundColorForTemplate():string{
    return this.appState.getTrimestreInfo().colorPrimaryDark.rgbString;
  }

  getTitleBackgroundColorForTemplate():string{
    return this.appState.getTransversalColor().rgbString;
  }
  //#endregion template

}
