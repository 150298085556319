import { TTrimestre } from "../../shared/classes/t-trimestre";
import { TTrimestreActivity } from "../classes/t-trimestre-activity";

export class BtnMenuTrimestreInfo {
    activity:TTrimestreActivity;
    trimestre:TTrimestre;

    constructor(activiy:TTrimestreActivity, trimestre:TTrimestre = TTrimestre["3_1"]){
        this.activity = activiy;
        this.trimestre = trimestre;
    }
}
