import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { AppStateService } from '../../../shared/services/app-state.service';
import { AuthorizationService } from '../../../shared/services/authorization.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { TGeneradorTarjetasStatus } from '../../classes/t-generador-tarjetas-status';
import { BtnCardComponent } from '../../components/btn-card/btn-card.component';
import { CardThumbsComponent } from '../../components/card-thumbs/card-thumbs.component';
import { EditorTarjetasComponent } from '../../components/editor-tarjetas/editor-tarjetas.component';
import { CardInfo } from '../../models/card-info';
import { GeneradorTarjetasService, ICard } from '../../services/generador-tarjetas.service';

@Component({
  selector: 'app-generador-tarjetas-view',
  templateUrl: './generador-tarjetas-view.component.html',
  styleUrls: ['./generador-tarjetas-view.component.css']
})
export class GeneradorTarjetasViewComponent extends BaseViewComponent implements OnInit {

  @ViewChild(EditorTarjetasComponent,  {static: true}) editorTarjetasController!: EditorTarjetasComponent;
  @ViewChild(CardThumbsComponent,  {static: false}) cardsController!: CardThumbsComponent;
  
  canShow:boolean = false;
  arrayCardsForTemplate:CardInfo[] = [];
  status:TGeneradorTarjetasStatus = TGeneradorTarjetasStatus.DEFAULT;

  private opacityForTemplate: number = 0;
  
  constructor(protected navigationManager:NavigationManagerService, 
    protected appState: AppStateService, 
    private soundPlayer:SoundPlayerService) { 
    super(navigationManager, appState);
  }

  ngOnInit(): void {
  }

  show(){
    
    this.status = TGeneradorTarjetasStatus.DEFAULT;
    this.canShow = true;
    this.opacityForTemplate = 1;

    setTimeout(() => {
      this.cardsController.show();
    }, 100);
  }

  private hide(){
    this.opacityForTemplate = 0;
    setTimeout(() => {
      this.canShow = false;
      this.cardsController.hide();
    }, 300);
  }

  private deleteSelectedCards(){
    this.cardsController.deleteCards();
  }

  private printSelectedCards(){
    this.cardsController.printCards();
  }

  //#region toques
  onCloseClick(){

    this.soundPlayer.playSoundClick();

    this.hide();
  }

  onOkClick(){

    switch(this.status){
      case TGeneradorTarjetasStatus.DELETING:
        this.deleteSelectedCards();
        break;
      case TGeneradorTarjetasStatus.PRINTING:
        this.printSelectedCards();
        break;
    }

    this.status = TGeneradorTarjetasStatus.DEFAULT;

  }

  onCancelClick(){

    this.status = TGeneradorTarjetasStatus.DEFAULT;
    this.cardsController.unSelectAllCards();
    //this.unSelectAllCards();
  }

  onNewCardClicked(){

    this.status = TGeneradorTarjetasStatus.DEFAULT;
    this.cardsController.unSelectAllCards();
    this.editorTarjetasController.show(new CardInfo("", "", "", ""));
  }

  onDeleteClick(){

    this.soundPlayer.playSoundClick();

    this.status = TGeneradorTarjetasStatus.DELETING;

      this.cardsController.unSelectAllCards();
  }

  onPrintClick(){

    this.soundPlayer.playSoundClick();

    this.status = TGeneradorTarjetasStatus.PRINTING;

    this.cardsController.unSelectAllCards();
  }

  onCardUpdated(){
    
    this.cardsController.reload();
  }

  onCardClicked(btnCardController:BtnCardComponent){

    if(this.status == TGeneradorTarjetasStatus.DEFAULT){
      this.editorTarjetasController.show(btnCardController.cardInfo);
    }
  }
  //#endregion toques

  //#region template

  getOpacityForTemplate():number{

    return this.opacityForTemplate;
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getTrimestreInfo().colorPrimaryLight.rgbString;
  }

  getColorTitleForTemplate():string{
    return this.appState.getTransversalColor().rgbString;
  }

  getTopBarBackgroundColorForTemplate():string{
    return this.appState.getTrimestreInfo().colorPrimary.rgbString;
  }

  getTitleBarColorForTemplate():string{
    return this.appState.getTrimestreInfo().colorPrimaryDark.rgbString;
  }
  //#endregion

}
