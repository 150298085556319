import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TGeneradorTarjetasStatus } from '../../classes/t-generador-tarjetas-status';

@Component({
  selector: 'app-tool-bar',
  templateUrl: './tool-bar.component.html',
  styleUrls: ['./tool-bar.component.css']
})
export class ToolBarComponent implements OnInit {

  @Input() status:TGeneradorTarjetasStatus = TGeneradorTarjetasStatus.DEFAULT; 
  @Output() eventOnOkClicked = new EventEmitter();
  @Output() eventOnCancelClicked = new EventEmitter();

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  onOkClick(){

    this.soundPlayer.playSoundClick();
    this.eventOnOkClicked.emit();
  }

  onCancelClick(){

    this.soundPlayer.playSoundClick();
    this.eventOnCancelClicked.emit();
  }

}
