<div class="pageWrapper" [style.background-color]="getBackgroundColorForTemplate()">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'menu'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="titleBar" [style.color]="getColorTitleForTemplate()">
        <p i18n="@@libroAventuras.title">LIBRO DE AVENTURAS</p>
    </div>
    <div class="iframeContainer" 
        [style.visibility]="getVisibilityFlotanteForTemplate()"
        [style.opacity]="getOpacityFlotanteForTemplate()">
        <iframe #iframe *ngIf="haveValidUrlToShow()" [src]="safeUrl" frameBorder="0"></iframe>
    </div>
</div>
