import { Component, Input, OnInit, QueryList, ViewChildren, ɵpublishDefaultGlobalUtils } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TrimestreStaticDataService } from '../../../shared/services/trimestre-static-data.service';
import { TDescargable } from '../../classes/t-descargable';
import { DescargableInfo } from '../../models/descargable-info';
import { ComplementarioConfigService } from '../../services/complementario-config.service';
import { BtnCurricularesThumbComponent } from '../btn-curriculares-thumb/btn-curriculares-thumb.component';
import { Utils } from '../../../shared/classes/utils';

@Component({
  selector: 'app-frame-for-curriculares',
  templateUrl: './frame-for-curriculares.component.html',
  styleUrls: ['./frame-for-curriculares.component.css']
})
export class FrameForCurricularesComponent implements OnInit {

  @Input() typeDescargable:TDescargable = TDescargable.NONE;
  @ViewChildren(BtnCurricularesThumbComponent) thumbsControllers!: QueryList<BtnCurricularesThumbComponent>;
  arrayThumbsInfo:DescargableInfo[] = [];

  private isShowing: boolean = false;
  private opacity: number = 0;
  private opacityContainerThumbs:number = 1;
  private currentDescargableType:TDescargable = TDescargable.NONE;

  constructor(private serverConfig:ComplementarioConfigService, 
    private appState:AppStateService, 
    private staticDataService:TrimestreStaticDataService, 
    private soundPlayer:SoundPlayerService) { }
  
  ngOnInit(): void {}

  public showFrame(type:TDescargable){

    //Tenemos en cuenta de que si venimos de algun otro tipo de descargable el frame esta visible y por lo tanto hay que 
    //hay el fadeo con la opacidad del contenedor de thumbs
    let delayBeforeShow = 0;
    if(this.opacity == 1){
      this.opacityContainerThumbs = 0;
      delayBeforeShow = 150;
    }

    this.currentDescargableType = type;

    //generamos thumbs para el tipo de descargable
    setTimeout(() => {
      this.setThumbsForDescarganleType(type);

      this.show();
      
      setTimeout(() => {
        this.showThumbs();
      }, 50);
    }, delayBeforeShow);
    
  }

  public hideFrame()
  {
    this.opacity = 0;
    setTimeout(() => {
      this.isShowing = false;
    }, 300);
  }

  private setThumbsForDescarganleType(type:TDescargable){

    this.arrayThumbsInfo = [];

    //Recuperamos los descargables para el trimestre actual
    const descargables = this.staticDataService.getDescargablesForTrimestre(this.appState.getTrimestre());

    //Buscamos los descargables de tipo rutina y los añadimos al array de descargables para mostrar
    for (let i = 0; i < descargables.length; i++) {
      if(descargables[i].type == type){
        this.arrayThumbsInfo.push(new DescargableInfo(descargables[i].type, descargables[i].title, descargables[i].description, descargables[i].pathToThumb, descargables[i].pathToDownloadFile));
      } 
    }
  }

  private show(){

    this.opacity = 0;
    this.isShowing = true;
    setTimeout(() => {
      this.opacity = 1;
    }, 50);
  }

  private showThumbs()
  {
    let delay = 25;
    let counter = 0;
    this.opacityContainerThumbs = 1;
    this.thumbsControllers.forEach(thumb => {
      setTimeout(() => {
        thumb.showThumb();
      }, delay*counter);
      counter++;
    });
  }

  // private downloadAll(){

  // }
  

  //  //#region toques
  //  onClickDescargarTodo(){
    
  //   this.soundPlayer.playSoundClick();

  //   this.downloadAll();
  // }


  eventOnClickDescargableThumb(info:DescargableInfo){

    setTimeout(() => {
      Utils.downloadLocalFile(info.urlForDownload, info.description);
    }, 100);
  }
  //#endregion

  //#region  template
  getUrlForDescargable():string{
    return "./assets/cuentos_mundo/index.html";
  }

  getVisibilityForTemplate():string{
    //return (this.isShowing) ? "block" : "none";
    return (this.isShowing) ? "visible" : "hidden";
  }

  getOpacityForTemplate():number{

    return this.opacity;
  }

  getOpacityContainerThumbsForTemplate():number{
    return this.opacityContainerThumbs;
  }
  //#endregion
}
