export enum TTrimestre {
    "3_1", 
    "3_2", 
    "3_3", 
    "4_1", 
    "4_2", 
    "4_3", 
    "5_1", 
    "5_2", 
    "5_3"
}
